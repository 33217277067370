import ReportActions from '@/components/ReportActions';
import { useAuth } from '@/config/AuthContext/useAuth';
import { queryClient } from '@/config/http/react-query.config';
import InspectionSurveyReport from '@/features/inspectionReport/components/InspectionSurveyReport';
import InvoiceView from '@/features/invoices/components/InvoiceView';
import PreRepairSurveyPdf from '@/features/invoices/components/PreRepairSurveyPdf';
import {
  deleteLineItem,
  getInvoice,
  getInvoiceTypeDetails,
  getTaxInvoice,
  updateInvoice,
} from '@/features/invoices/services/invoices.service';
import { useToastApi } from '@/hooks/useToastApi';
import { SettingOutlined } from '@ant-design/icons';
import CustomFooterForm from '@invoices/components/CustomFooterForm';
import CustomHeaderForm from '@invoices/components/CustomHeaderForm';
import CustomInvoiceSegments from '@invoices/components/CustomInvoiceSegments';
import InvoiceForm from '@invoices/components/InvoiceForm';
import InvoicePdf from '@invoices/components/InvoicePdf';
import {
  TCompanyResponse,
  TCustomFooterForm,
  TCustomHeaderForm,
  TCustomInvoiceSegments,
  TInvoiceResponse,
  TLineItem,
} from '@invoices/util/types';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Breadcrumb, Card, Flex, Tabs, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import isEmpty from 'lodash/isEmpty';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const InspectionInvoiceLayout = () => {
  const location = useLocation();
  const styles = useStyles();
  const { uniqueCode } = useParams<{ uniqueCode: string }>();
  const {
    authState: { tenantId, token },
  } = useAuth();
  const toast = useToastApi();
  const [itemToEdit, setItemToEdit] = useState<TLineItem | undefined>(undefined);
  const searchParams = new URLSearchParams(location.search);
  const invoiceId = searchParams.get('invoiceId') ? parseInt(searchParams.get('invoiceId') as string) : undefined;
  const invoiceTypeCode = searchParams.get('invoiceTypeCode') || undefined;
  const componentRef = useRef<HTMLDivElement>(null);
  const [segmentedValue, setSegmentedValue] = useState<TCustomInvoiceSegments>('Line Items');
  const [tableFooterHidden, setTableFooterHidden] = useState(false);
  const [headerData, setHeaderData] = useState<TCustomHeaderForm>([] as TCustomHeaderForm);
  const [footerData, setFooterData] = useState<TCustomFooterForm>([] as TCustomFooterForm);
  const [surveyReportPdfDataUrl, setSurveyReportPdfDataUrl] = useState<string | undefined>();

  const { data: invoice } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => getInvoice({ invoiceId }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
  });

  const referenceNumber = invoice?.referenceNumber;
  const { data: taxInvoice } = useQuery({
    queryKey: ['taxInvoice', referenceNumber],
    queryFn: () => getTaxInvoice({ referenceNumber }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    enabled: !!referenceNumber,
  });

  const { data: invoiceTypeDetails } = useQuery({
    queryKey: ['invoiceTypeDetails', invoiceTypeCode],
    queryFn: () => getInvoiceTypeDetails({ invoiceTypeCode: invoiceTypeCode }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    enabled: !!invoiceTypeCode,
  });

  const { mutate: updateInvoiceMutation, isPending: updateInvoiceIsPending } = useMutation({
    mutationFn: (data: TCustomFooterForm | TCustomHeaderForm) => updateInvoice(invoiceId, data),
    onSuccess: (response) => {
      toast.success(response?.message || 'Invoice updated successfully');
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] });
    },
    onError: (error) => {
      toast.error(error?.message || 'Failed to update invoice');
    },
  });

  useEffect(() => {
    if (!isEmpty(headerData) || !isEmpty(footerData)) {
      updateInvoiceMutation({
        ...headerData,
        ...footerData,
      });
    }
  }, [headerData, footerData, updateInvoiceMutation]);

  const onLineItemsDelete = async (lineItems: TLineItem[]) => {
    await Promise.all(
      lineItems.map(async (lineItem) => {
        await deleteLineItem(lineItem.invoiceId, lineItem.id);
      })
    );
    setItemToEdit(undefined);
    await queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] });
  };

  const sections = useMemo(
    () =>
      Array.from(new Set(invoice?.lineItems?.map((item) => item.sectionCode))).map((section) => ({
        value: section,
        label: section,
      })),
    [invoice?.lineItems]
  );

  type TDataItem = {
    name: string;
    value: string;
  };

  type TSectionData = {
    section: string;
    data: TDataItem[];
  };

  const preprocessDataIntoMap = (sections: TSectionData[]): Map<string, string> => {
    const map = new Map<string, string>();
    sections?.forEach((section: TSectionData) => {
      section?.data.forEach((item: TDataItem) => {
        if (!map.has(item.name)) {
          map.set(item.name, item.value);
        }
      });
    });
    return map;
  };

  const incidentDetailsMap = preprocessDataIntoMap(Array.isArray(taxInvoice) ? taxInvoice : []);

  type FooterProps = {
    company: TCompanyResponse;
  };
  const Footer = ({ company }: FooterProps) => (
    <View style={pdfStyles.footer} fixed>
      <Text style={pdfStyles.footerTitle}>{company.name}</Text>
      <Text style={pdfStyles.footerTxt}>
        {`120 Lower Delta Road, #08-08, Cendex Center, Singapore 169208. Tel: ${company.tel} Fax: ${company.fax}`}
      </Text>
      <Text style={pdfStyles.footerTxt}>
        {`Email: ${company.email} Website: ${company.website} Com. Reg. No.: ${company.comReg}`}
      </Text>
    </View>
  );

  return (
    <div style={styles.container}>
      <Flex justify="space-between" align="center" style={styles.buttonBar} gap={8}>
        <div>
          <Breadcrumb
            separator="/"
            items={[
              {
                title: 'Inspection Overview',
                href: `/inspection/?tenantId=${tenantId}&token=${token}`,
              },
              { title: 'Inspection', href: `/inspection/${uniqueCode}?tenantId=${tenantId}&token=${token}` },
              { title: `Invoice #${invoiceId}` },
            ]}
            style={styles.breadCrumb}
          />
          <Title level={4} style={styles.mainTitle}>
            Inspection Invoice
          </Title>
        </div>
        <ReportActions
          inspectionId={uniqueCode}
          surveyReportPdfDataUrl={surveyReportPdfDataUrl}
          disabled={isEmpty(invoice?.lineItems)}
          vehicleNo={incidentDetailsMap.get('vehicleNumber') || ''}
          estimate={
            <Document>
              <Page size="A4">
                <InvoicePdf invoice={invoice} tenantId={tenantId} footerHidden={tableFooterHidden} />
                {tenantId === 'ajax' ? (
                  <Footer
                    company={{
                      name: 'AJAX Inspection Services Pte Ltd',
                      tel: '+65 6255 0808',
                      fax: '+65 6849 9155',
                      email: 'info@ajaxinspection.com',
                      comReg: '201318067R',
                      gstReg: '201318067R',
                      website: 'www.ajaxadjuster.com',
                      address: '120 Lower Delta Road, #08-08, Cendex Center, Singapore 169208.',
                    }}
                  />
                ) : (
                  <View style={pdfStyles.footer} fixed>
                    <Text style={pdfStyles.footerTitle}>Powered by Digital Services Global (Pvt) Ltd</Text>
                  </View>
                )}
              </Page>
            </Document>
          }
          surveyReport={
            <Document>
              <Page size="A4">
                {invoice?.invoiceId && (
                  <PreRepairSurveyPdf
                    invoice={invoice}
                    vehicleDescription={{
                      vehicleNumber: incidentDetailsMap.get('vehicleNumber') || '',
                      engineNo: incidentDetailsMap.get('engineNo') || '',
                      vehicleEngineCapacity: incidentDetailsMap.get('engineCapacity/CC') || '',
                      vehicleColor: incidentDetailsMap.get('color') || '',
                      makeOrModel: incidentDetailsMap.get('make/model') || '',
                      chassisNo: incidentDetailsMap.get('chassisNo') || '',
                      regDate: incidentDetailsMap.get('regDate') || '',
                      odoMeter: incidentDetailsMap.get('odometer') || '',
                    }}
                    vehicleCondition={{
                      general: incidentDetailsMap.get('generalCondition') || '',
                      handbrake: incidentDetailsMap.get('handbrake') || '',
                      tyreMake: incidentDetailsMap.get('tyreMake') || '',
                      frontTyreSize: incidentDetailsMap.get('frontTyreSize') || '',
                      rearTyreSize: incidentDetailsMap.get('rearTyreSize') || '',
                      modification: false,
                      footbrake: incidentDetailsMap.get('footbrake') || '',
                      frontLeftSize: incidentDetailsMap.get('frontLeft') || '',
                      rearLeftSize: incidentDetailsMap.get('rearLeft') || '',
                      frontRightSize: incidentDetailsMap.get('frontRight') || '',
                      rearRightSize: incidentDetailsMap.get('rearRight') || '',
                      steering: incidentDetailsMap.get('steering') || '',
                      painWork: incidentDetailsMap.get('paintWork') || '',
                    }}
                    invoiceDetails={{
                      assignDate: incidentDetailsMap.get('assignDate') || '',
                      accidentDate: incidentDetailsMap.get('accidentDate') || '',
                      surveyDate: incidentDetailsMap.get('surveyDate') || '',
                      placeOfSurvey: incidentDetailsMap.get('placeOfSurvey') || '',
                      TPPolicyNo: incidentDetailsMap.get('TPPolicyNo') || '',
                      ourRef: incidentDetailsMap.get('ourRef') || '',
                      yourRef: incidentDetailsMap.get('yourRef') || '',
                      claimType: incidentDetailsMap.get('claimType') || '',
                      remark: incidentDetailsMap.get('invoiceRemarks') || '',
                      descriptionOfDamage: incidentDetailsMap.get('describeCircumstancesOfTheAccident') || '',
                    }}
                    tenantId={tenantId}
                    footerHidden={tableFooterHidden}
                  />
                )}
                {tenantId === 'ajax' ? (
                  <Footer
                    company={{
                      name: 'AJAX Inspection Services Pte Ltd',
                      tel: '+65 6255 0808',
                      fax: '+65 6849 9155',
                      email: 'info@ajaxinspection.com',
                      comReg: '201318067R',
                      gstReg: '201318067R',
                      website: 'www.ajaxadjuster.com',
                      address: '120 Lower Delta Road, #08-08, Cendex Center, Singapore 169208.',
                    }}
                  />
                ) : (
                  <View style={pdfStyles.footer} fixed>
                    <Text style={pdfStyles.footerTitle}>Powered by Digital Services Global (Pvt) Ltd</Text>
                  </View>
                )}
              </Page>
            </Document>
          }
          referenceNumber={referenceNumber}
        />
      </Flex>

      <Flex>
        <InspectionSurveyReport
          setPdfDataUrl={(dataUrl) => setSurveyReportPdfDataUrl(dataUrl)}
          vehicleCondition={{
            general: incidentDetailsMap.get('generalCondition') || '',
            handbrake: incidentDetailsMap.get('handbrake') || '',
            tyreMake: incidentDetailsMap.get('tyreMake') || '',
            frontTyreSize: incidentDetailsMap.get('frontTyreSize') || '',
            rearTyreSize: incidentDetailsMap.get('rearTyreSize') || '',
            modification: false,
            footbrake: incidentDetailsMap.get('footbrake') || '',
            frontLeftSize: incidentDetailsMap.get('frontLeft') || '',
            rearLeftSize: incidentDetailsMap.get('rearLeft') || '',
            frontRightSize: incidentDetailsMap.get('frontRight') || '',
            rearRightSize: incidentDetailsMap.get('rearRight') || '',
            steering: incidentDetailsMap.get('steering') || '',
            painWork: incidentDetailsMap.get('paintWork') || '',
          }}
          invoice={invoice}
          invoiceDetails={{
            assignDate: incidentDetailsMap.get('assignDate') || '',
            accidentDate: incidentDetailsMap.get('accidentDate') || '',
            surveyDate: incidentDetailsMap.get('surveyDate') || '',
            placeOfSurvey: incidentDetailsMap.get('placeOfSurvey') || '',
            TPPolicyNo: incidentDetailsMap.get('TPPolicyNo') || '',
            ourRef: incidentDetailsMap.get('ourRef') || '',
            yourRef: incidentDetailsMap.get('yourRef') || '',
            claimType: incidentDetailsMap.get('claimType') || '',
            remark: incidentDetailsMap.get('invoiceRemarks') || '',
            descriptionOfDamage: incidentDetailsMap.get('describeCircumstancesOfTheAccident') || '',
          }}
          vehicleDescription={{
            vehicleNumber: incidentDetailsMap.get('vehicleNumber') || '',
            engineNo: incidentDetailsMap.get('engineNo') || '',
            vehicleEngineCapacity: incidentDetailsMap.get('engineCapacity/CC') || '',
            vehicleColor: incidentDetailsMap.get('color') || '',
            makeOrModel: incidentDetailsMap.get('make/model') || '',
            chassisNo: incidentDetailsMap.get('chassisNo') || '',
            regDate: incidentDetailsMap.get('regDate') || '',
            odoMeter: incidentDetailsMap.get('odometer') || '',
          }}
        />
      </Flex>

      <Flex justify="space-between" gap={8}>
        {/* Invoice container */}
        <Card style={styles.invoiceContainerCard}>
          <div ref={componentRef}>
            <InvoiceView
              invoiceTypeDetails={invoiceTypeDetails}
              invoice={invoice}
              onDeleteLineItems={onLineItemsDelete}
              onSelectToEdit={(value) => setItemToEdit(value)}
              onTableFooterHidden={(value) => setTableFooterHidden(value)}
              itemToEdit={itemToEdit}
            />
          </div>
        </Card>

        <Card style={styles.invoiceConfigCard}>
          {/* Side pane header */}
          <Flex gap={8} align="center">
            <Flex justify="center" align="center" style={styles.invoiceConfigurationIconContainer}>
              <SettingOutlined />
            </Flex>
            <Flex vertical>
              <Title level={5} style={styles.marginZero}>
                Invoice Configuration
              </Title>
              <Typography.Text style={styles.marginZero}>Templates and History Review</Typography.Text>
            </Flex>
          </Flex>

          {/* Tabs */}
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: 'Add/Edit Items',
                children: (
                  <Flex vertical align="center" gap={8}>
                    <CustomInvoiceSegments segment={segmentedValue} setSegments={(value) => setSegmentedValue(value)} />
                    {segmentedValue === 'Header' ? (
                      <CustomHeaderForm
                        loading={updateInvoiceIsPending}
                        invoice={invoice || ({} as TInvoiceResponse)}
                        setHeaderData={(value) => setHeaderData(value)}
                      />
                    ) : segmentedValue === 'Footer' ? (
                      <CustomFooterForm
                        loading={updateInvoiceIsPending}
                        setFooterData={(value) => setFooterData(value)}
                        customerNote={invoice?.customerNote || ''}
                      />
                    ) : (
                      <InvoiceForm
                        lineItems={invoice?.lineItems || []}
                        fields={invoiceTypeDetails?.tableColumns || []}
                        sections={sections}
                        itemToEdit={itemToEdit}
                        onUpdate={() => setItemToEdit(undefined)}
                      />
                    )}
                  </Flex>
                ),
              },
              //TODO: Implement these features later
              // {
              //   key: '2',
              //   label: 'Export Templates',
              //   children: 'Invoice Export Templates',
              // },
              // {
              //   key: '3',
              //   label: 'Edit History',
              //   children: 'Invoice History',
              // },
            ]}
            style={styles.tabs}
          />
        </Card>
      </Flex>
    </div>
  );
};

export default InspectionInvoiceLayout;

const pdfStyles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: 8,
    height: 40,
  },
  footerTitle: {
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 900,
    color: '#3839a6',
  },
  footerTxt: {
    fontSize: 8,
    color: '#3839a6',
  },
});

const useStyles = () => {
  return {
    container: {
      padding: '0px 16px',
      backgroundColor: '#F5F4F7',
    } as CSSProperties,
    mainTitle: {
      margin: 0,
      fontWeight: '500',
    } as CSSProperties,
    buttonBar: { marginTop: 16 } as CSSProperties,
    breadCrumb: { fontSize: '12px' } as CSSProperties,
    invoiceContainerCard: {
      width: '100%',
      marginTop: 16,
    } as CSSProperties,
    invoiceConfigCard: {
      width: '500px',
      marginTop: 16,
    } as CSSProperties,
    marginZero: {
      margin: 0,
      lineHeight: 1.2,
    } as CSSProperties,
    invoiceConfigurationIconContainer: {
      borderRadius: '50%',
      border: '1px solid #D9D9D9',
      width: '32px',
      height: '32px',
    } as CSSProperties,
    tabs: {
      marginTop: 16,
    } as CSSProperties,
  };
};
