import { RightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { CSSProperties } from 'react';
import { InspectionDetailsList } from '../../types';
import { getCollapsibleData } from '../../utils/inspectionDetails.util';

type Props = {
  inspectionDetailsList: InspectionDetailsList;
};

const InspectionDetails = ({ inspectionDetailsList }: Props) => {
  const styles: Styles = useStyles();
  const { items, defaultActiveKeys } = getCollapsibleData(inspectionDetailsList, styles.label);

  return (
    <Collapse
      defaultActiveKey={defaultActiveKeys}
      items={items}
      expandIconPosition="end"
      style={styles.collapse}
      expandIcon={({ isActive }) => (
        <span style={styles.expandIcon}>
          <RightOutlined rotate={isActive ? 90 : 0} />
        </span>
      )}
    />
  );
};

export default InspectionDetails;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    label: { fontWeight: 600, color: '#0035cc' } as CSSProperties,
    expandIcon: {
      color: '#0035cc',
      fontSize: '10px',
      cursor: 'pointer',
      width: '30px',
      height: '30px',
      display: 'flex',
      borderRadius: '100%',
      backgroundColor: '#fff',
      justifyContent: 'center',
      border: '1px solid rgba(22, 119, 255, 0.15)',
    } as CSSProperties,
    collapse: {
      width: '100',
      backgroundColor: 'rgba(240, 245, 255, 0.5)',
    } as CSSProperties,
  };
};
