import { KeyValuePairList } from '../types';

export const transformPolicyData = (values: {
  title: string;
  data: [{ title: string; value: string }];
}): { title: string; data: KeyValuePairList } => {
  return {
    title: values.title,
    data: values.data.map((item) => ({
      title: item.title,
      description: item.value,
    })),
  };
};
