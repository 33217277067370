import { Segmented } from 'antd';
import { TCustomInvoiceSegments } from '../util/types';

type CustomInvoiceSegmentsProps = {
  segment: TCustomInvoiceSegments;
  setSegments: (value: TCustomInvoiceSegments) => void;
};

const CustomInvoiceSegments = ({ setSegments, segment }: CustomInvoiceSegmentsProps) => {
  return (
    <Segmented<TCustomInvoiceSegments>
      options={['Header', 'Line Items', 'Footer']}
      value={segment}
      onChange={(value) => {
        setSegments(value);
      }}
    />
  );
};
export default CustomInvoiceSegments;
