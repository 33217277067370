import { useAuth } from '@/config/AuthContext/useAuth.ts';
import { DigiEyeApi } from '@/config/http/axios.api-config.ts';
import {
  TInvoiceDetails,
  TInvoiceResponse,
  TVehicleConditionResponse,
  TVehicleDescriptionResponse,
} from '@/features/invoices/util/types';
import { generatePdf } from '@inspectionReport/util/templates/SurveyReportTemplate';
import { getCurrency } from '@invoices/util';
import pdfMake from 'pdfmake/build/pdfmake';
import { useEffect, useState } from 'react';

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

export type InspectionSurveyReportProps = {
  invoice: TInvoiceResponse | undefined;
  invoiceDetails: TInvoiceDetails;
  vehicleDescription: TVehicleDescriptionResponse;
  vehicleCondition: TVehicleConditionResponse;
  setPdfDataUrl: (dataUrl: string) => void;
};

const InspectionSurveyReport = (props: InspectionSurveyReportProps) => {
  const tenantId = useAuth()?.authState?.tenantId;
  const [logoDataUrl, setLogoDataUrl] = useState<string | undefined>();
  const [pdfDataUrl, setPdfDataUrl] = useState<string>('');
  const currency = getCurrency(tenantId);

  useEffect(() => {
    const handleImageConversion = async (imageUrl: string) => {
      const dataURL = await convertImageToDataURL(imageUrl);
      if (dataURL) {
        const base64Data = getDataFromDataURL(dataURL);
        setLogoDataUrl(`data:image/jpeg;base64,${base64Data}`);
      }
    };

    if (tenantId === 'ajax') {
      handleImageConversion('https://i.ibb.co/P4XbT9r/ajax-logo-1.png');
    } else {
      DigiEyeApi.get('logo').then((data) => {
        const logoUrl = data?.data?.data?.logo;
        if (logoUrl) {
          handleImageConversion(logoUrl);
        }
      });
    }
  }, [tenantId]);

  const subtotal = props?.invoice?.lineItems?.reduce((total, item) => {
    return total + item?.quantity * item?.unitPrice;
  }, 0);

  useEffect(() => {
    // Generate the PDF when logoDataUrl is available
    if (logoDataUrl) {
      generatePdf(props, currency, subtotal, tenantId, logoDataUrl, setPdfDataUrl);
    }
  }, [logoDataUrl, props?.invoice, tenantId]);

  useEffect(() => {
    if (pdfDataUrl) {
      props.setPdfDataUrl(pdfDataUrl);
    }
  }, [pdfDataUrl]);

  async function convertImageToDataURL(url: string | undefined) {
    try {
      if (url) {
        const response = await fetch(url);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      }
    } catch (error) {
      console.error('Error fetching the image:', error);
      return null;
    }
  }

  function getDataFromDataURL(dataUrl: any) {
    // Split the DataURL to get the base64 part
    return dataUrl.split(',')[1];
  }

  return <></>;
};

export default InspectionSurveyReport;
