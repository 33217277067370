import { getStepIcon, TimeLine } from '@/features/inspection/utils/timeLine.util';
import { Steps } from 'antd';

type Props = {
  items: TimeLine;
};

const getCurrent = (items: TimeLine): number => {
  let current = 0;
  for (let i = 0; i < items.length; i++) {
    if (items[i].status === 'finish') current = i;
  }
  return current;
};

const RestructuredItemList = (items: TimeLine) => {
  return items.map((item) => ({
    ...item,
    icon: getStepIcon(item),
  }));
};

const ProgressTimeLine = ({ items }: Props) => (
  <Steps current={getCurrent(items)} items={RestructuredItemList(items)} size={'small'} className="custom-steps" />
);

export default ProgressTimeLine;
