export const getTreeDataParent = (key: string, tree: any[]): string => {
  let parentValue;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentValue = node.value;
      } else if (getTreeDataParent(key, node.children)) {
        parentValue = getTreeDataParent(key, node.children);
      }
    }
  }
  return parentValue;
};
