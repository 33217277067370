import { ExclamationCircleOutlined } from '@ant-design/icons';
import Grid from '@components/Grid.tsx';
import { MediaData, TMediaCardDetails } from '@inspection/types.ts';
import { Empty, Flex, Image, Typography } from 'antd';
import { CSSProperties } from 'react';
import MediaCard from './MediaCard.tsx';

type Props = {
  data: MediaData[];
  columns?: number;
  uniqueCode: string;
  refetch: () => void;
  hideDetails?: boolean | TMediaCardDetails[];
};

const Gallery = ({ data, columns = 4, uniqueCode, refetch, hideDetails = false }: Props) => {
  const styles = useStyles();
  const { Text } = Typography;

  if (data.length < 1) return <Empty description={'No Media'} />;

  return (
    <>
      {data.filter((item) => item.deleted).length > 0 && (
        <Flex gap={'6px'} style={styles.deletedMessage}>
          <ExclamationCircleOutlined />
          <Text style={styles.text}>{`${data.filter((item) => item.deleted).length} item(s) have been deleted!`}</Text>
        </Flex>
      )}
      <Image.PreviewGroup>
        <Grid columns={columns}>
          {data
            .filter((item) => !item.deleted)
            .map((item) => (
              <div key={item.thumbnail ? item.thumbnail : item.full} style={styles.imageWrapper}>
                <MediaCard item={item} uniqueCode={uniqueCode} refetch={refetch} hideDetails={hideDetails} />
              </div>
            ))}
        </Grid>
      </Image.PreviewGroup>
    </>
  );
};

const useStyles = () => {
  return {
    imageWrapper: {
      marginBottom: '0.5rem',
    } as CSSProperties,
    text: {
      color: 'red',
      fontSize: '12px',
    } as CSSProperties,
    deletedMessage: {
      width: '100%',
      marginBottom: '8px',
      color: 'red',
    } as CSSProperties,
  };
};

export default Gallery;
