import { deleteMedia } from '@/features/inspection/services/inspections.service';
import { TDeleteMedia } from '@/features/inspection/types';
import { useToastApi } from '@/hooks/useToastApi';
import { DeleteOutlined, MoreOutlined, WarningFilled } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Dropdown, MenuProps, Popconfirm, Tooltip } from 'antd';

type MediaDeleteButtonProps = {
  item: any;
  uniqueCode: string;
  refetch: () => void;
};

const MediaDeleteButton = ({ item, uniqueCode, refetch }: MediaDeleteButtonProps) => {
  const styles = useStyles();
  const toastApi = useToastApi();
  const mutation = useMutation({
    mutationFn: ({ uniqueCode, docId }: TDeleteMedia) => deleteMedia({ uniqueCode, docId }),
    onSuccess: (data) => {
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? 'Media deleted successfully!' : data.message,
        duration: 2,
      });
      data.success && refetch();
    },
    onError: (data) => {
      toastApi.open({
        type: 'error',
        content: data?.message || 'Failed to add comment!',
        duration: 2,
      });
    },
  });

  const deleteInspectionMedia = () => {
    mutation.mutate({ uniqueCode, docId: item.docId });
  };
  type MenuItem = Required<MenuProps>['items'][number];
  const items: MenuItem[] = [
    {
      key: '1',
      style: styles.red,
      label: (
        <Popconfirm
          trigger={'click'}
          arrow={false}
          title="Are you sure you want to delete this media?"
          onConfirm={deleteInspectionMedia}
          okText="Delete"
          cancelText="Cancel"
          icon={<WarningFilled style={styles.red} />}
          okButtonProps={{ danger: true }}
        >
          <Tooltip placement="bottom" title={'Delete this media item'} mouseEnterDelay={2}>
            <DeleteOutlined /> Delete
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click']} arrow disabled>
      <MoreOutlined style={styles.primary} />
    </Dropdown>
  );
};

const useStyles = () => {
  return {
    red: { color: '#FF0000' },
    primary: { color: '#0035CC' },
  };
};
export default MediaDeleteButton;
