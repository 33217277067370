import { useAuth } from '@/config/AuthContext/useAuth';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import InvoiceFooter from '@invoices/components/InvoiceFooter';
import InvoiceHeader from '@invoices/components/InvoiceHeader';
import { calculateTotals, formatCurrency, getCurrency } from '@invoices/util';
import { TInvoiceResponse, TInvoiceTypeDetailsResponse, TLineItem } from '@invoices/util/types';
import { Alert, Button, Col, Divider, Flex, Popconfirm, Row, Table } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import isEmpty from 'lodash/isEmpty';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import InvoiceViewSkeleton from '../skeletons/InvoiceViewSkeleton';
type InvoiceViewProps = {
  invoiceTypeDetails: TInvoiceTypeDetailsResponse | undefined;
  invoice: TInvoiceResponse | undefined;
  onDeleteLineItems: (lineItems: TLineItem[]) => void;
  onSelectToEdit?: (lineItem: TLineItem | undefined) => void;
  itemToEdit?: TLineItem;
  printMode?: boolean;
  onTableFooterHidden?: (hideTableFooter: boolean) => void;
};

const InvoiceView = ({
  invoiceTypeDetails,
  invoice,
  onDeleteLineItems,
  onSelectToEdit,
  itemToEdit,
  onTableFooterHidden,
  printMode,
}: InvoiceViewProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const styles: Styles = useStyles();
  const [hideTableFooter, setHideTableFooter] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);

  const sortedLineItems = useMemo(() => {
    return invoice?.lineItems ? [...invoice.lineItems].sort((a, b) => (a.lineItemId ?? 0) - (b.lineItemId ?? 0)) : [];
  }, [invoice?.lineItems]);

  const dataSource = useMemo(() => {
    return sortedLineItems.map((item) => ({
      ...item,
      amount: item.discount
        ? item.unitPrice * item.quantity * (1 - item.discount / 100)
        : item.unitPrice * item.quantity,
      key: item?.lineItemId,
    }));
  }, [sortedLineItems]);
  const {
    authState: { tenantId },
  } = useAuth();

  const currency = getCurrency(tenantId);
  const transformColumns = (columns: any[]) => {
    return columns.map((col) => {
      const title = eval(col.title); // Evaluate the dynamic title
      const render = col.render ? eval(col.render) : eval('null'); // Evaluate the dynamic render function

      return {
        title,
        dataIndex: col.dataIndex,
        key: col.key,
        width: col.width,
        hidden: col.hidden,
        align: col.align,
        render,
      };
    });
  };

  useEffect(() => {
    if (isEmpty(selectedRowKeys)) return;
    const sections: any = Object.keys(selectedRowKeys);
    // @ts-ignore
    //TODO: fix type issue
    if (sections?.length === 1 && selectedRowKeys[sections[0]]?.length === 1 && itemToEdit === undefined) {
      setSelectedRowKeys([]);
    }
  }, [itemToEdit, selectedRowKeys]);

  useEffect(() => {
    setColumns(transformColumns(invoiceTypeDetails?.tableColumns || []));
  }, [invoice, invoiceTypeDetails]);

  const FIXED_COLUMNS = columns;

  const groupedData = useMemo(() => {
    return dataSource?.reduce((acc: { [key: string]: TLineItem[] }, item) => {
      const section = item.sectionCode || 'default';
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(item);
      return acc;
    }, {});
  }, [dataSource]);

  const rowSelection = (section: string) => ({
    selectedRowKeys: (selectedRowKeys[section as keyof React.Key] as unknown as string[]) || [],
    onChange: (newSelectedRowKeys: React.Key[]) => {
      const newValue: React.Key[] = {
        ...selectedRowKeys,
        [section]: newSelectedRowKeys,
      };
      setSelectedRowKeys(newValue);

      const oneItemArrays: any = Object.values(newValue)?.filter((arr) => Array.isArray(arr) && arr.length === 1);
      if (oneItemArrays.length === 1 && Object.values(newValue).every((arr) => Array.isArray(arr) && arr.length <= 1)) {
        const selectedRow = dataSource?.find((item: TLineItem) => item.key === oneItemArrays[0][0]);
        onSelectToEdit && onSelectToEdit(selectedRow || undefined);
        return;
      }
      onSelectToEdit && onSelectToEdit(undefined);
    },
  });

  const getSelectedItems = () => {
    return (
      Object.keys(selectedRowKeys)
        .flatMap((section) =>
          groupedData?.[section]?.filter((item: TLineItem) =>
            (selectedRowKeys[section as keyof React.Key] as unknown as number[])?.includes(item?.key as number)
          )
        )
        .filter(Boolean) || []
    );
  };

  let aggregateTotalAmount = 0;
  let aggregateTotalDiscount = 0;
  return (
    <div style={styles.invoiceContainer(printMode)}>
      {invoice ? (
        <>
          {invoice && !printMode && (
            <InvoiceHeader
              additionalFields={invoice.additionalFields}
              attn={invoice.attn}
              companyRegNo={invoice.companyRegNo}
              fromAddress={invoice.fromAddress}
              fromContactInfo={invoice.fromContactInfo}
              fromName={invoice.fromName}
              invoiceDate={invoice.invoiceDate}
              invoiceNumber={invoice.invoiceNumber}
              taxRegNo={invoice.taxRegNo}
              toAddress={invoice.toAddress}
              toName={invoice.toName}
              invoiceId={invoice.invoiceId}
              invoiceTypeName={invoice.invoiceType?.invoiceTypeName || 'Invoice'}
            />
          )}
          {!printMode && <Divider style={{ margin: '16px' }} />}
          {groupedData && Object.keys(groupedData).length > 0 && (
            <Flex justify="flex-end" style={styles.titleContainer}>
              {printMode ? null : (
                <Flex gap={6}>
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => {
                      onDeleteLineItems(getSelectedItems() as TLineItem[]);
                      setSelectedRowKeys([]);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="default" icon={<DeleteOutlined />} disabled={!getSelectedItems().length}></Button>
                  </Popconfirm>
                  <Button
                    disabled={Object.keys(groupedData).length === 1}
                    type="default"
                    children={`${hideTableFooter ? 'Show' : 'Hide'} Section Totals`}
                    icon={hideTableFooter ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    onClick={() => {
                      setHideTableFooter(!hideTableFooter);
                      onTableFooterHidden && onTableFooterHidden(!hideTableFooter);
                    }}
                  />
                </Flex>
              )}
            </Flex>
          )}
          {groupedData && Object.keys(groupedData).length > 0 ? (
            Object.keys(groupedData)
              .sort((a, b) => (a === 'default' ? -1 : b === 'default' ? 1 : 0))
              .map((section, index) => {
                const sectionData = groupedData[section];
                const totals = calculateTotals(sectionData);
                aggregateTotalAmount += totals.totalAmount;
                aggregateTotalDiscount += totals.totalDiscount;
                return (
                  <div key={section}>
                    {section !== 'default' && (
                      <Title level={5} style={{ margin: '12px 8px' }}>
                        {section}
                      </Title>
                    )}
                    <div style={{ overflow: 'hidden' }}>
                      <Table
                        tableLayout="fixed"
                        rowSelection={
                          printMode ? undefined : { type: 'checkbox', ...rowSelection(section), hideSelectAll: true }
                        }
                        dataSource={sectionData}
                        columns={FIXED_COLUMNS}
                        pagination={false}
                        size="small"
                        showHeader={index === 0}
                        footer={
                          hideTableFooter || Object.keys(groupedData).length === 1
                            ? undefined
                            : () => (
                                <Flex vertical justify="flex-end" style={{ margin: '8px 0px' }}>
                                  {(() => {
                                    const items = [
                                      { label: `Subtotal (${currency}):`, value: totals.totalAmount.toFixed(2) },
                                      ...(invoice?.invoiceTypeCode === 'TAX_INVOICE'
                                        ? [{ label: `GST (9%):`, value: (totals.totalAmount * 0.09).toFixed(2) }]
                                        : [
                                            {
                                              label: `${section === 'default' ? '' : section} Discount (${currency}):`,
                                              value: totals.totalDiscount.toFixed(2),
                                            },
                                          ]),
                                      {
                                        label: `Net ${section === 'default' ? '' : section} Total (${currency}):`,
                                        value:
                                          invoice?.invoiceTypeCode === 'TAX_INVOICE'
                                            ? (totals.totalAmount + totals.totalAmount * 0.09).toFixed(2)
                                            : (totals.totalAmount - totals.totalDiscount).toFixed(2),
                                      },
                                    ];

                                    return items.map((item, index) => (
                                      <Row gutter={4} key={index} justify={'space-between'} style={styles.tableFooter}>
                                        <Col className="gutter-row" span={16}>
                                          <Text style={{ textTransform: 'capitalize' }}>{item.label}</Text>
                                        </Col>
                                        <Col className="gutter-row" span={8} style={{ textAlign: 'right' }}>
                                          <Text>{formatCurrency(Number(item.value), tenantId, true)}</Text>
                                        </Col>
                                      </Row>
                                    ));
                                  })()}
                                </Flex>
                              )
                        }
                      />
                    </div>
                  </div>
                );
              })
          ) : (
            <>
              {!isEmpty(invoice) && (
                <Table
                  dataSource={[]}
                  columns={FIXED_COLUMNS}
                  pagination={false}
                  size="small"
                  locale={{ emptyText: 'No data available' }}
                />
              )}
            </>
          )}
          {dataSource && dataSource.length > 0 && (
            <Flex vertical justify="flex-end" style={{ marginTop: '32px', marginRight: '0.5rem' }}>
              <Divider type="horizontal" />

              {(() => {
                const items =
                  invoice?.invoiceTypeCode === 'TAX_INVOICE'
                    ? [
                        { label: `Total Amount (${currency}):`, value: aggregateTotalAmount.toFixed(2) },
                        { label: `GST (9%):`, value: (aggregateTotalAmount * 0.09).toFixed(2) },
                        {
                          label: `Grand Total (${currency}):`,
                          value: (aggregateTotalAmount + aggregateTotalAmount * 0.09).toFixed(2),
                        },
                      ]
                    : [
                        { label: `Total Amount (${currency}):`, value: aggregateTotalAmount.toFixed(2) },
                        { label: `Total Discount (${currency}):`, value: aggregateTotalDiscount.toFixed(2) },
                        {
                          label: `Grand Total (${currency}):`,
                          value: (aggregateTotalAmount - aggregateTotalDiscount).toFixed(2),
                        },
                      ];

                return items.map((item, index) => (
                  <Row gutter={4} key={index} justify={'space-between'} style={styles.tableFooter}>
                    <Col className="gutter-row" span={12}>
                      <Text>{item.label}</Text>
                    </Col>
                    <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
                      <Text>{formatCurrency(Number(item.value), tenantId, true)}</Text>
                    </Col>
                  </Row>
                ));
              })()}
            </Flex>
          )}
          {invoice && !printMode && (
            <InvoiceFooter note={invoice?.customerNote} dueDate={invoice?.dueDate} terms={invoice?.terms} />
          )}
        </>
      ) : (
        <>
          <Alert
            message={
              'Use the right panel to add line items and edit the header/footer. Your updated invoice will appear here.'
            }
            type="info"
            showIcon
            style={{ marginBottom: '16px' }}
          />
          <InvoiceViewSkeleton />
        </>
      )}
    </div>
  );
};

export default InvoiceView;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    invoiceContainer: (printMode: boolean | undefined) =>
      ({
        width: printMode ? '100%' : '230mm',
        margin: '0 auto',
        boxShadow: printMode ? '' : '0px 0px 6px #ccc',
        padding: printMode ? '8px' : '32px',
      }) as CSSProperties,
    invoiceTitle: {
      margin: 0,
      lineHeight: 1,
    } as CSSProperties,
    titleContainer: {
      marginBottom: '14px',
    } as CSSProperties,
    syncStatusSuccess: {
      color: 'green',
      fontSize: '12px',
    } as CSSProperties,
    syncStatusPending: {
      color: 'red',
      fontSize: '12px',
      marginBottom: '8px',
    } as CSSProperties,
    invoiceStatusTag: {
      padding: '2px 6px',
      lineHeight: 1,
    } as CSSProperties,
    addLineItemButton: {
      width: '100%',
      borderRadius: '0',
    } as CSSProperties,
    tableFooter: {
      width: '30vw',
      justifySelf: 'flex-end',
      alignSelf: 'flex-end',
    },
  };
};
