import { LeftOutlined } from '@ant-design/icons';
import Sider from 'antd/es/layout/Sider';
import { CSSProperties, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  width: string;
};

const ContentSider = ({
  children,
  collapsed,
  setCollapsed = (collapsed) => {
    return collapsed;
  },
  width,
}: Props) => {
  const styles = useStyles(width);
  return (
    <Sider theme="light" collapsed={collapsed} collapsedWidth={0} width={width} style={styles.sider}>
      <span
        style={styles.siderTrigger(collapsed)}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className={collapsed ? '' : 'sider-trigger'}
      >
        <LeftOutlined />
      </span>
      <div style={styles.contentContainer}>{children}</div>
    </Sider>
  );
};

export default ContentSider;

const useStyles = (width: string) => {
  return {
    sider: {
      backgroundColor: '#f5f4f7',
      minWidth: width,
      position: 'relative',
    } as CSSProperties,
    siderTrigger: (siderCollapsed: boolean): CSSProperties => ({
      zIndex: 100,
      position: 'absolute',
      right: '-20px',
      top: '50vh',
      color: '#ffffff',
      fontSize: '14px',
      cursor: 'pointer',
      width: '40px',
      height: '40px',
      display: 'flex',
      borderRadius: '100%',
      backgroundColor: '#0035cc',
      justifyContent: 'center',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
      transform: `translateY(-50%) ${siderCollapsed ? 'scaleX(-1)' : 'scaleX(1)'}`,
      transition: 'transform 0.5s ease-in-out',
    }),
    contentContainer: { overflow: 'hidden', minWidth: width } as CSSProperties,
  };
};
