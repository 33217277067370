import GalleryLoading from '@/components/GalleryLoading.tsx';
import Gallery from '@/features/inspection/components/Gallery/Gallery.index';
import { InspectionHeader } from '@/features/inspection/components/InspectionHeader.tsx';
import ProgressTimeLine from '@/features/inspection/components/ProgressTimeLine.tsx';
import { fetchInspectionData } from '@/features/inspection/services/inspections.service.ts';
import { useQuery } from '@tanstack/react-query';
import { Card, Flex, Spin } from 'antd';
import { CSSProperties } from 'react';
import { TMediaCardDetails } from '../types';

type Props = {
  uniqueCode: string;
  columns?: number;
  withoutHeader?: boolean;
  hideDetails?: boolean | TMediaCardDetails[];
};

function SingleInspection({ uniqueCode, columns, withoutHeader = false, hideDetails = false }: Props) {
  const styles = useStyles();

  const { data: inspection, isLoading } = useQuery({
    queryKey: ['inspection-data', uniqueCode],
    queryFn: () => fetchInspectionData({ uniqueCode }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
  });

  if (isLoading) {
    if (withoutHeader)
      return (
        <div style={styles.container}>
          <GalleryLoading />
        </div>
      );
    else
      return (
        <div style={styles.container}>
          <Flex align={'center'} justify={'center'} style={{ padding: '1rem' }}>
            <Spin size={'large'} />
          </Flex>
        </div>
      );
  }

  if (withoutHeader)
    return (
      <div style={styles.container}>
        <Flex align="center" style={{ width: '100%' }} justify="center" gap={8}>
          <Gallery
            data={inspection?.media || []}
            columns={columns}
            uniqueCode={uniqueCode}
            refetch={() => {}}
            hideDetails={hideDetails}
          />
        </Flex>
      </div>
    );

  return (
    <div style={styles.container}>
      <Card styles={{ body: styles.cardBody('', '24px 16px') }}>
        {inspection ? (
          <div style={{ width: '100%' }}>
            <ProgressTimeLine items={inspection.timeLine} />
          </div>
        ) : null}
      </Card>
      <Card styles={{ body: styles.cardBody('8vh') }}>
        <InspectionHeader
          uniqueCode={uniqueCode || ''}
          accidentDate={inspection?.accidentDate || ''}
          referenceNumber={inspection?.referenceNumber || ''}
          inspectionType={inspection?.inspectionCode || ''}
        />
      </Card>
      <Flex align="center" style={{ width: '100%' }} justify="center" gap={8}>
        <Gallery
          data={inspection?.media || []}
          columns={columns}
          uniqueCode={uniqueCode}
          refetch={() => {}}
          hideDetails={hideDetails}
        />
      </Flex>
    </div>
  );
}

export default SingleInspection;

type Styles = () => {
  container: CSSProperties;
  cardBody: (height?: string, padding?: string) => CSSProperties;
};

const useStyles: Styles = () => {
  return {
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: '0.5rem',
    },
    cardBody: (height?: string, padding?: string) => ({
      padding: padding || '0.5rem',
      height: height,
      display: 'flex',
      alignItems: 'center',
    }),
  };
};
