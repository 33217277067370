import { formatCurrency } from '@/features/invoices/util';
import { InspectionSurveyReportProps } from '@inspectionReport/components/InspectionSurveyReport';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import pdfMake from 'pdfmake/build/pdfmake';

export const generatePdf = (
  props: InspectionSurveyReportProps,
  currency: string,
  subtotal: number | undefined,
  tenantId: string,
  logoDataUrl: string,
  setPdfDataUrl: (dataUrl: string) => void
) => {
  const showCustomFooter = tenantId !== 'ajax';
  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 60, 40, 60],
    header: {
      stack: [
        { image: logoDataUrl, width: 150, alignment: 'center' },
        {
          canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 2, lineColor: '#0057b7' }],
          alignment: 'center',
        },
      ],
    },
    content: [
      {
        columns: [
          {
            stack: [
              {
                text: props?.invoice?.invoiceDate && 'Date',
                style: 'subHeader',
              },
              {
                text: props?.invoice?.invoiceDate && dayjs(props?.invoice?.invoiceDate).format('Do of MMMM YYYY'),
                style: 'txt',
              },
            ],
          },
          {
            stack: [
              {
                text: props?.invoice?.invoiceType?.invoiceTypeName && props?.invoice?.invoiceType.invoiceTypeName,
                style: 'subHeader',
              },
              {
                text: `#${props?.invoice?.invoiceNumber || props?.invoice?.invoiceId}`,
                style: 'txt',
              },
            ],
            alignment: 'right',
          },
        ],
        margin: [0, 4, 0, 16],
      },
      {
        columns: [
          {
            stack: [
              {
                text: (props?.invoice?.toName || props?.invoice?.toAddress) && 'To',
                style: 'subHeader',
              },
              {
                text: props?.invoice?.toName && props?.invoice?.toName,
                style: 'txt',
              },
              {
                text: props?.invoice?.toAddress && props?.invoice?.toAddress,
                style: 'txt',
              },
            ],
          },
          {
            stack: [
              {
                text: props?.invoice?.fromName && 'From',
                style: 'subHeader',
              },
              {
                text: props?.invoice?.fromName && props?.invoice?.fromName,
                style: 'txt',
              },
              {
                text: props?.invoice?.fromAddress && props?.invoice?.fromAddress,
                style: 'txt',
              },
              {
                text: props?.invoice?.fromContactInfo && 'Contact',
                style: 'subHeader',
                margin: [0, 16, 0, 0],
              },
              {
                text: props?.invoice?.fromContactInfo && props?.invoice?.fromContactInfo,
                style: 'txt',
              },
            ],
            alignment: 'right',
          },
        ],
        margin: [0, 0, 0, 16],
      },
      {
        columns: [
          {
            stack: [
              {
                text: props?.invoice?.attn && 'Attn',
                style: 'subHeader',
              },
              {
                text: props?.invoice?.attn && props?.invoice?.attn,
                style: 'txt',
              },
            ],
          },
          {
            stack: [
              {
                columns: [
                  {
                    text: props?.invoice?.companyRegNo && 'Registration No :',
                    style: 'subHeader',
                  },
                  {
                    text: props?.invoice?.companyRegNo && props?.invoice?.companyRegNo,
                    style: 'txt',
                    alignment: 'right',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: props?.invoice?.taxRegNo && 'GST Registration No :',
                    style: 'subHeader',
                  },
                  {
                    text: props?.invoice?.taxRegNo && props?.invoice?.taxRegNo,
                    style: 'txt',
                    alignment: 'right',
                  },
                ],
              },
            ],
            alignment: 'right',
          },
        ],
        margin: [0, 0, 0, 24],
      },
      {
        columns: [
          {
            stack: [
              {
                text: props?.invoice?.invoiceName,
                style: 'header',
              },
            ],
            alignment: 'center',
          },
        ],
        margin: [0, 0, 0, 16],
      },
      {
        columns: [
          {
            stack: [
              props?.invoice?.additionalFields?.map((field) => {
                if (!isEmpty(field.value)) {
                  return {
                    columns: [
                      {
                        text: `${field.label} :`,
                        style: 'subHeader',
                      },
                      {
                        text: field.value ? field.value : '-',
                        style: 'txt',
                      },
                    ],
                  };
                }
              }),
            ],
            width: [350],
            alignment: 'left',
          },
        ],
        margin: [0, 0, 0, 8],
      },
      {
        style: 'table',
        table: {
          widths: [100, 150, '*', '*', '*'],
          body: [
            [
              { text: 'Item', bold: true, margin: [0, 4, 0, 4], alignment: 'center', fillColor: '#f0f0f0' },
              { text: 'Description', bold: true, margin: [0, 4, 0, 4], alignment: 'center', fillColor: '#f0f0f0' },
              { text: 'Quantity', bold: true, margin: [0, 4, 0, 4], alignment: 'center', fillColor: '#f0f0f0' },
              {
                text: `Unit Price (${currency})`,
                bold: true,
                margin: [0, 4, 0, 4],
                alignment: 'center',
                fillColor: '#f0f0f0',
              },
              {
                text: `Amount (${currency})`,
                bold: true,
                margin: [0, 4, 0, 4],
                alignment: 'center',
                fillColor: '#f0f0f0',
              },
            ],
            ...Object.keys(
              (props?.invoice?.lineItems || []).reduce((acc: { [key: string]: any[] }, item) => {
                const section = item.sectionCode || '';
                if (!acc[section]) {
                  acc[section] = [];
                }
                acc[section].push(item);
                return acc;
              }, {})
            ).flatMap((sectionCode) => [
              ...(sectionCode !== 'default'
                ? [[{ text: sectionCode, colSpan: 5, bold: true, fillColor: '#f0f0f0 ' }, {}, {}, {}, {}]]
                : []),

              // Add the items for this section
              ...(props?.invoice?.lineItems || [])
                .filter((item) => item.sectionCode === sectionCode)
                .map((item) => [
                  item.itemName,
                  item.itemDescription || '-',
                  { text: item.quantity, alignment: 'right' },
                  {
                    text: formatCurrency(item.unitPrice, tenantId, true),
                    alignment: 'right',
                  },
                  {
                    text: formatCurrency(item.quantity * item.unitPrice, tenantId, true),
                    alignment: 'right',
                  },
                ]),
            ]),
          ],
        },
        fontSize: 10,
      },
      {
        columns: [
          {
            stack: [
              {
                columns: [
                  {
                    text: `Subtotal (${currency}) :`,
                    style: 'subHeader',
                  },
                  {
                    text: subtotal ? formatCurrency(Number(subtotal?.toFixed(2)), tenantId, true) : '0.00',
                    style: 'txt',
                  },
                ],
                margin: [0, 2],
              },
              {
                columns: [
                  {
                    text: 'GST (9%) :',
                    style: 'subHeader',
                  },
                  {
                    text: subtotal ? formatCurrency(Number((subtotal * 0.09).toFixed(2)), tenantId, true) : '0.00',
                    style: 'txt',
                  },
                ],
                margin: [0, 2],
              },
              {
                columns: [
                  {
                    text: `Total (${currency}) :`,
                    style: 'subHeader',
                  },
                  {
                    text: subtotal ? formatCurrency(Number((subtotal * 1.09).toFixed(2)), tenantId, true) : '0.00',
                    style: 'txt',
                  },
                ],
                margin: [0, 2],
              },
            ],
            alignment: 'right',
            margin: [0, 16],
          },
        ],
        margin: [0, 20, 0, 0],
      },
      {
        columns: [
          {
            stack: [
              {
                text: props?.invoice?.terms && 'Payment Terms',
                style: 'subHeader',
              },
              {
                text: props?.invoice?.terms && props?.invoice?.terms,
                style: 'txt',
                margin: [0, 0, 0, 8],
              },
              {
                text: props?.invoice?.dueDate && 'Due Date',
                style: 'subHeader',
              },
              {
                text: props?.invoice?.dueDate && dayjs(props?.invoice?.dueDate).format('Do of MMMM YYYY'),
                style: 'txt',
                margin: [0, 0, 0, 8],
              },
              {
                text: props?.invoice?.customerNote && 'Note',
                style: 'subHeader',
              },
              {
                text: props?.invoice?.customerNote && props?.invoice?.customerNote,
                style: 'txt',
                margin: [0, 0, 0, 8],
              },
            ],
          },
        ],
        margin: [0, 0, 0, 8],
      },
      {
        pageBreak: 'before',
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'PRE-REPAIR SURVEY REPORT',
                style: 'subHeader',
                alignment: 'center',
              },
            ],
            [
              {
                stack: [
                  {
                    text: props?.invoice?.toName,
                  },
                  {
                    text: props?.invoice?.toAddress,
                    style: 'txt',
                    margin: [0, 8],
                  },
                  {
                    text: props?.invoice?.attn,
                    style: 'txt',
                  },
                ],
                alignment: 'center',
              },
            ],
            [
              {
                table: {
                  widths: ['*', '*', '*', '*'],

                  body: [
                    [
                      { text: 'Assigned Date:', bold: true },
                      props?.invoiceDetails?.assignDate || 'N/A',
                      { text: 'Survey Date:', bold: true },
                      props?.invoiceDetails?.surveyDate || 'N/A',
                    ],
                    [
                      { text: 'Accident Date:', bold: true },
                      props?.invoiceDetails?.accidentDate || 'N/A',
                      { text: 'TP Policy No:', bold: true },
                      props?.invoiceDetails?.TPPolicyNo || 'N/A',
                    ],
                    [
                      { text: 'Place of Survey:', bold: true },
                      props?.invoiceDetails?.placeOfSurvey || 'N/A',
                      { text: 'Our Ref:', bold: true },
                      props?.invoiceDetails?.ourRef || 'N/A',
                    ],
                    [
                      { text: 'Your Ref:', bold: true },
                      props?.invoiceDetails?.yourRef || 'N/A',
                      { text: 'Claim Type:', bold: true },
                      props?.invoiceDetails?.claimType || 'N/A',
                    ],
                  ],
                },
                fontSize: 10,
                layout: 'noBorders',
                margin: [4, 4],
              },
            ],
            [
              {
                stack: [
                  { text: 'Description of Vehicle:', style: 'tableSubHeader' },
                  {
                    table: {
                      widths: ['*', '*', '*', '*'],
                      body: [
                        [
                          { text: 'Veh. No:', bold: true },
                          props?.vehicleDescription?.vehicleNumber || 'N/A',
                          { text: 'Make/Model:', bold: true },
                          props?.vehicleDescription?.makeOrModel || 'N/A',
                        ],
                        [
                          { text: 'Color:', bold: true },
                          props?.vehicleDescription?.vehicleColor || 'N/A',
                          { text: 'Engine No:', bold: true },
                          props?.vehicleDescription?.engineNo || 'N/A',
                        ],
                        [
                          { text: 'Engine Capacity:', bold: true },
                          props?.vehicleDescription?.vehicleEngineCapacity
                            ? props?.vehicleDescription?.vehicleEngineCapacity
                            : 'N/A',
                          { text: 'Chassis No:', bold: true },
                          props?.vehicleDescription?.chassisNo || 'N/A',
                        ],
                        [
                          { text: 'Odometer:', bold: true },
                          props?.vehicleDescription?.odoMeter || 'N/A',
                          { text: 'Reg. Date:', bold: true },
                          props?.vehicleDescription?.regDate || 'N/A',
                        ],
                      ],
                    },
                    fontSize: 10,
                    layout: 'noBorders',
                    margin: [4, 4],
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  { text: 'Condition of Vehicle:', style: 'tableSubHeader' },
                  {
                    table: {
                      widths: ['*', '*', '*', '*', '*', '*'],
                      body: [
                        [
                          { text: 'General:', bold: true },
                          props?.vehicleCondition?.general || 'N/A',
                          { text: 'Modification:', bold: true },
                          props?.vehicleCondition?.modification || 'N/A',
                          { text: 'Handbrake:', bold: true },
                          props?.vehicleCondition?.handbrake || 'N/A',
                        ],
                        [
                          { text: 'Steering:', bold: true },
                          props?.vehicleCondition?.steering || 'N/A',
                          { text: 'Footbrake:', bold: true },
                          props?.vehicleCondition?.footbrake || 'N/A',
                          { text: 'Tyre Make:', bold: true },
                          props?.vehicleCondition?.tyreMake || 'N/A',
                        ],
                        [
                          { text: 'Paint Work:', bold: true },
                          props?.vehicleCondition?.painWork || 'N/A',
                          { text: 'Front Tyre Size:', bold: true },
                          props?.vehicleCondition?.frontTyreSize || 'N/A',
                          { text: 'Rear Tyre Size:', bold: true },
                          props?.vehicleCondition?.rearTyreSize || 'N/A',
                        ],
                        [
                          { text: 'Front Left Side:', bold: true },
                          props?.vehicleCondition?.frontLeftSize || 'N/A',
                          { text: 'Front Right Side:', bold: true },
                          props?.vehicleCondition?.frontRightSize || 'N/A',
                          { text: 'Rear Left Side:', bold: true },
                          props?.vehicleCondition?.rearLeftSize || 'N/A',
                        ],
                        [
                          { text: 'Rear Right Side:', bold: true },
                          props?.vehicleCondition?.rearRightSize || 'N/A',
                          { text: 'Rear Right Side:', bold: true },
                          props?.vehicleCondition?.rearRightSize || 'N/A',
                          { text: 'Rear Right Side:', bold: true },
                          props?.vehicleCondition?.rearRightSize || 'N/A',
                        ],
                      ],
                    },
                    fontSize: 10,
                    layout: 'noBorders',
                    margin: [4, 4],
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  { text: 'Description of Damage:', style: 'tableSubHeader' },
                  { text: props?.invoiceDetails?.descriptionOfDamage || 'N/A', margin: [0, 4] },
                ],
                fontSize: 10,
              },
            ],
            [
              {
                stack: [
                  { text: 'Remarks:', style: 'tableSubHeader' },
                  { text: props?.invoiceDetails?.remark || 'N/A', margin: [0, 4] },
                ],
                fontSize: 10,
              },
            ],
            [
              {
                columns: [
                  {
                    width: '50%',
                    stack: [
                      { text: '', margin: [0, 50, 0, 0] },
                      {
                        canvas: [
                          {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 200,
                            y2: 0,
                            lineWidth: 1,
                          },
                        ],
                        margin: [0, 20, 0, 0],
                      },
                      {
                        text: 'Adjuster',
                        fontSize: 10,
                        alignment: 'center',
                        margin: [0, 5, 0, 30],
                        bold: true,
                      },
                    ],
                  },
                  {
                    width: '50%',
                    stack: [
                      { text: '', margin: [0, 50, 0, 0] },
                      {
                        canvas: [
                          {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 200,
                            y2: 0,
                            lineWidth: 1,
                          },
                        ],
                        margin: [0, 20, 0, 0],
                      },
                      {
                        text: 'Date',
                        fontSize: 10,
                        alignment: 'center',
                        margin: [50, 5, 0, 30],
                        bold: true,
                      },
                    ],
                    alignment: 'right',
                  },
                ],
                columnGap: 60,
              },
            ],
          ],
        },
        margin: [0, 20, 0, 0],
      },
    ],
    footer: {
      columns: [
        {
          stack: [
            {
              text: 'Note: This report confidential and solely for the use of the client. This inspection was conducted on a "Without Prejudice" basis',
              alignment: 'left',
              fontSize: 8,
              bold: true,
              italics: true,
              margin: [8, 0, 8, 4],
            },
            !showCustomFooter
              ? [
                  {
                    text: `AJAX Inspection Services Pte Ltd`,
                    style: 'subHeader',
                    alignment: 'center',
                    color: '#0057b7',
                  },
                  {
                    text: `120, Lower Delta Road,#08-08, Cendex, Singapore 169208. Tel: +65 6255 0808 Fax: +65 6849 9155`,
                    alignment: 'center',
                    fontSize: 7,
                    color: '#0057b7',
                  },
                  {
                    text: `Email:info@ajaxinspection.com Website:www.ajaxadjusters.com Co. Reg. No:201318067R`,
                    alignment: 'center',
                    fontSize: 7,
                    color: '#0057b7',
                  },
                ]
              : [
                  {
                    text: `${props?.invoice?.fromName ? props?.invoice?.fromName : ''}`,
                    style: 'subHeader',
                    alignment: 'center',
                    color: '#0057b7',
                  },
                  {
                    text: `${props?.invoice?.fromAddress ? props?.invoice?.fromAddress : ''} ${props?.invoice?.fromContactInfo ? props?.invoice?.fromContactInfo : ''}`,
                    alignment: 'center',
                    fontSize: 7,
                    color: '#0057b7',
                  },
                  {
                    text: `${props?.invoice?.fromContactInfo ? props?.invoice?.fromContactInfo : ''} ${props?.invoice?.taxRegNo ? props?.invoice?.taxRegNo : ''}`,
                    alignment: 'center',
                    fontSize: 7,
                    color: '#0057b7',
                  },
                ],
          ],
          width: '100%',
          height: 200,
        },
      ],
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
      },
      subHeader: {
        fontSize: 10,
        bold: true,
      },
      tableSubHeader: {
        fontSize: 10,
        bold: true,
        decoration: 'underline',
      },
      txt: {
        fontSize: 10,
      },
      table: {
        width: 100,
        fontsize: 10,
      },
    },
  };

  pdfMake.createPdf(docDefinition).getDataUrl((dataUrl: string) => {
    setPdfDataUrl(dataUrl);
  });
};
