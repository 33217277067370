import { TComments } from '@/features/inspectionList/types';
import { Flex } from 'antd';
import Text from 'antd/es/typography/Text';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';

type Props = {
  commentList: TComments;
};

const CommentsContainer = ({ commentList }: Props) => {
  const styles = useStyles();
  return (
    <Flex vertical>
      {commentList && commentList.length > 0 ? (
        <Flex vertical style={styles.commentsList} gap={12}>
          {commentList.map((singleComment, index) => {
            const commentedAt = dayjs(singleComment.commentedAt);
            return (
              <Flex vertical key={index} style={styles.commentContainer}>
                <Flex style={styles.commentItemContainer} gap={8}>
                  <Text style={styles.usernameTextStyle}>{singleComment.name}</Text>
                  <Text style={styles.titleTextStyle}>{commentedAt.from(dayjs())}</Text>
                </Flex>
                <Text style={styles.commentTextStyle}>{singleComment.comment}</Text>
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Text>No comments available</Text>
      )}
    </Flex>
  );
};
const useStyles = () => {
  return {
    titleTextStyle: {
      fontSize: 12,
      color: 'black',
      opacity: 0.4,
    } as CSSProperties,
    commentItemContainer: {
      alignItems: 'center',
    } as CSSProperties,
    usernameTextStyle: {
      fontSize: '12px',
      fontWeight: 500,
    } as CSSProperties,
    commentTextStyle: {
      fontSize: '12px',
      marginBottom: '4px',
      color: '#5B5D68',
    } as CSSProperties,
    commentsList: {
      maxHeight: '40vh',
      overflowY: 'scroll',
      flexDirection: 'column-reverse',
    } as CSSProperties,
    commentContainer: {
      backgroundColor: '#f0f5ff',
      borderRadius: '8px',
      padding: '8px',
    } as CSSProperties,
  };
};
export default CommentsContainer;
