import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { TCustomHeaderForm, TInvoiceResponse } from '@invoices/util/types';
import { Button, DatePicker, Flex, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Text from 'antd/es/typography/Text';
import dayjs from 'dayjs';
//import { isValidPhoneNumber } from 'libphonenumber-js';

type CustomHeaderFormProps = {
  invoice: TInvoiceResponse;
  setHeaderData: (data: TCustomHeaderForm) => void;
  loading: boolean;
};

const DynamicForm = () => {
  return (
    <Form.List name="additionalFields">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Flex vertical gap={8} key={key} style={{ marginBottom: '16px' }}>
              <Text children={`Custom Field ${key + 1}`} />
              <Flex gap={8}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  //label={'Custom Field'}
                  {...restField}
                  name={[name, 'label']}
                  // rules={[{ required: true, message: 'Please enter a label' }]}
                >
                  <Input placeholder="Label" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  // label={'Custom Field'}
                  {...restField}
                  name={[name, 'value']}
                  // rules={[{ required: true, message: 'Please enter the value' }]}
                >
                  <Input placeholder="Value" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Flex>
            </Flex>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
              Add field
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

const CustomHeaderForm = ({ loading, invoice, setHeaderData }: CustomHeaderFormProps) => {
  const [form] = Form.useForm();

  const {
    invoiceId,
    invoiceDate,
    fromName,
    fromAddress,
    fromContactInfo,
    toName,
    toAddress,
    attn,
    invoiceNumber,
    companyRegNo,
    taxRegNo,
    additionalFields,
  } = invoice;

  const initialValues = {
    invoiceDate: dayjs(invoiceDate).isValid() ? dayjs(invoiceDate) : dayjs(),
    fromName: fromName || undefined,
    fromAddress: fromAddress || undefined,
    fromContactInfo: fromContactInfo || undefined,
    toName: toName || undefined,
    toAddress: toAddress || undefined,
    attn: attn || undefined,
    invoiceNumber: invoiceNumber || invoiceId,
    companyRegNo: companyRegNo || undefined,
    taxRegNo: taxRegNo || undefined,
    additionalFields: additionalFields || [],
  };
  const onFinish = (values: TCustomHeaderForm) => {
    setHeaderData(values);
  };
  return (
    <Form form={form} onFinish={onFinish} layout="vertical" style={{ width: '100%' }} initialValues={initialValues}>
      <Form.Item
        name="invoiceDate"
        label="Date"
        // rules={[{ required: true, message: 'Please enter the date' }]}
      >
        <DatePicker style={{ width: '100%' }} maxDate={dayjs()} />
      </Form.Item>

      <Form.Item
        name="fromName"
        label="From Company Name"
        //TODO:Make them optional for now
        //rules={[{ required: true, message: 'Please enter the from company name' }]}
      >
        <Input maxLength={100} type="text" placeholder="From Company Name" />
      </Form.Item>

      <Form.Item
        name="fromAddress"
        label="From Address"
        // rules={[{ required: true, message: 'Please enter the from address' }]}
      >
        <TextArea rows={3} maxLength={200} placeholder="From Address" />
      </Form.Item>

      <Form.Item
        name="fromContactInfo"
        label="From Contact Information"
        // rules={[
        //   { required: true, message: 'Please enter the contact information' },
        //   {
        //     validator: async (_, value) => {
        //       if (!value) {
        //         return Promise.reject('Contact Number is required');
        //       }

        //       if (!isValidPhoneNumber('+' + value)) {
        //         return Promise.reject('Contact Number is invalid');
        //       }

        //       return Promise.resolve();
        //     },
        //     message: 'Contact Number is invalid',
        //   },
        // ]}
      >
        <TextArea name="fromContactInfo" placeholder="Contact Information" />
      </Form.Item>

      <Form.Item
        name="toName"
        label="To Company Name"
        // rules={[{ required: true, message: 'Please enter the to company name' }]}
      >
        <Input maxLength={100} type="text" placeholder="To Company Name" />
      </Form.Item>

      <Form.Item
        name="toAddress"
        label="To Address"
        // rules={[{ required: true, message: 'Please enter the to address' }]}
      >
        <TextArea rows={3} maxLength={200} placeholder="To Address" />
      </Form.Item>

      <Form.Item
        name="attn"
        label="Attention"
        // rules={[{ required: true, message: 'Please enter the to address' }]}
      >
        <Input maxLength={100} type="text" placeholder="Attention" />
      </Form.Item>
      <Form.Item
        name="companyRegNo"
        label="Registration No"
        // rules={[{ required: true, message: 'Please enter the registration number' }]}
      >
        <Input maxLength={100} type="text" placeholder="Registration No" />
      </Form.Item>

      <Form.Item
        name="taxRegNo"
        label="GST Registration No"
        // rules={[{ required: true, message: 'Please enter the GST registration no' }]}
      >
        <Input maxLength={100} type="text" placeholder="GST Registration No" />
      </Form.Item>

      <DynamicForm />
      <Form.Item>
        <Flex gap={8} justify="flex-end">
          <Button type="default" htmlType="reset">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default CustomHeaderForm;
