import { useToastApi } from '@/hooks/useToastApi';
import { FileExclamationOutlined } from '@ant-design/icons';
import { markedAsResolved } from '@inspection/services/inspections.service';
import { useMutation } from '@tanstack/react-query';
import { Button, Tooltip } from 'antd';

type Props = {
  isAuthorizedUser: boolean;
  uniqueCode: string;
  token: string;
  tenantId: string;
  refetch: () => void;
};

const MarkAsResolvedButton = ({ isAuthorizedUser, uniqueCode, token, tenantId, refetch }: Props) => {
  const toastApi = useToastApi();
  const showMessage = (
    content: string = 'This feature will be available soon!',
    type: 'warning' | 'error' | 'info' | 'loading' | 'success' = 'warning'
  ) => {
    toastApi.open({
      type: type,
      content: content,
      duration: 2,
    });
  };

  const resolveMutation = useMutation({
    mutationFn: () => markedAsResolved({ tenantId, token, uniqueCode }),
    onSuccess: (res) => {
      showMessage(res.success ? 'Inspection marked as resolved' : res.message, res.success ? 'success' : 'error');
      res.success && refetch();
    },
    onError: () => showMessage('Something went wrong', 'error'),
  });
  return (
    <Tooltip
      placement="bottom"
      title={isAuthorizedUser ? 'Mark this inspection as resolved' : "You don't have permission to resolve"}
      mouseEnterDelay={2}
    >
      <Button
        icon={<FileExclamationOutlined />}
        type="link"
        danger
        onClick={() => {
          resolveMutation.mutate();
        }}
        disabled={!isAuthorizedUser}
        loading={resolveMutation.isPending}
      >
        Mark as resolved
      </Button>
    </Tooltip>
  );
};

export default MarkAsResolvedButton;
