import { StopOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';

type Props = {
  isAuthorizedUser: boolean;
  isInspectionBlocked: boolean;
};

const MarkAsInvalidButton = ({ isAuthorizedUser, isInspectionBlocked }: Props) => {
  return (
    <Space>
      <Tooltip
        placement="bottom"
        title={
          !isAuthorizedUser
            ? "You don't have permission to invalidate"
            : isInspectionBlocked
              ? 'Mark this inspection as resolved first to invalidate'
              : 'Mark this inspection as invalid'
        }
        mouseEnterDelay={2}
      >
        <Button
          icon={<StopOutlined />}
          type="link"
          disabled={!isAuthorizedUser || isInspectionBlocked}
          style={{ color: !isAuthorizedUser || isInspectionBlocked ? '' : 'black' }}
        >
          Mark as invalid
        </Button>
      </Tooltip>
    </Space>
  );
};

export default MarkAsInvalidButton;
