import InvoiceView from '@/features/invoices/components/InvoiceView';
import { TInvoiceResponse } from '@/features/invoices/util/types';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { getInvoiceTypeDetails } from '@invoices/services/invoices.service';
import { useQueries } from '@tanstack/react-query';
import { Button, Col, Flex, Image, Row, Space } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { CSSProperties, useState } from 'react';
import AccidentLocation from '../inspection/components/AccidentLocation';
import { JobDescription, KeyValuePair, TInspectionReportSection } from '../inspection/types';
import ImageSection from './ImageSection';

type ReportDetailProps = {
  label: string;
  value: string;
};

const ReportDetail = ({ label = '', value = '' }: ReportDetailProps) => {
  const styles = useStyles();
  return (
    <Row align={'middle'} gutter={16}>
      <Col span={8}>
        <Text style={styles.label}>{label}</Text>
      </Col>
      <Col span={8}>
        <span style={styles.dottedLine} />
      </Col>
      <Col span={8}>
        <Text style={styles.label}>{value}</Text>
      </Col>
    </Row>
  );
};

const InspectionReportSection = ({
  description,
  content,
  title,
  level,
  width,
  printMode,
  type,
  align,
  showItem,
  hideItem,
}: TInspectionReportSection) => {
  const styles = useStyles();

  const visibleInvoices = Array.isArray(content) ? content.filter((item) => item.lineItems?.length > 0) : [];

  const [invoiceVisibility, setInvoiceVisibility] = useState<boolean[]>(visibleInvoices.map(() => true));
  const [hideInvoiceSection, setHideInvoiceSection] = useState<boolean>(false);

  const toggleSectionVisibility = () => {
    if (hideInvoiceSection) {
      setInvoiceVisibility(visibleInvoices.map(() => true));
      showItem && showItem();
      setHideInvoiceSection(false);
    } else {
      hideItem && hideItem();
      setHideInvoiceSection(true);
    }
  };

  const toggleInvoiceVisibility = (index: number) => {
    setInvoiceVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[index] = !newVisibility[index];
      const allHidden = newVisibility.every((isVisible) => !isVisible);
      setHideInvoiceSection(allHidden);
      return newVisibility;
    });
  };

  const invoiceQueries = useQueries({
    queries: visibleInvoices.map((item: TInvoiceResponse, index: number) => {
      const isVisible = invoiceVisibility[index];
      const invoiceTypeCode = item?.invoiceTypeCode;
      const enabled = isVisible && !!invoiceTypeCode;

      return {
        queryKey: ['invoiceTypeDetails', invoiceTypeCode, index],
        queryFn: () => getInvoiceTypeDetails({ invoiceTypeCode }),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled,
      };
    }),
  });

  return (
    <Flex vertical>
      {title && (
        <Flex style={styles.mainTitle(hideInvoiceSection, level, printMode)} justify="space-between" align="center">
          <Title level={5} children={title} style={styles.title(hideInvoiceSection, level, printMode)} />
          <Text children={description} />
          {!printMode && (
            <Button
              type="text"
              icon={hideInvoiceSection ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              style={styles.hideButton}
              onClick={toggleSectionVisibility}
            />
          )}
        </Flex>
      )}
      {!hideInvoiceSection && (
        <Flex vertical style={{ padding: '1rem' }}>
          {type === 'images' && Array.isArray(content) && <ImageSection content={content} printMode={printMode} />}
          {type === 'jobDescription' && Array.isArray(content) && (
            <Flex style={{ justifyContent: 'space-between' }}>
              {content?.map((item: JobDescription, index: number) => {
                if (item.type === 'table') {
                  return (
                    <Flex key={index} gap={100}>
                      {item.headers?.map((header) => <Text key={header}>{header}</Text>)}
                    </Flex>
                  );
                }
                if (item.type === 'singleImage') {
                  return (
                    <Flex key={index} style={styles.singleImageContainer(align)}>
                      <Image style={styles.singleImage(width)} src={item.src} preview={false} />
                    </Flex>
                  );
                }
              })}
            </Flex>
          )}
          {type === 'paragraph' && <Text children={content} />}
          {type === 'map' && <AccidentLocation location={content} />}
          {type === 'invoice' && (
            <Flex vertical style={{ padding: '1rem' }}>
              {visibleInvoices.map((item: TInvoiceResponse, index: number) => {
                const isVisible = invoiceVisibility[index];
                const invoiceTypeCode = item?.invoiceTypeCode;
                const queryResult = invoiceTypeCode ? invoiceQueries[index] : null;
                const { data: invoiceTypeDetails, error } = queryResult || {};

                if (error) {
                  return <Text type="danger">Error loading invoice details</Text>;
                }

                return (
                  <Flex key={index} vertical style={{ marginBottom: '24px' }}>
                    <Space style={styles.invoiceTitleContainer(isVisible)}>
                      {!printMode && (
                        <>
                          <Title level={5} style={styles.invoiceTitle(isVisible)}>
                            Invoice {index + 1} | {item.invoiceName}
                          </Title>
                          <Button
                            type="text"
                            icon={isVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            onClick={() => toggleInvoiceVisibility(index)}
                          />
                        </>
                      )}
                    </Space>
                    {isVisible && (
                      <InvoiceView
                        invoiceTypeDetails={invoiceTypeDetails}
                        invoice={item}
                        onDeleteLineItems={() => {}}
                        printMode={true}
                      />
                    )}
                  </Flex>
                );
              })}
            </Flex>
          )}
          {type === 'keyValuePairList' && Array.isArray(content) && (
            <Flex vertical style={{ padding: '1rem' }}>
              {content?.map((item: KeyValuePair, index: number) => (
                <ReportDetail key={index} label={item.title} value={item.description} />
              ))}
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default InspectionReportSection;

const useStyles = () => {
  return {
    singleImageContainer: (align?: 'left' | 'center' | 'right') =>
      ({
        justifyContent:
          align === 'left' ? 'start' : align === 'right' ? 'end' : align === 'center' ? 'center' : 'start',
      }) as CSSProperties,
    singleImage: (width?: number) =>
      ({
        width: width,
      }) as CSSProperties,
    label: {
      fontSize: '16px',
      color: '#464956',
    },
    value: {
      fontSize: '16px',
      color: '#464956',
    },
    dottedLine: {
      display: 'block',
      borderBottom: `1px dotted #464956`,
      margin: '0 10px',
      height: '1px',
      width: 'auto',
    } as CSSProperties,
    invoiceTitleContainer: (hidden: boolean) =>
      ({
        marginBottom: 8,
        backgroundColor: hidden ? '#F5F5F6' : '',
        borderRadius: '4px',
        border: hidden ? '' : '2px dashed #E0E0E0',
        alignItems: 'center',
        justifyContent: 'space-between',
      }) as CSSProperties,
    invoiceTitle: (hidden: boolean) =>
      ({
        color: hidden ? 'black' : '#656565',
        margin: 0,
      }) as CSSProperties,
    mainTitle: (hidden: boolean, level: number, printMode?: boolean) =>
      ({
        backgroundColor: printMode ? 'none' : hidden ? '' : level === 1 ? '#E8F1FF' : '#F5F5F6',
        padding: '0 1rem 0 1rem',
        marginTop: '10px',
        borderRadius: '4px',
        border: hidden ? '2px dashed #E0E0E0' : '',
        display: printMode && hidden ? 'none' : 'flex',
      }) as CSSProperties,
    title: (hidden: boolean, level: number, printMode?: boolean) =>
      ({
        margin: 0,
        color: printMode ? '#1677FF' : hidden ? '#656565' : level === 1 ? '#1677FF' : 'black',
        display: printMode && hidden ? 'none' : 'block',
      }) as CSSProperties,
    hideButton: { alignSelf: 'flex-end' },
  };
};
