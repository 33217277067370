import { useToastApi } from '@/hooks/useToastApi.tsx';
import { useCopyToClipboard } from 'usehooks-ts';

const useCopyLink = () => {
  const [copiedText, copy] = useCopyToClipboard();
  const toastApi = useToastApi();

  const copyLink = async (link: URL, linkName: string) => {
    try {
      await copy(link.toString());
      toastApi.open({
        key: 'copying',
        type: 'success',
        content: `Copied the ${linkName} link to clipboard`,
        duration: 2,
      });
      return copiedText;
    } catch (error) {
      toastApi.open({
        key: 'copying',
        type: 'error',
        content: 'Failed to copy the link',
        duration: 2,
      });
    }
  };

  return { copyLink };
};

export default useCopyLink;
