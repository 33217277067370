import { useAuth } from '@/config/AuthContext/useAuth';
import { SEARCH_PARAMS } from '@inspection/constants';
import { Button, Flex, Typography } from 'antd';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

function Home() {
  const styles = useStyles();
  const { authState } = useAuth();

  return (
    <Flex align="center" justify="center" style={styles.placeholder}>
      <Typography.Title level={1}>DigiEye Console (BETA)</Typography.Title>
      <Link
        to={`inspection?${SEARCH_PARAMS.TENANT_ID}=${authState.tenantId}&${SEARCH_PARAMS.TOKEN}=${authState.token}`}
      >
        <Button type="primary">Inspection List</Button>
      </Link>
    </Flex>
  );
}

export default Home;

const useStyles = () => ({
  placeholder: {
    height: 'calc(100vh - 60px)',
    opacity: 0.5,
    width: '100%',
    flexDirection: 'column',
  } as CSSProperties,
});
