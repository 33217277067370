import { useAuth } from '@/config/AuthContext/useAuth.ts';
import env from '@/config/env.ts';
import { useToastApi } from '@/hooks/useToastApi';
import { DownloadOutlined } from '@ant-design/icons';
import { generateInvoicePDF } from '@invoices/services/invoices.service.ts';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Dropdown, Flex, MenuProps } from 'antd';
import Text from 'antd/lib/typography/Text';
import { ReactElement } from 'react';

type ReportActionsProps = {
  estimate: ReactElement;
  surveyReport: ReactElement;
  disabled?: boolean;
  surveyReportPdfDataUrl?: string | undefined;
  referenceNumber?: string;
  inspectionId?: string;
  vehicleNo: string;
};

const ReportActions = (props: ReportActionsProps) => {
  const {
    authState: { token, tenantId },
  } = useAuth();
  const toast = useToastApi();
  const downloadReport = () => {
    const link = document.createElement('a');
    link.href = props.surveyReportPdfDataUrl ?? '';
    link.download = `Survey Report ${props.vehicleNo} ${Date.now()}`;
    link.click();
  };
  const pdfGeneration = async () => {
    toast.info({
      key: 'pdfGeneration',
      content: 'Download will begin shortly!',
      duration: 0,
    });
    await generateInvoicePDF({
      referenceNumber: props.referenceNumber,
      token,
      inspectionId: props.inspectionId,
      vehicleNo: props.vehicleNo,
    })
      .then(() => {
        toast.success({
          key: 'pdfGeneration',
          content: 'Your report has been generated!',
        });
      })
      .catch(() => {
        toast.error({
          key: 'pdfGeneration',
          content: 'An error occurred while generating the report!',
        });
      });
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Flex gap={8}>
          <Text children={'Download Survey Report'} onClick={() => downloadReport()} />
          <DownloadOutlined />
        </Flex>
      ),
    },
    ...(!env.VITE_NODE_ENV.includes('prod') || tenantId === 'ajax'
      ? [
          {
            key: '2',
            label: (
              <Flex gap={8}>
                <Text children={'Download Inspection Report'} onClick={pdfGeneration} />
                <DownloadOutlined />
              </Flex>
            ),
          },
        ]
      : []),
  ];
  return (
    <Flex gap={8}>
      <Dropdown.Button disabled={props?.disabled} menu={{ items }} type="default">
        <PDFDownloadLink document={props?.estimate} fileName={`Invoice ${props?.vehicleNo} ${Date.now()}`}>
          <Flex gap={8}>
            <Text children="Download PDF" />
            <DownloadOutlined />
          </Flex>
        </PDFDownloadLink>
      </Dropdown.Button>
    </Flex>
  );
};

export default ReportActions;
