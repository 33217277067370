import { Input, InputRef, Space } from 'antd';
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react';

type Props = {
  onSave: (value: string | number) => void;
  value?: string | number;
  type?: 'text' | 'number' | 'text-area';
  placeholder?: string;
  textAlign?: 'left' | 'center' | 'right';
  icon?: ReactNode;
  editable?: boolean;
};

function EditableTextView({
  onSave,
  value = '',
  type = 'text',
  placeholder = '',
  textAlign = 'left',
  icon: iconProp,
  editable = true,
}: Props) {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>(value);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const InputElement = type === 'text-area' ? Input.TextArea : Input;
  const icon = editable ? iconProp : null;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const save = () => {
    setEditing(false);
    onSave(inputValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const renderText = (text: string | number) => {
    if (type === 'number') return new Intl.NumberFormat().format(text as number);
    else
      return (text as string).split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
  };

  return editing ? (
    <div style={{ width: '100%' }}>
      <InputElement
        type={type}
        ref={inputRef}
        value={inputValue}
        placeholder={placeholder}
        onBlur={save}
        onChange={handleInputChange}
        autoSize={{ minRows: 3 }}
        style={{ textAlign }}
      />
    </div>
  ) : (
    <div
      style={{ width: '100%', textAlign }}
      className="editable-cell-value-wrap"
      onClick={editable ? () => setEditing(true) : undefined}
    >
      <Space>
        {icon}
        {inputValue ? <p>{renderText(inputValue)}</p> : <span style={{ color: '#ccc' }}>{placeholder}</span>}
      </Space>
    </div>
  );
}

export default EditableTextView;
