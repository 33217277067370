import env from '@/config/env.ts';
import { TLocation } from '@/types';
import { APIProvider, AdvancedMarker, Map } from '@vis.gl/react-google-maps';

type Props = {
  location: TLocation;
  zoomLevel?: number;
};

const MapComponent = ({ location, zoomLevel }: Props) => {
  return (
    <APIProvider apiKey={env.VITE_GOOGLE_MAP_API_KEY}>
      <Map
        defaultCenter={location}
        defaultZoom={zoomLevel ? zoomLevel : env.VITE_GOOGLE_MAP_ZOOM_LEVEL ? env.VITE_GOOGLE_MAP_ZOOM_LEVEL : 12}
        mapId="accidentLocation"
      >
        <AdvancedMarker position={location} />
      </Map>
    </APIProvider>
  );
};

export default MapComponent;
