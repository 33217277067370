export const filterInspectionTypes = (inspectionTypes: [], eventData: any) => {
  return inspectionTypes
    .filter((item: any) => {
      return (
        item.value === eventData?.cls &&
        item.children.some((section: any) => section.value === eventData?.cls + '_' + eventData?.section)
      );
    })
    .map((item: any) => {
      return {
        ...item,
        children: item.children.filter((section: any) => section.value === eventData?.cls + '_' + eventData?.section),
      };
    }) as [];
};
