import ContentOverlay from '@/components/ContentOverlay';
import LocationModal from '@/components/LocationModal';
import { useAuth } from '@/config/AuthContext/useAuth';
import { getInspectionRoute } from '@/constants/API_ROUTES';
import InspectionSummary from '@/features/inspectionList/components/InspectionSummary';
import { INSPECTION_FILTER_STATUSES } from '@/features/inspectionList/constants';
import {
  fetchAssessorList,
  fetchAssignedToList,
  fetchInspectionCount,
  fetchInspectionList,
  fetchInspectionTypes,
} from '@/features/inspectionList/services/inspectionList.service';
import { InspectionRow, SearchParams } from '@/features/inspectionList/types';
import { getStatusColor } from '@/features/inspectionList/utils/statusColor.util';
import { useToastApi } from '@/hooks/useToastApi.tsx';
import { EyeOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  TreeSelect,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ColumnType } from 'antd/es/table';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';
import InspectionCreateForm from './InspectionCreateForm';
import Report from './Report';

function InspectionList() {
  useDocumentTitle('Inspection List | DigiEye');
  const location = useLocation();
  const navigate = useNavigate();

  const toastApi = useToastApi();
  const [referenceNumber, setReferenceNumber] = useState<string>();
  const [vehicleNumber, setVehicleNumber] = useState<string>();
  const [assessor, setAssessor] = useState<string>();
  const [assignedTo, setAssignedTo] = useState<string>();
  const [inspectionType, setInspectionType] = useState<string>();
  const [inspectionStatus, setInspectionStatus] = useState<string>();
  const [isNewInspectionOpen, setIsNewInspectionOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [datePreset, setDatePreset] = useState<number>();
  const [params, setParams] = useState<SearchParams>({
    page: 1,
    limit: 10,
    referenceNumber: undefined,
    vehicleNumber: undefined,
    assessor: undefined,
    assignedTo: undefined,
    inspectionType: undefined,
    inspectionStatus: undefined,
    startDate: undefined,
    endDate: undefined,
  });
  const [isClaimCreated, setIsClaimCreated] = useState<boolean>(false);

  const styles = useStyles();

  const {
    authState: { tenantId, token },
  } = useAuth();

  const {
    data: inspectionList,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [
      'inspection-list',
      params.limit,
      params.page,
      params.referenceNumber,
      params.vehicleNumber,
      params.assessor,
      params.assignedTo,
      params.inspectionType,
      params.inspectionStatus,
      params.startDate,
      params.endDate,
    ],
    queryFn: ({ queryKey }) =>
      fetchInspectionList({
        limit: queryKey[1] as number,
        page: queryKey[2] as number,
        referenceNumber: queryKey[3] as string,
        vehicleNumber: queryKey[4] as string,
        assessor: queryKey[5] as string,
        assignedTo: queryKey[6] as string,
        inspectionType: queryKey[7] as string,
        inspectionStatus: queryKey[8] as string,
        startDate: queryKey[9] as string,
        endDate: queryKey[10] as string,
      }),
    refetchOnWindowFocus: false,
  });

  const { data: inspectionCount, refetch: refetchCount } = useQuery({
    queryKey: [
      'inspection-count',
      params.referenceNumber,
      params.vehicleNumber,
      params.assessor,
      params.assignedTo,
      params.inspectionType,
      params.inspectionStatus,
      params.startDate,
      params.endDate,
    ],
    queryFn: ({ queryKey }) =>
      fetchInspectionCount({
        referenceNumber: queryKey[1],
        vehicleNumber: queryKey[2],
        assessor: queryKey[3],
        assignedTo: queryKey[4],
        inspectionType: queryKey[5],
        inspectionStatus: queryKey[6],
        startDate: queryKey[7],
        endDate: queryKey[8],
      }),
    refetchOnWindowFocus: false,
  });

  const { data: assessors, isLoading: fetchAssessorsLoading } = useQuery({
    queryKey: ['assessor-list'],
    queryFn: () => fetchAssessorList(),
  });

  const { data: inspectionTypes, isLoading: fetchInspectionTypesLoading } = useQuery({
    queryKey: ['inspection-types'],
    queryFn: () => fetchInspectionTypes(),
  });

  const { data: assignedToList, isLoading: fetchAssignedToListLoading } = useQuery({
    queryKey: ['assignedToList-list'],
    queryFn: () => fetchAssignedToList(),
  });

  const handleDatePreset = (option: number) => {
    setDatePreset(option);
    if (option === -1) {
      setStartDate(dayjs().add(option, 'd').startOf('day').toISOString());
      setEndDate(dayjs().add(option, 'd').endOf('day').toISOString());
    } else {
      setStartDate(dayjs().add(option, 'd').startOf('day').toISOString());
      setEndDate(dayjs().endOf('day').toISOString());
    }
  };

  useEffect(() => {
    if (isFetching || isLoading)
      toastApi.open({
        key: 'refetching',
        type: 'loading',
        content: 'Loading...',
        duration: 10,
      });
    else
      toastApi.open({
        key: 'refetching',
        type: 'success',
        content: 'Loaded!',
        duration: 2,
      });
  }, [isFetching, isLoading, toastApi]);

  // Parses URL query parameters and sets state values accordingly
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const referenceNumber = searchParams.get('referenceNumber');
    const vehicleNumber = searchParams.get('vehicleNumber');
    const assessor = searchParams.get('assessor');
    const assignedTo = searchParams.get('assignedTo');
    const inspectionType = searchParams.get('inspectionType');
    const inspectionStatus = searchParams.get('inspectionStatus');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const datePreset = searchParams.get('datePreset');

    if (referenceNumber) setReferenceNumber(referenceNumber);
    if (vehicleNumber) setVehicleNumber(vehicleNumber);
    if (assessor) setAssessor(assessor);
    if (assignedTo) setAssignedTo(assignedTo);
    if (inspectionType) setInspectionType(inspectionType);
    if (inspectionStatus) setInspectionStatus(inspectionStatus);
    if (startDate) setStartDate(startDate);
    if (endDate) setEndDate(endDate);
    if (datePreset) setDatePreset(Number(datePreset));

    setParams((prevParams) => ({
      ...prevParams,
      referenceNumber: referenceNumber ?? undefined,
      vehicleNumber: vehicleNumber ?? undefined,
      assessor: assessor ?? undefined,
      assignedTo: assignedTo ?? undefined,
      inspectionType: inspectionType ?? undefined,
      inspectionStatus: inspectionStatus ?? undefined,
      startDate: startDate ?? undefined,
      endDate: endDate ?? undefined,
    }));
  }, [location.search]);

  const onSearchClick = () => {
    if (
      params.referenceNumber === referenceNumber &&
      params.vehicleNumber === vehicleNumber &&
      params.assessor === assessor &&
      params.inspectionType === inspectionType &&
      params.inspectionStatus === inspectionStatus &&
      params.assignedTo === assignedTo &&
      params.startDate === startDate &&
      params.endDate === endDate
    ) {
      refetch();
      refetchCount();
    } else {
      setParams({
        page: 1,
        limit: params.limit,
        referenceNumber,
        vehicleNumber,
        assessor,
        inspectionType,
        inspectionStatus,
        assignedTo,
        startDate,
        endDate,
      });

      const queryParams = new URLSearchParams(location.search);

      Object.entries(params).forEach(([key, value]) => {
        if (value) queryParams.set(key, value.toString());
        else queryParams.delete(key);
      });

      if (referenceNumber) queryParams.set('referenceNumber', referenceNumber);
      else queryParams.delete('referenceNumber');

      if (vehicleNumber) queryParams.set('vehicleNumber', vehicleNumber);
      else queryParams.delete('vehicleNumber');

      if (assessor) queryParams.set('assessor', assessor);
      else queryParams.delete('assessor');

      if (assignedTo) queryParams.set('assignedTo', assignedTo);
      else queryParams.delete('assignedTo');

      if (inspectionType) queryParams.set('inspectionType', inspectionType);
      else queryParams.delete('inspectionType');

      if (inspectionStatus) queryParams.set('inspectionStatus', inspectionStatus);
      else queryParams.delete('inspectionStatus');

      if (startDate) queryParams.set('startDate', startDate);
      else queryParams.delete('startDate');

      if (endDate) queryParams.set('endDate', endDate);
      else queryParams.delete('endDate');

      if (datePreset) queryParams.set('datePreset', datePreset.toString());
      else queryParams.delete('datePreset');

      navigate({ search: queryParams.toString() });
    }
  };

  const cols: ColumnType<InspectionRow>[] = useMemo(() => {
    if (!inspectionList?.table) return [];

    return inspectionList.table.map((col) => {
      if (col.key === 'referenceNumber') {
        return {
          ...col,
          render: (_, record: InspectionRow) => (
            <Badge status={getStatusColor(record.status)} text={record.referenceNumber} />
          ),
        };
      }
      if (col.key === 'uniqueCode') {
        return {
          ...col,
          render: (_, record: InspectionRow) => (
            <Space>
              <a href={getInspectionRoute(record.uniqueCode, tenantId, token)}>
                <Button icon={<EyeOutlined />} />
              </a>
            </Space>
          ),
        };
      }
      if (col.key === 'status') {
        return {
          ...col,
          render: (_, record: InspectionRow) => (
            <Space>
              <Tag color={getStatusColor(record.status)}>{record.status}</Tag>
            </Space>
          ),
        };
      }
      if (col.key === 'accidentPlace') {
        return {
          ...col,
          render: (_, record: InspectionRow) =>
            record.accidentPlace.toString() !== '-' ? (
              <Space style={styles.accidentPlaceRow}>
                <LocationModal location={record.accidentPlace} title="Accident Place" />
              </Space>
            ) : (
              <span style={styles.accidentPlaceRow}>-</span>
            ),
        };
      } else return col;
    });
  }, [inspectionList?.table, tenantId, token]);

  return (
    <div style={styles.inspectionListContainer}>
      <Flex justify="space-between" align="center" style={styles.buttonBar} gap={8}>
        <div>
          <Breadcrumb separator="/" items={[{ title: 'Home' }, { title: 'Inspections' }]} style={styles.breadCrumb} />
          <Title level={4} style={styles.mainTitle}>
            Inspections Overview
          </Title>
        </div>
        <Flex gap={8}>
          <Tooltip title="Create Inspection" placement="bottom" mouseEnterDelay={2}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsNewInspectionOpen(true)}>
              Create Inspection
            </Button>
          </Tooltip>
          <Report />
          <ContentOverlay
            open={isNewInspectionOpen}
            onCancel={() => setIsNewInspectionOpen(false)}
            title={!isClaimCreated && 'Create Inspection'}
          >
            <InspectionCreateForm
              inspectionTypes={inspectionTypes}
              fetchInspectionTypesLoading={fetchInspectionTypesLoading}
              assessors={assessors}
              fetchAssessorsLoading={fetchAssessorsLoading}
              setOpen={setIsNewInspectionOpen}
              isClaimCreated={setIsClaimCreated}
            />
          </ContentOverlay>
        </Flex>
      </Flex>
      <Card style={styles.filterCard}>
        <Form
          layout="vertical"
          className="filterSection"
          onFinish={() => onSearchClick()}
          onKeyUp={(event) => event.key.toLowerCase() === 'enter' && onSearchClick()}
        >
          <Flex vertical gap={8}>
            <Row gutter={8}>
              <Col span={6}>
                <FormItem label="Job Number" style={styles.formItem}>
                  <Input
                    allowClear
                    style={styles.inputField}
                    placeholder="Job Number"
                    onChange={(event) => setReferenceNumber(event.target.value === '' ? undefined : event.target.value)}
                    value={referenceNumber}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Vehicle Number" style={styles.formItem}>
                  <Input
                    allowClear
                    style={styles.inputField}
                    placeholder="Vehicle Number"
                    onChange={(event) => setVehicleNumber(event.target.value === '' ? undefined : event.target.value)}
                    value={vehicleNumber}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Inspection Type" style={styles.formItem}>
                  <TreeSelect
                    showSearch
                    allowClear
                    placeholder="Select Type"
                    treeData={inspectionTypes}
                    loading={fetchInspectionTypesLoading}
                    onChange={setInspectionType}
                    value={inspectionType ? inspectionType : undefined}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Assessor" style={styles.formItem}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Assessor"
                    style={styles.inputField}
                    onChange={setAssessor}
                    options={assessors || []}
                    loading={fetchAssessorsLoading}
                    value={assessor}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <FormItem label="Assigned to" style={styles.formItem}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Assigned to"
                    style={styles.inputField}
                    onChange={(value) => setAssignedTo(value)}
                    options={assignedToList || []}
                    loading={fetchAssignedToListLoading}
                    value={assignedTo}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Inspection Status" style={styles.formItem}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Inspection Status"
                    style={styles.inputField}
                    onChange={(value) => setInspectionStatus(value)}
                    options={INSPECTION_FILTER_STATUSES || []}
                    value={inspectionStatus}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Relative Date" style={styles.formItem}>
                  <Radio.Group
                    onChange={(value) => handleDatePreset(parseInt(value.target.value))}
                    style={styles.radioGroup}
                    value={datePreset !== undefined ? datePreset.toString() : null}
                  >
                    <Flex justify="end">
                      <Radio.Button value="0" style={styles.radioButton}>
                        Today
                      </Radio.Button>
                      <Radio.Button value="-1" style={styles.radioButton}>
                        Yesterday
                      </Radio.Button>
                      <Radio.Button value="-7" style={styles.radioButton}>
                        7 Days
                      </Radio.Button>
                      <Radio.Button value="-30" style={styles.radioButton}>
                        1 Month
                      </Radio.Button>
                    </Flex>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Date Range" style={styles.formItem}>
                  <Flex gap={8}>
                    <DatePicker.RangePicker
                      value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : [null, null]}
                      placeholder={['Start Date', 'End Date']}
                      onChange={(value) => {
                        setDatePreset(undefined);
                        setStartDate(value ? value[0]?.toISOString() : undefined);
                        setEndDate(value ? dayjs(value[1])?.endOf('day')?.toISOString() : undefined);
                      }}
                      style={styles.inputField}
                    />
                    <Button htmlType={'submit'} type="default" icon={<SearchOutlined />}>
                      Search
                    </Button>
                  </Flex>
                </FormItem>
              </Col>
            </Row>
          </Flex>
        </Form>
      </Card>
      <Table
        rowClassName={(_, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
        loading={isLoading}
        columns={cols}
        dataSource={inspectionList?.data || []}
        expandable={{
          rowExpandable: () => true,
          expandedRowRender: (record) => <InspectionSummary uniqueCode={record.uniqueCode} />,
          columnTitle: 'Summary',
          fixed: 'right',
        }}
        scroll={{ x: 'max-content' }}
        pagination={{
          onChange: (page, pageSize) => setParams((state) => ({ ...state, page, limit: pageSize })),
          total: inspectionCount || 10,
          pageSize: params.limit,
          current: params.page,
        }}
      />
    </div>
  );
}

export default InspectionList;

const useStyles = () => {
  return {
    accidentPlaceRow: {
      textAlign: 'center',
      width: '100%',
      display: 'block',
    } as CSSProperties,
    inspectionListContainer: {
      padding: '0px 16px',
      backgroundColor: '#F5F4F7',
    } as CSSProperties,
    mainTitle: {
      margin: 0,
      fontWeight: '500',
    } as CSSProperties,
    filterCard: { margin: '16px 0' } as CSSProperties,
    formItem: {
      marginBottom: 8,
    } as CSSProperties,
    inputField: {
      width: '100%',
    } as CSSProperties,
    radioButton: { width: '100%', textAlign: 'center', padding: 0, textWrap: 'nowrap' } as CSSProperties,
    radioGroup: { width: '100%', overflow: 'hidden' } as CSSProperties,
    buttonBar: { marginTop: 16 } as CSSProperties,
    breadCrumb: { fontSize: '12px' } as CSSProperties,
  };
};
