import { FileExclamationOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';

type Props = {
  isAuthorizedUser: boolean;
  isInspectionInvalid: boolean;
  isInspectionCompleted: boolean;
};

const MarkAsBlockedButton = ({ isAuthorizedUser = false, isInspectionInvalid, isInspectionCompleted }: Props) => {
  return (
    <Space>
      <Tooltip
        placement="bottom"
        title={
          !isAuthorizedUser
            ? "You don't have permission to block"
            : isInspectionInvalid
              ? 'Inspection is marked as invalid'
              : isInspectionCompleted
                ? 'Already Completed'
                : 'Mark this inspection as blocked'
        }
        mouseEnterDelay={2}
      >
        <Button
          icon={<FileExclamationOutlined />}
          type="link"
          disabled={isInspectionInvalid || isInspectionCompleted || !isAuthorizedUser}
          style={{
            color: isInspectionInvalid || isInspectionCompleted || !isAuthorizedUser ? '' : 'black',
          }}
        >
          Mark as blocked
        </Button>
      </Tooltip>
    </Space>
  );
};

export default MarkAsBlockedButton;
