import env from '@/config/env.ts';
import { TLocation } from '@/types';
import { MessageInstance } from 'antd/es/message/interface';
import { useCopyToClipboard } from 'usehooks-ts';

/**
 * Convert the location object to Google map location URL
 * @param location : TLocation object with latitude & longitude
 */
export const getLocationURL = (location: TLocation) => {
  const baseUrl = env.VITE_GOOGLE_MAP_LINK || 'https://google.com/maps';
  return `${baseUrl}?q=${location.lat},${location.lng}`;
};

export const useCopyLocation = (toastApi: MessageInstance) => {
  const [copiedText, copy] = useCopyToClipboard();

  const copyLocationToClipboard = async (location: TLocation) => {
    const locationUrl = getLocationURL(location);
    try {
      await copy(locationUrl);
      toastApi.open({
        key: 'copying',
        type: 'success',
        content: 'Link copied!',
        duration: 2,
      });
    } catch (error) {
      toastApi.open({
        key: 'copying',
        type: 'error',
        content: 'Failed to copy the link',
        duration: 2,
      });
    }
  };

  return { copiedText, copyLocationToClipboard };
};
