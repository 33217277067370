import { InspectionHeader } from '@/features/inspection/components/InspectionHeader.tsx';
import SingleInspection from '@/features/inspection/components/SingleInspection.tsx';
import { RightOutlined } from '@ant-design/icons';
import { fetchOtherInspections } from '@inspection/services/inspections.service';
import { useQuery } from '@tanstack/react-query';
import { Collapse, Empty, Flex, Spin } from 'antd';
import { useMemo } from 'react';

type Props = {
  uniqueCode: string;
  vehicleNumber?: string;
};

const OtherInspections = (props: Props) => {
  const {
    data: otherInspections,
    isLoading: otherInspectionLoading,
    isFetching: isOtherInspectionFetching,
  } = useQuery({
    queryKey: ['other-inspections', props.vehicleNumber, props.uniqueCode],
    queryFn: () =>
      fetchOtherInspections({
        uniqueCode: props.uniqueCode,
        vehicleNumber: props.vehicleNumber!,
      }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
    enabled: !!props.vehicleNumber,
  });

  const styles: Styles = useStyles();
  const items = useMemo(
    () =>
      (otherInspections || []).map((inspection) => ({
        key: inspection.uniqueCode,
        style: {
          marginBottom: '12px',
          backgroundColor: '#fff',
          border: '1px solid #f0f0f0',
          borderRadius: '8px',
        },
        label: (
          <InspectionHeader
            uniqueCode={inspection.uniqueCode}
            inspectionType={inspection.inspectionType}
            referenceNumber={inspection.referenceNumber}
            accidentDate={inspection.accidentDate}
          />
        ),
        children: <SingleInspection uniqueCode={inspection.uniqueCode} columns={2} withoutHeader />,
      })),
    [otherInspections]
  );
  if (isOtherInspectionFetching || otherInspectionLoading)
    return (
      <Flex align={'center'} justify={'center'} style={{ padding: '1rem' }}>
        <Spin size={'large'} />
      </Flex>
    );

  if (items.length < 1) return <Empty />;

  return (
    <Collapse
      collapsible="icon"
      items={items}
      style={styles.collapse}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <span style={styles.expandIcon}>
          <RightOutlined rotate={isActive ? 90 : 0} />
        </span>
      )}
    />
  );
};

export default OtherInspections;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    expandIcon: {
      color: '#0035cc',
      fontSize: '10px',
      cursor: 'pointer',
      width: '30px',
      height: '30px',
      display: 'flex',
      borderRadius: '100%',
      backgroundColor: '#fff',
      justifyContent: 'center',
      border: '1px solid rgba(22, 119, 255, 0.15)',
      position: 'absolute',
      bottom: '12px',
      right: '24px',
    },
    collapse: {
      border: 'none',
      backgroundColor: 'transparent',
    },
  } as const;
};
