import { useAuth } from '@/config/AuthContext/useAuth.ts';
import { queryClient } from '@/config/http/react-query.config.ts';
import { useToastApi } from '@/hooks/useToastApi.tsx';
import { RightOutlined } from '@ant-design/icons';
import { EMPTY_INCIDENT_DETAILS } from '@inspection/components/IncidentDetails/IncidentDetailsData.ts';
import { KeyValuePair } from '@inspection/components/IncidentDetails/SubSectionDetails';
import SubSectionDetails from '@inspection/components/IncidentDetails/SubSectionDetails.tsx';
import {
  createOrUpdateIncidentDetails,
  fetchIncidentDetails,
} from '@inspectionList/services/inspectionList.service.ts';
import { TCreateInspection } from '@inspectionList/types.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Collapse, Empty, Flex, Spin, Typography } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';

const { Title } = Typography;
const { Panel } = Collapse;

export interface IncidentDetailsProps {
  section: string;
  data: KeyValuePair[];
}

type Props = {
  isForm: boolean;
  referenceNumber: string;
};

const IncidentDetails = ({ referenceNumber, isForm }: Props) => {
  const styles = useStyles();
  const toastApi = useToastApi();
  const { authState } = useAuth();

  const [data, setData] = useState<Array<IncidentDetailsProps>>([]);

  const { data: incidentDetailsData, isLoading: incidentDetailsIsLoading } = useQuery({
    queryKey: ['incident-details', referenceNumber],
    queryFn: () => fetchIncidentDetails(referenceNumber),
  });

  const { mutate } = useMutation({
    mutationFn: ({ form: submittedValues }: TCreateInspection) =>
      createOrUpdateIncidentDetails({ form: submittedValues, referenceNumber }),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ['incident-details', referenceNumber] });
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? 'Inspection details updated successfully!' : data.message,
        duration: 2,
      });
    },
    onError: (error) => {
      if (error instanceof Error)
        toastApi.open({
          type: 'error',
          content: error?.message || 'Something went wrong!',
          duration: 2,
        });
    },
  });

  useEffect(() => {
    if (incidentDetailsData && data.length < 1) {
      if (incidentDetailsData && incidentDetailsData.length > 0) {
        setData(incidentDetailsData);
      } else {
        setData(EMPTY_INCIDENT_DETAILS);
      }
    }
  }, [incidentDetailsData]);

  const handleSave = (section: string, newData: KeyValuePair[]) => {
    const updatedData = data.map((item) => (item.section === section ? { ...item, data: newData } : item));
    setData(updatedData);
    if (isForm) mutate({ form: updatedData });
  };

  if (data.length < 1) return <Empty description={'No Incident Overview Details'} />;

  return (
    <Collapse
      accordion
      style={styles.collapse}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <span style={styles.expandIcon}>
          <RightOutlined rotate={isActive ? 90 : 0} />
        </span>
      )}
      defaultActiveKey={authState.tenantId === 'ajax' ? '1' : undefined}
    >
      <Panel header={<Title level={5} style={styles.title}>{`Incident Overview`}</Title>} key="1" style={styles.panel}>
        {incidentDetailsIsLoading ? (
          <Flex align={'center'} justify={'center'} style={styles.spinnerContainer}>
            <Spin size={'large'} />
          </Flex>
        ) : (
          <>
            {data?.map((item, index) => (
              <SubSectionDetails
                key={index}
                section={item.section}
                data={item.data}
                isForm={isForm}
                onSave={(newData) => handleSave(item.section, newData)}
              />
            ))}
          </>
        )}
      </Panel>
    </Collapse>
  );
};

const useStyles = () => {
  return {
    collapse: {
      backgroundColor: 'rgba(240, 245, 255, 0.5)',
    } as CSSProperties,
    expandIcon: {
      color: '#0035cc',
      fontSize: '10px',
      cursor: 'pointer',
      width: '30px',
      height: '30px',
      display: 'flex',
      borderRadius: '100%',
      backgroundColor: '#fff',
      justifyContent: 'center',
      border: '1px solid rgba(22, 119, 255, 0.15)',
    } as CSSProperties,
    title: {
      margin: 0,
      color: '#0035cc',
    } as CSSProperties,
    panel: {
      width: '100%',
    } as CSSProperties,
    spinnerContainer: {
      padding: '1rem',
    } as CSSProperties,
  };
};

export default IncidentDetails;
