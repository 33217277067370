import { KeyValuePairList } from '@inspection/types';
import { Button, Col, Divider, Flex, Row, Spin, Statistic, Typography } from 'antd';
import { CSSProperties, useState } from 'react';

type Props = {
  title?: string;
  columns?: number;
  data: KeyValuePairList;
  defaultExpandedItems?: number;
  more?: boolean;
  showDivider?: boolean;
  isLoading?: boolean;
};

const StatisticGrid = ({
  title,
  columns = 3,
  data = [],
  defaultExpandedItems = data.length || 10,
  more = false,
  showDivider = false,
  isLoading = false,
}: Props) => {
  const [expandedRows, setExpandedRows] = useState<number>(defaultExpandedItems);
  const styles: Styles = useStyles();

  const handleMoreClick = () => {
    if (expandedRows === data.length) {
      setExpandedRows(defaultExpandedItems);
    } else {
      setExpandedRows(data.length);
    }
  };

  return (
    <Col style={styles.row}>
      {title && <Typography.Text style={styles.title}>{title}</Typography.Text>}
      <Row style={styles.row}>
        {isLoading ? (
          <Flex align={'center'} justify={'center'} style={{ padding: '1rem' }}>
            <Spin size={'large'} />
          </Flex>
        ) : (
          <>
            {data?.map((item, index) => (
              <Col span={24 / columns} key={index} style={styles.col}>
                <div style={styles.statisticContainer(index < expandedRows)}>
                  <Statistic style={styles.statistic} title={item.title} value={item.description} groupSeparator={''} />
                </div>
              </Col>
            ))}
          </>
        )}
      </Row>
      {more && (
        <Col span={12} style={styles.moreButtonCol}>
          <Button size="small" type="link" onClick={handleMoreClick} style={styles.moreButton}>
            {expandedRows === data.length ? 'Hide' : 'More'}
          </Button>
        </Col>
      )}
      {showDivider && <Divider type="horizontal" style={{ backgroundColor: '#d9d9d9', marginTop: '4px' }} />}
    </Col>
  );
};

export default StatisticGrid;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    row: {
      width: '100%',
    } as CSSProperties,
    col: {
      padding: '0 4px',
    } as CSSProperties,
    statistic: {
      textWrap: 'wrap',
      overflowWrap: 'anywhere',
    } as CSSProperties,
    moreButtonCol: {
      textAlign: 'start',
    } as CSSProperties,
    title: {
      marginLeft: '4px',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#0035cc',
    } as CSSProperties,
    moreButton: {
      padding: 0,
      marginLeft: 4,
      fontSize: 12,
    } as CSSProperties,
    statisticContainer: (expandedRows: boolean) => ({
      marginTop: expandedRows ? '15px' : '0',
      marginBottom: expandedRows ? '8px' : '0',
      maxHeight: expandedRows ? '100px' : '0',
      opacity: expandedRows ? 1 : 0,
      transition: 'max-height 0.3s, opacity 0.3s',
      overflow: 'hidden',
    }),
  };
};
