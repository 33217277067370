import { EyeInvisibleOutlined, EyeOutlined, UndoOutlined } from '@ant-design/icons';
import Grid from '@components/Grid.tsx';
import { InspectionEvidenceData, TEvidence } from '@inspection/types.ts';
import { Button, Flex, Image, Typography } from 'antd';
import dayjs from 'dayjs';
import { CSSProperties, useState } from 'react';

const ImageSection = ({ content, printMode }: { content: InspectionEvidenceData[]; printMode?: boolean }) => {
  const styles = useStyles();

  const [imageVisibility, setImageVisibility] = useState<{ [key: string]: boolean }[]>(
    content.map((inspection: InspectionEvidenceData) => {
      const visibility: { [key: string]: boolean } = {};
      inspection.evidence.forEach((evidenceItem: TEvidence) => {
        visibility[evidenceItem.photoKey] = true;
      });
      return visibility;
    })
  );

  const [hiddenImages, setHiddenImages] = useState<string[][]>(content.map(() => []));

  const toggleImageVisibility = (inspectionIndex: number, evidenceId: string) => {
    const newVisibility = [...imageVisibility];
    const sectionVisibility = { ...newVisibility[inspectionIndex] };
    sectionVisibility[evidenceId] = !sectionVisibility[evidenceId];
    newVisibility[inspectionIndex] = sectionVisibility;

    const isHidden = !sectionVisibility[evidenceId];

    const newHiddenImages = [...hiddenImages];
    if (isHidden) {
      newHiddenImages[inspectionIndex] = [...newHiddenImages[inspectionIndex], evidenceId];
    } else {
      newHiddenImages[inspectionIndex] = newHiddenImages[inspectionIndex].filter((id) => id !== evidenceId);
    }

    setImageVisibility(newVisibility);
    setHiddenImages(newHiddenImages);
  };

  const showAllImages = (inspectionIndex: number) => {
    const newVisibility = [...imageVisibility];
    const sectionVisibility = { ...newVisibility[inspectionIndex] };

    Object.keys(sectionVisibility).forEach((key) => {
      sectionVisibility[key] = true;
    });

    newVisibility[inspectionIndex] = sectionVisibility;

    const newHiddenImages = [...hiddenImages];
    newHiddenImages[inspectionIndex] = [];

    setImageVisibility(newVisibility);
    setHiddenImages(newHiddenImages);
  };

  const hideAllImages = (inspectionIndex: number) => {
    const newVisibility = [...imageVisibility];
    const sectionVisibility = { ...newVisibility[inspectionIndex] };

    Object.keys(sectionVisibility).forEach((key) => {
      sectionVisibility[key] = false;
    });

    newVisibility[inspectionIndex] = sectionVisibility;

    const newHiddenImages = [...hiddenImages];
    newHiddenImages[inspectionIndex] = Object.keys(sectionVisibility);

    setImageVisibility(newVisibility);
    setHiddenImages(newHiddenImages);
  };

  const undoHideImage = (inspectionIndex: number) => {
    const sectionHidden = [...hiddenImages[inspectionIndex]];

    if (sectionHidden.length > 0) {
      const lastHiddenId = sectionHidden.pop()!;

      const newVisibility = [...imageVisibility];
      const sectionVisibility = { ...newVisibility[inspectionIndex] };
      sectionVisibility[lastHiddenId] = true;
      newVisibility[inspectionIndex] = sectionVisibility;

      const newHiddenImages = [...hiddenImages];
      newHiddenImages[inspectionIndex] = sectionHidden;

      setImageVisibility(newVisibility);
      setHiddenImages(newHiddenImages);
    }
  };

  return (
    <Flex vertical>
      {content.map((inspection: InspectionEvidenceData, inspectionIndex: number) => {
        if (inspection.evidence.length === 0) return null;

        const sectionVisibility = imageVisibility[inspectionIndex];
        const visibilityValues = Object.values(sectionVisibility);
        const allImagesHidden = visibilityValues.every((visible) => !visible);

        return (
          <div key={inspectionIndex} style={styles.sectionStyle}>
            {!printMode || !allImagesHidden ? (
              <Flex justify="space-between" style={styles.flexStyle(allImagesHidden)} gap={8}>
                <Flex gap={8}>
                  <Typography.Text style={styles.typographyText}>{inspection.inspectionTypeName}</Typography.Text>
                  <Typography.Text style={styles.typographyText}>|</Typography.Text>
                  <Typography.Text style={styles.typographyText}>
                    {dayjs(inspection.inspectionDate).format('MMMM D, YYYY h:mm A')}
                  </Typography.Text>
                </Flex>
                {!printMode && (
                  <Flex gap={8}>
                    <Button
                      onClick={() => undoHideImage(inspectionIndex)}
                      style={styles.buttonStyle}
                      disabled={hiddenImages[inspectionIndex].length === 0}
                      icon={<UndoOutlined />}
                    >
                      Undo
                    </Button>
                    <Button
                      onClick={() => {
                        if (hiddenImages[inspectionIndex].length > 0) {
                          showAllImages(inspectionIndex);
                        } else {
                          hideAllImages(inspectionIndex);
                        }
                      }}
                      icon={hiddenImages[inspectionIndex].length > 0 ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    >
                      {hiddenImages[inspectionIndex].length > 0 ? 'View All' : 'Hide All'}
                    </Button>
                  </Flex>
                )}
              </Flex>
            ) : null}

            <Grid columns={2}>
              {inspection.evidence.map(
                (item: TEvidence) =>
                  item.type === 'image' &&
                  imageVisibility[inspectionIndex][item.photoKey] && (
                    <div style={styles.imageContainerStyle} key={item.photoKey}>
                      <Image src={item.src} style={styles.imageStyle} preview={false} />
                      {!printMode && (
                        <Button
                          type="text"
                          icon={<EyeInvisibleOutlined />}
                          style={styles.hideButtonStyle}
                          onClick={() => toggleImageVisibility(inspectionIndex, item.photoKey)}
                        />
                      )}
                    </div>
                  )
              )}
            </Grid>
          </div>
        );
      })}
    </Flex>
  );
};

export default ImageSection;

const useStyles = () => {
  return {
    sectionStyle: {
      marginBottom: '20px',
    } as CSSProperties,
    flexStyle: (allImagesHidden: boolean) =>
      ({
        marginBottom: '10px',
        alignItems: 'center',
        padding: 8,
        borderRadius: '4px',
        backgroundColor: allImagesHidden ? '#fff' : '#F5F5F6',
        border: allImagesHidden ? '2px dashed #E0E0E0' : '',
      }) as CSSProperties,
    typographyText: {
      fontSize: 14,
      fontWeight: 600,
    } as CSSProperties,
    buttonStyle: {
      marginLeft: '10px',
    } as CSSProperties,
    imageContainerStyle: {
      position: 'relative',
    } as CSSProperties,
    imageStyle: {
      width: '100%',
    } as CSSProperties,
    hideButtonStyle: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      color: '#000',
      background: '#fff',
      backdropFilter: 'blur(5px)',
      borderRadius: '8px',
      padding: '8px',
    } as CSSProperties,
  };
};
