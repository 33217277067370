import { fetchInspectionData } from '@inspection/services/inspections.service.ts';
import { useQuery } from '@tanstack/react-query';

export function useInspectionQuery({ uniqueCode }: { uniqueCode: string | undefined }) {
  return useQuery({
    queryKey: ['inspection-data', uniqueCode],
    queryFn: () => fetchInspectionData({ uniqueCode }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
  });
}
