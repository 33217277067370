import Logo from '@/components/Logo';
import { useAuth } from '@/config/AuthContext/useAuth';
import env from '@/config/env.ts';
import { DigiEyeApi } from '@/config/http/axios.api-config.ts';
import { UnauthorizedErrorRedirect } from '@/config/http/unauthorized-error.util.ts';
import { CLIENT_ROUTES } from '@/constants/API_ROUTES';
import { getColorForName, getCurrentUser, getInitials } from '@/features/inspection/utils/auth.util';
import {
  DownOutlined,
  ExportOutlined,
  LogoutOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Flex, Menu, MenuProps } from 'antd';
import { Header } from 'antd/es/layout/layout';
import Text from 'antd/es/typography/Text';
import { CSSProperties, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    label: 'Inspection Overview',
    key: 'list',
  },
];

const userItems: MenuItem[] = [
  {
    key: 'analyticsDashboard',
    icon: <PieChartOutlined />,
    label: (
      <Flex gap={8}>
        Analytics Dashboard <ExportOutlined />{' '}
      </Flex>
    ),
  },
  {
    key: 'userManagement',
    icon: <TeamOutlined />,
    label: (
      <Flex gap={8}>
        User Management <ExportOutlined />{' '}
      </Flex>
    ),
  },
  {
    key: 'brandInspectionSettings',
    icon: <SettingOutlined />,
    label: (
      <Flex gap={8}>
        Brand & Inspection Settings <ExportOutlined />{' '}
      </Flex>
    ),
  },
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    style: { color: 'red' },
    label: ' Log Out',
  },
];

const logout = async () => {
  try {
    await DigiEyeApi.get('auth/v1/logout');
    UnauthorizedErrorRedirect(true);
  } catch (e) {
    console.log(JSON.stringify(e));
  }
};

const onMenuItemClick = (key: string) => {
  switch (key) {
    case 'analyticsDashboard':
      window.open(`${env.VITE_DASHBOARD_URL}home`, '_blank');
      break;
    case 'userManagement':
      window.open(`${env.VITE_DASHBOARD_URL}users`, '_blank');
      break;
    case 'brandInspectionSettings':
      window.open(`${env.VITE_DASHBOARD_URL}console/company/info`, '_blank');
      break;
    case 'logout':
      logout();
      break;
    default:
      break;
  }
};

const Nav = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<{ name: string; email: string }>();
  const {
    authState: { token, tenantId },
  } = useAuth();

  useEffect(() => {
    if (token) setCurrentUser(getCurrentUser(token));
  }, [token]);

  return (
    <>
      <Header style={styles.topNavigationBar}>
        <Flex style={styles.menuContainer} align={'center'}>
          <a
            onClick={() => {
              navigate(CLIENT_ROUTES.INSPECTION_LIST(tenantId, token));
            }}
          >
            <Logo />
          </a>
          <Menu
            onClick={() => {
              navigate(CLIENT_ROUTES.INSPECTION_LIST(tenantId, token));
            }}
            mode="horizontal"
            items={items}
            style={styles.menuStyle}
          />

          {currentUser ? (
            <Dropdown
              menu={{ items: userItems, onClick: ({ key }) => onMenuItemClick(key) }}
              trigger={['click']}
              placement="bottomRight"
              overlayStyle={styles.userMenu}
              align={{ offset: ['6%', '20%'] }}
              arrow
            >
              <Flex gap={16}>
                <Flex gap={8} align="center">
                  <Avatar
                    size={32}
                    style={styles.avatar(getColorForName(currentUser?.name))}
                    children={getInitials(currentUser?.name)}
                  />
                  <Flex vertical>
                    <Text style={{ lineHeight: 1.2, fontSize: '14px' }}>{currentUser?.name}</Text>
                    <Text style={{ lineHeight: 1.2, fontSize: '12px' }}>{currentUser?.email}</Text>
                  </Flex>
                </Flex>
                <DownOutlined />
              </Flex>
            </Dropdown>
          ) : null}
        </Flex>
      </Header>
      <Outlet />
    </>
  );
};

const useStyles = () => {
  return {
    topNavigationBar: {
      background: 'white',
      padding: '0px 16px',
      position: 'sticky',
      top: 0,
      zIndex: 2000,
      alignContent: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '64px',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.1)',
    } as CSSProperties,
    menuContainer: { width: '100%' } as CSSProperties,
    menuStyle: {
      flex: 1,
      minWidth: 0,
    } as CSSProperties,
    avatar: (color: string) => ({
      backgroundColor: color,
      fontSize: '16px',
    }),
    userMenu: {
      zIndex: 10000,
    },
  };
};

export default Nav;
