import { useAuth } from '@/config/AuthContext/useAuth';
import { getInspectionInvoiceRoute } from '@/constants/API_ROUTES';
import {
  createInvoice as createInvoiceService,
  getInvoiceTypes,
  getInvoicesSummery,
} from '@/features/invoices/services/invoices.service';
import { useToastApi } from '@/hooks/useToastApi';
import { FileTextOutlined, PlusOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons';
import { formatCurrency } from '@invoices/util';
import { TCreateInvoiceForm, TErrorResponse } from '@invoices/util/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Col, Collapse, Flex, Form, Input, Popover, Row, Select, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { CSSProperties, useEffect, useState } from 'react';
dayjs.extend(RelativeTime);

type Props = {
  uniqueCode?: string;
  referenceNumber?: string;
  vehicleNumber?: string;
  containerStyles?: CSSProperties;
};

function Invoices({ uniqueCode, referenceNumber, vehicleNumber, containerStyles }: Props) {
  const {
    authState: { token, tenantId },
  } = useAuth();
  const toast = useToastApi();
  const {
    data: response,
    isFetching: isInvoicesLoading,
    refetch: refetchInvoices,
  } = useQuery({
    queryKey: ['inspection-invoices-summary', uniqueCode],
    queryFn: () => getInvoicesSummery({ uniqueCode: uniqueCode as string }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
    enabled: !!uniqueCode,
  });

  const queryClient = useQueryClient();

  const { mutate: createInvoice } = useMutation({
    mutationFn: createInvoiceService,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ['inspection-invoices-summary', uniqueCode] });
      queryClient.invalidateQueries({ queryKey: ['inspection-invoices', uniqueCode] });
      toast.success({
        content: res.message || 'Invoice added successfully!',
        duration: 2,
      });
    },
    onError: (res: TErrorResponse) => {
      const message = res?.response?.data?.message?.includes('invoiceNumber')
        ? 'Invoice number already exists'
        : res?.message || 'Failed to add, please try again';

      toast.error({
        content: message,
        duration: 2,
      });
    },
  });

  useEffect(() => {
    refetchInvoices();
  }, [refetchInvoices]);

  const styles = useStyles();

  return (
    <div style={{ ...styles.container, ...containerStyles }}>
      <Collapse
        size="small"
        defaultActiveKey={['invoices']}
        style={{ backgroundColor: 'rgba(240, 245, 255, 0.5)' }}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <span style={styles.expandIcon}>
            <RightOutlined rotate={isActive ? 90 : 0} />
          </span>
        )}
        items={[
          {
            key: 'invoices',
            label: <Typography.Link style={styles.label}>Invoices & Estimations</Typography.Link>,
            children: (
              <Spin spinning={isInvoicesLoading}>
                <Flex vertical gap={4} style={styles.containerCard}>
                  {response?.updatedAt === undefined ? null : (
                    <Flex style={styles.topContainer} justify={'space-between'}>
                      <Typography.Text
                        style={styles.topTxt}
                      >{`Last Update ${dayjs(response.updatedAt).from(dayjs())}`}</Typography.Text>
                      <Button
                        size={'small'}
                        loading={isInvoicesLoading}
                        type={'text'}
                        icon={<ReloadOutlined style={styles.refetchIcon} />}
                        onClick={() => refetchInvoices()}
                      />
                    </Flex>
                  )}
                  {response?.invoices?.map(
                    ({ invoiceId, invoiceName, invoiceTypeCode, grandTotal, invoiceTypeName }) => (
                      <a
                        key={invoiceId}
                        href={getInspectionInvoiceRoute(uniqueCode || '', invoiceId, invoiceTypeCode, tenantId, token)}
                      >
                        <Space style={styles.mainContainer}>
                          <Flex gap={8} align={'center'}>
                            <Flex style={styles.iconContainer}>
                              <FileTextOutlined style={styles.invoiceIcon} />
                            </Flex>
                            <Flex vertical style={styles.textContainer}>
                              <Typography.Text style={styles.invoiceNameTxt}>{invoiceName}</Typography.Text>
                              <Typography.Text
                                style={styles.invoiceIdTxt}
                              >{`#${invoiceId} | ${invoiceTypeName ? invoiceTypeName : ''}`}</Typography.Text>
                            </Flex>
                          </Flex>
                          <Flex>
                            <Typography>{formatCurrency(grandTotal ? grandTotal : 0, tenantId)}</Typography>
                          </Flex>
                        </Space>
                      </a>
                    )
                  )}
                </Flex>
                <AddNewInvoice
                  onCreate={(invoiceName, invoiceTypeCode, invoiceNumber) =>
                    createInvoice({
                      invoiceTypeCode: invoiceTypeCode,
                      invoiceName: invoiceName,
                      invoiceNumber: invoiceNumber,
                      inspectionId: uniqueCode as string,
                      referenceNumber: referenceNumber as string,
                      vehicleNumber: vehicleNumber as string,
                    })
                  }
                />
              </Spin>
            ),
          },
        ]}
      />
    </div>
  );
}

const AddNewInvoice = ({
  onCreate,
}: {
  onCreate: (invoiceName: string, invoiceTypeCode: string, invoiceNumber: string) => void;
}) => {
  const [form] = Form.useForm();

  const [showCreateInvoicePopover, setShowCreateInvoicePopover] = useState(false);
  const onFinish = (values: TCreateInvoiceForm) => {
    onCreate(values.invoiceName, values.invoiceTypeCode, values.invoiceNumber);
    form.resetFields();
    setShowCreateInvoicePopover(false);
  };

  const { data: invoiceTypes } = useQuery({
    queryKey: ['invoice-types'],
    queryFn: () => getInvoiceTypes(),
  });
  return (
    <>
      <Flex>
        <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => setShowCreateInvoicePopover(true)}
          children={'Add Invoice'}
          block
        />
      </Flex>
      <Popover
        onOpenChange={setShowCreateInvoicePopover}
        open={showCreateInvoicePopover}
        title="Add Invoice"
        trigger={['click']}
        placement="topRight"
        content={
          <Form
            style={{ width: '100%' }}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              invoiceTypeCode: 'INVOICE',
              invoiceNumber: '',
              invoiceName: null,
            }}
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Invoice Type"
                  name="invoiceTypeCode"
                  rules={[{ required: true, message: 'Please select a type!' }]}
                >
                  <Select>
                    {invoiceTypes?.map(({ invoiceTypeCode, invoiceTypeName }) => (
                      <Select.Option key={invoiceTypeCode} value={invoiceTypeCode}>
                        {invoiceTypeName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Invoice no"
                  name="invoiceNumber"
                  // rules={[{ required: true, message: 'Please enter the invoice number!' }]}
                >
                  <Input placeholder="Invoice No" prefix={'#'} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Invoice Name"
              name="invoiceName"
              rules={[{ required: true, message: 'Please enter the invoice name!' }]}
            >
              <Input placeholder="Invoice Name" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Create an invoice
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </>
  );
};

export default Invoices;

const useStyles = () => ({
  container: {} as CSSProperties,
  label: { fontWeight: 600, color: '#0035cc' } as CSSProperties,
  expandIcon: {
    color: '#0035cc',
    fontSize: '10px',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    display: 'flex',
    borderRadius: '100%',
    backgroundColor: '#fff',
    justifyContent: 'center',
    border: '1px solid rgba(22, 119, 255, 0.15)',
  } as CSSProperties,
  collapse: {
    width: '100',
    backgroundColor: 'rgba(240, 245, 255, 0.5)',
  } as CSSProperties,
  button: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as CSSProperties,
  topContainer: {
    alignItems: 'center',
  } as CSSProperties,
  topTxt: {
    fontSize: 11,
    color: '#aaabb1',
  } as CSSProperties,
  refetchIcon: {
    fontSize: '12px',
  } as CSSProperties,
  containerCard: {
    marginBottom: '16px',
  } as CSSProperties,
  mainContainer: {
    border: '1px solid #d3d3d3',
    width: '100%',
    padding: '4px 8px',
    borderRadius: '8px',
    justifyContent: 'space-between',
  } as CSSProperties,
  iconContainer: {
    width: '30px',
    height: '30px',
    backgroundColor: '#e8f1ff',
    borderRadius: '8px',
    padding: '8px',
  } as CSSProperties,
  invoiceIcon: {
    fontSize: '16px',
  } as CSSProperties,
  textContainer: {} as CSSProperties,
  invoiceNameTxt: {
    lineHeight: 1.1,
    fontWeight: 600,
  } as CSSProperties,
  invoiceIdTxt: {
    fontSize: '12px',
    lineHeight: 1.1,
    color: '#aaabb1',
  } as CSSProperties,
  formInput: { flexGrow: 1, marginBottom: 0 } as CSSProperties,
});
