import MarkAsInvalidButton from '@inspection/components/ActionBar/MarkAsInvalid/MarkAsInvalidButton';
import ResolveAsValidButton from '@inspection/components/ActionBar/MarkAsInvalid/ResolveAsValidButton';

type Props = {
  isInspectionInvalid: boolean;
  uniqueCode: string;
  token: string;
  tenantId: string;
  refetch: () => void;
  isAuthorizedUser: boolean;
  isInspectionBlocked: boolean;
};

const InvalidStatus = ({
  isInspectionInvalid,
  uniqueCode,
  token,
  tenantId,
  refetch,
  isAuthorizedUser,
  isInspectionBlocked,
}: Props) => {
  return isInspectionInvalid ? (
    <ResolveAsValidButton
      isAuthorizedUser={isAuthorizedUser}
      uniqueCode={uniqueCode}
      token={token}
      tenantId={tenantId}
      refetch={refetch}
    />
  ) : (
    <MarkAsInvalidButton isAuthorizedUser={isAuthorizedUser} isInspectionBlocked={isInspectionBlocked} />
  );
};
export default InvalidStatus;
