import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, Flex, Typography } from 'antd';
import { CSSProperties } from 'react';

type Props = {
  type: string;
  time: string;
  reason: string;
};

const BlockedOrInvalidStatusMessage = ({ time, reason, type }: Props) => {
  const styles = useStyles();
  const { Text, Title } = Typography;
  return (
    <Card style={styles.card}>
      <Flex align="start">
        <InfoCircleOutlined style={styles.icon} />
        <Col>
          <Title
            color="red"
            level={5}
            style={styles.message}
          >{`Inspection marked as ${type} on ${time} by the operator`}</Title>
          <Text>{reason}</Text>
        </Col>
      </Flex>
    </Card>
  );
};

export default BlockedOrInvalidStatusMessage;

const useStyles = () => {
  return {
    card: {
      marginBottom: '16px',
      backgroundColor: '#fff1f0',
      borderColor: 'red',
      height: '120px',
      borderWidth: '1px',
    } as CSSProperties,
    icon: {
      color: 'red',
      fontSize: '24px',
      marginRight: '8px',
    } as CSSProperties,
    message: {
      margin: 0,
      color: 'red',
    } as CSSProperties,
  };
};
