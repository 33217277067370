import env from '@/config/env';
import { TLineItem } from '@invoices/util/types';

export const getCurrency = (tenantId: string): string => {
  return env.VITE_CURRENCY_SETTINGS[tenantId] || '';
};

export const formatCurrency = (
  value: number,
  tenantId: string,
  hideSymbol: boolean = false,
  currency?: string
): string => {
  currency = currency || getCurrency(tenantId);
  if (!currency) return value.toString();
  const formattedValue = new Intl.NumberFormat('us-US', { style: 'currency', currency }).format(value);
  return hideSymbol ? formattedValue.replace(currency, '') : formattedValue;
};

export const calculateTotals = (items: TLineItem[]) => {
  return items.reduce(
    (acc, item) => {
      if (item.amountAdjustment) {
        acc.totalAmount += item.amountAdjustment;
        acc.totalDiscount += item.discount ? item.amountAdjustment * (item.discount / 100) : 0;
        return acc;
      } else {
        acc.totalAmount += item.unitPrice * item.quantity;
        acc.totalDiscount += item.discount ? item.unitPrice * item.quantity * (item.discount / 100) : 0;
        return acc;
      }
    },
    { totalAmount: 0, totalDiscount: 0 }
  );
};
