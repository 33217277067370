import { Skeleton, Table } from 'antd';

const TableSkeleton = () => {
  const columns = [
    { render: () => <Skeleton.Input style={{ width: '100%' }} /> },
    { render: () => <Skeleton.Input style={{ width: '100%' }} /> },
    { render: () => <Skeleton.Input style={{ width: '100%' }} /> },
    { render: () => <Skeleton.Input style={{ width: '100%' }} /> },
  ];

  const data = Array.from({ length: 5 }, (_, index) => ({ key: index }));

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={true}
      bordered
      style={{ marginTop: '20px' }}
    />
  );
};

export default TableSkeleton;
