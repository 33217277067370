import { CSSProperties, FC, ReactNode } from 'react';

type GridProps = {
  columns: number;
  children: ReactNode;
  style?: Omit<CSSProperties, 'display' | 'gridTemplateColumns' | 'gap'>;
};

const Grid: FC<GridProps> = ({ children, columns, style }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Grid;
