import MapComponent from '@/components/MapComponent.tsx';
import { TLocation } from '@/types';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Flex, Popover, Row } from 'antd';
import Text from 'antd/es/typography/Text';
import { CSSProperties } from 'react';

type Props = {
  location: TLocation;
};

const LocationPopover = ({ location }: Props) => {
  const styles = useStyles();

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      content={
        location ? (
          <Flex style={styles.popoverContentStyle}>
            <MapComponent location={location} />
          </Flex>
        ) : (
          <Text style={styles.titleTextStyle}>No Location found</Text>
        )
      }
      title={'Accident Location'}
    >
      <Row>
        <Button type="text" style={styles.locationIconStyle(!!location)} icon={<EnvironmentOutlined />}>
          Location
        </Button>
      </Row>
    </Popover>
  );
};

const useStyles = () => {
  return {
    titleTextStyle: {
      fontSize: 12,
      color: 'black',
      opacity: 0.4,
    } as CSSProperties,
    popoverContentStyle: {
      height: '300px',
      width: '300px',
      padding: '0',
    } as CSSProperties,
    locationIconStyle: (isLocation: boolean) =>
      ({
        fontSize: '12px',
        gap: 8,
        color: isLocation ? '#0035CC' : '#00000073',
        backgroundColor: 'transparent',
        paddingLeft: '0px',
      }) as CSSProperties,
  };
};

export default LocationPopover;
