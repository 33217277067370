import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type TAuthState = {
  token: string;
  tenantId: string;
};

export const AuthContext = createContext<{
  authState: TAuthState;
  setAuthState: Dispatch<SetStateAction<TAuthState>>;
}>({
  authState: { tenantId: '', token: '' },
  setAuthState: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}
