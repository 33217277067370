interface KeyValuePair {
  name: string;
  value: string | number;
}

interface IncidentDetailsProps {
  section: string;
  data: KeyValuePair[];
}

export const EMPTY_INCIDENT_DETAILS: IncidentDetailsProps[] = [
  {
    section: 'accidentStatement',
    data: [
      { name: 'dateOfFirstSubmission', value: '' },
      { name: 'reportedBy', value: '' },
      { name: 'dateOfAccident', value: '' },
      { name: 'exactLocationOfTheAccident', value: '' },
      { name: 'additionalLocationInformation', value: '' },
      { name: 'country/stateOfLoss', value: '' },
    ],
  },
  {
    section: 'vehicleDetails',
    data: [
      { name: 'vehicleNumber', value: '' },
      { name: 'manufacturer', value: '' },
      { name: 'model', value: '' },
      { name: 'variant', value: '' },
      { name: 'purposeOfTheVehicle', value: '' },
      { name: 'areYouClaimingUnderYourInsurancePolicy?', value: '' },
      { name: 'vehicleCategory', value: '' },
      { name: 'transmission', value: '' },
      { name: 'cc', value: '' },
    ],
  },
  {
    section: 'customerDetails',
    data: [
      { name: 'company?', value: '' },
      { name: 'nameOfTheRegisteredOwner', value: '' },
      { name: 'companyRegNo', value: '' },
      { name: 'mobileNumber', value: '' },
      { name: 'alternativePhoneNo', value: '' },
    ],
  },
  {
    section: 'insuranceCompanyDetails',
    data: [
      { name: 'nameOfTheInsuranceCompany', value: '' },
      { name: 'policyNumber/CoverNoteNumber', value: '' },
    ],
  },
  {
    section: 'driverDetails',
    data: [
      { name: 'nameOfDriver', value: '' },
      { name: 'nricNo', value: '' },
      { name: 'dateOfBirth', value: '' },
      { name: 'occupation', value: '' },
    ],
  },
  {
    section: 'describeCircumstancesOfTheAccident',
    data: [{ name: 'describeCircumstancesOfTheAccident', value: '' }],
  },
];
