import { AuthContext, TAuthState } from '@/config/AuthContext/useAuth.ts';
import { SEARCH_PARAMS } from '@inspection/constants';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { authStateSingleton } from './auth-service.util';
import { UnauthorizedErrorRedirect } from '../http/unauthorized-error.util';

function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useState<TAuthState>({
    token: '',
    tenantId: '',
  });
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get(SEARCH_PARAMS.TENANT_ID) && searchParams.get(SEARCH_PARAMS.TOKEN)) {
      const state = {
        tenantId: searchParams.get(SEARCH_PARAMS.TENANT_ID) as string,
        token: searchParams.get(SEARCH_PARAMS.TOKEN) as string,
      };
      setAuthState(state);
      authStateSingleton.authState = state;
    } else UnauthorizedErrorRedirect();
  }, [searchParams, setAuthState]);

  return <AuthContext.Provider value={{ authState, setAuthState }}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
