export const InspectionStatus = {
  BLOCKED: 'Blocked',
  CDP: 'Customer Decision Pending',
  COMPLETED: 'Completed',
};

export const INSPECTION_FILTER_STATUSES = [
  { value: 'created', label: 'Created' },
  { value: 'opened', label: 'Opened' },
  { value: 'accepted', label: 'Assessor Accepted' },
  { value: 'rejected', label: 'Assessor Rejected' },
  { value: 'assessorSubmitted', label: 'Assessor Submitted' },
  { value: 'submitted', label: 'Completed' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'customerDecisionPending', label: 'Customer Decision Pending' },
];
