import { InputNumber, InputNumberProps, Select } from 'antd';
import { Option } from 'antd/es/mentions';

import { CSSProperties } from 'react';

type CurrencyInputProps = InputNumberProps & {
  onCurrencyChange?: (currency: string) => void;
  width?: string;
  defaultCurrency?: string;
  currencyOptions?: { value: string; label: string }[];
};

const CurrencySelectInput = ({
  onCurrencyChange = (currency) => {
    return currency;
  },
  width = 'auto',
  defaultCurrency = 'USD',
  currencyOptions = [
    { value: 'USD', label: '$' },
    { value: 'EUR', label: '€' },
    { value: 'GBP', label: '£' },
    { value: 'AUD', label: 'A$' },
    { value: 'INR', label: '₹' },
    { value: 'SGD', label: 'S$' },
    { value: 'LKR', label: 'Rs' },
    { value: 'KHR', label: '៛' },
  ],
  ...props
}: CurrencyInputProps) => {
  const styles = useStyles(width);

  const selectBefore = (
    <Select defaultValue={defaultCurrency} onChange={onCurrencyChange} disabled={props.disabled}>
      {currencyOptions.map((option) => (
        <Option value={option.value}>{option.label}</Option>
      ))}
    </Select>
  );
  return (
    <InputNumber
      {...props}
      addonBefore={selectBefore}
      style={styles.currency}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
      onKeyDown={(event) => {
        const target = event.target as HTMLInputElement;
        const { key } = event;
        const value = target.value;

        if (
          key === 'Backspace' ||
          key === 'Delete' ||
          key === 'Tab' ||
          key === 'Escape' ||
          key === 'Enter' ||
          key === 'ArrowLeft' ||
          key === 'ArrowRight'
        ) {
          return;
        }

        if (key === '.' && value.includes('.')) {
          event.preventDefault();
          return;
        }

        if (key === '-' && target.selectionStart !== 0) {
          event.preventDefault();
          return;
        }

        const regex = /^[0-9.-]*$/;
        if (!regex.test(key)) {
          event.preventDefault();
        }
      }}
      precision={2}
    />
  );
};

export default CurrencySelectInput;

const useStyles = (width: string) => {
  return {
    currency: {
      width: width,
    } as CSSProperties,
  };
};
