import ContentOverlay from '@/components/ContentOverlay';
import { useToastApi } from '@/hooks/useToastApi';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Empty, Flex, GetProp, Image, Tooltip, Typography, UploadFile, UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { CSSProperties, useState } from 'react';
import { uploadImages } from '../../services/inspections.service';

type Prop = {
  uniqueCode: string;
  refetch: () => void;
};

const UploadMediaButton = ({ uniqueCode, refetch }: Prop) => {
  const [showMediaUploadOverlay, setShowMediaUploadOverlay] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

  const styles = useStyles();

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const newFiles = newFileList.map((file) => file.originFileObj as File);
    setFiles(newFiles);
  };

  const toastApi = useToastApi();

  const mutation = useMutation({
    mutationFn: () => uploadImages({ uniqueCode, files }),
    onSuccess: () => {
      showMessage('Media uploaded successfully.', 'success');
      setIsLoading(false);
      setShowMediaUploadOverlay(false);
      setFileList([]);
      setFiles([]);
      refetch();
    },
    onError: () => {
      showMessage('Media upload Failed', 'error');
      setIsLoading(false);
    },
  });

  const showMessage = (
    content: string = 'This feature will be available soon!',
    type: 'warning' | 'error' | 'info' | 'loading' | 'success' = 'warning'
  ) => {
    toastApi.open({
      type: type,
      content: content,
      duration: 2,
    });
  };

  return (
    <>
      <ContentOverlay
        title="Upload Media"
        open={showMediaUploadOverlay}
        onCancel={() => setShowMediaUploadOverlay(false)}
      >
        <Flex vertical>
          <Dragger
            multiple
            listType="picture-card"
            style={styles.dragger}
            name="file"
            onPreview={onPreview}
            maxCount={30}
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={() => false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <Typography.Link>
              Support for a single or bulk upload. Maximum file count of 30 allowed at a time.
            </Typography.Link>
          </Dragger>
          {fileList.length === 0 ? (
            <Empty style={styles.empty} description="No media" imageStyle={styles.emptyImage} />
          ) : (
            ''
          )}
          <Button
            onClick={() => {
              setIsLoading(true);
              mutation.mutate();
            }}
            type="primary"
            style={styles.uploadButton}
            loading={mutation.isPending || isLoading}
            disabled={fileList.length === 0}
          >
            Upload
          </Button>
          <Typography.Text type="danger" style={styles.warningText}>
            Please wait until all the images have finished loading before clicking upload.
          </Typography.Text>
          {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </Flex>
      </ContentOverlay>
      <Tooltip placement="bottom" title="Upload photos and videos related to this inspection" mouseEnterDelay={1}>
        <Button
          icon={<UploadOutlined />}
          type="default"
          onClick={() => {
            setShowMediaUploadOverlay(!showMediaUploadOverlay);
          }}
        >
          Upload Media
        </Button>
      </Tooltip>
    </>
  );
};

export default UploadMediaButton;

const useStyles = () => {
  return {
    dragger: {
      marginBottom: 10,
    } as CSSProperties,
    empty: {
      border: '1px solid #211e1e',
      padding: 10,
      margin: 0,
      width: '100%',
      borderRadius: 8,
      height: 102,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    } as CSSProperties,
    emptyImage: {
      height: 40,
      width: 40,
    } as CSSProperties,
    uploadButton: {
      marginTop: 20,
    } as CSSProperties,
    warningText: {
      paddingTop: 10,
      textAlign: 'center',
    } as CSSProperties,
  };
};
