import { useAuth } from '@/config/AuthContext/useAuth.ts';
import CommentsContainer from '@/features/inspection/components/Gallery/CommentsContainer';
import { TComments } from '@/features/inspectionList/types';
import { useToastApi } from '@/hooks/useToastApi.tsx';
import { ExclamationCircleFilled, MessageOutlined, RedoOutlined, SendOutlined } from '@ant-design/icons';
import { addComment } from '@inspection/services/inspections.service.ts';
import { TAddComment, TCommentForm } from '@inspection/types.ts';
import { getCurrentUser } from '@inspection/utils/auth.util.ts';
import { useMutation } from '@tanstack/react-query';
import { Badge, Button, Checkbox, Flex, Form, Input, Popover } from 'antd';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import isEmpty from 'lodash/isEmpty';
import { CSSProperties, useState } from 'react';
dayjs.extend(RelativeTime);

type Props = {
  documentId: string;
  commentList: TComments;
  uniqueCode: string;
  onAddComment: (data: { name: string; comment: string; commentedAt: string; isPriorityComment: boolean }) => void;
  onRemoveComment: () => void;
};

const CommentPopover = ({ documentId, uniqueCode, commentList, onAddComment, onRemoveComment }: Props) => {
  const styles = useStyles();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [isCommentFailed, setIsCommentFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const toastApi = useToastApi();
  const { authState } = useAuth();

  const mutation = useMutation({
    mutationFn: ({ uniqueCode, docId, comment, isPriorityComment }: TAddComment) =>
      addComment({ uniqueCode, docId, comment, docComment: true, isPriorityComment }),
    onSuccess: (data) => {
      form.resetFields();
      setIsCommentFailed(false);
      setErrorMessage('');
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? 'Comment added successfully!' : data.message,
        duration: 2,
      });
    },
    onError: (data) => {
      setIsCommentFailed(true);
      setErrorMessage('Failed to add comment, please try again!');
      toastApi.open({
        type: 'error',
        content: data?.message || 'Failed to add comment!',
        duration: 2,
      });
      onRemoveComment();
    },
  });

  const onFinish = (values: TCommentForm) => {
    if (isEmpty(values.comment)) {
      setErrorMessage('Please enter a comment');
      return;
    }
    mutation.mutate({
      uniqueCode,
      docId: documentId,
      comment: values.comment,
      docComment: true,
      isPriorityComment: !!values.isPriorityComment,
    });
    onAddComment({
      name: getCurrentUser(authState.token).name,
      comment: values.comment,
      commentedAt: dayjs().toISOString(),
      isPriorityComment: values.isPriorityComment || false,
    });
  };

  return (
    <Popover
      trigger={['click']}
      placement="bottomLeft"
      content={
        <Flex vertical style={styles.commentsContainer}>
          <CommentsContainer commentList={commentList} />
          <Form form={form} layout="vertical" onFinish={(values) => onFinish(values)} style={styles.fullWidth}>
            <Form.Item name="isPriorityComment" style={styles.inputContainer}>
              <Checkbox
                children="Show this comment on top of the image"
                onChange={(e) => form.setFieldsValue({ isPriorityComment: e.target.checked })}
              />
            </Form.Item>
            <Flex>
              <Form.Item
                name={'comment'}
                style={styles.fullWidth}
                validateStatus={errorMessage ? 'error' : ''}
                help={errorMessage || undefined}
              >
                <TextArea
                  rows={1}
                  placeholder="Add a comment"
                  onChange={(value) => {
                    if (isEmpty(value)) {
                      setErrorMessage('');
                      setIsCommentFailed(false);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item style={styles.sendButtonStyle}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={mutation.isPending}
                  icon={isCommentFailed ? <RedoOutlined /> : <SendOutlined />}
                />
              </Form.Item>
            </Flex>
          </Form>
        </Flex>
      }
      title={'Comments'}
    >
      <Flex>
        <Button
          type="text"
          style={styles.commentBtn(commentList.length > 0)}
          icon={
            <Badge
              count={isCommentFailed ? <ExclamationCircleFilled style={styles.badgeColor} /> : commentList.length}
              size="small"
              styles={{ indicator: { fontSize: '8px' } }}
            >
              <MessageOutlined style={styles.commentBtn(commentList.length > 0)} />
            </Badge>
          }
        >
          Comments
        </Button>
      </Flex>
    </Popover>
  );
};

const useStyles = () => {
  return {
    commentsContainer: {
      width: '350px',
      overflowX: 'hidden',
      borderRadius: '4px',
    } as CSSProperties,
    sendButtonStyle: {
      marginLeft: '4px',
    } as CSSProperties,
    inputContainer: {
      marginBottom: '0px',
      marginTop: '20px',
    } as CSSProperties,
    fullWidth: {
      width: '100%',
    } as CSSProperties,
    badgeColor: {
      color: '#f5222d',
    } as CSSProperties,
    commentBtn: (comments: boolean) =>
      ({
        fontSize: '12px',
        color: comments ? '#0035CC' : '#00000073',
        backgroundColor: 'transparent',
        paddingLeft: '0px',
      }) as CSSProperties,
  };
};

export default CommentPopover;
