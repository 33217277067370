import { Col, Flex, Row, Skeleton } from 'antd';

const InvoiceHeaderSkeleton = () => {
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Skeleton.Image />
        </Col>
        <Col>
          <Flex vertical gap={8} align="flex-end">
            <Skeleton.Input style={{ width: 200, borderRadius: 100 }} />
            <Skeleton.Input style={{ width: 300, borderRadius: 100 }} size="small" />
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Skeleton paragraph={{ rows: 1 }} round />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Flex vertical gap={8}>
            <Skeleton.Input style={{ width: 200, borderRadius: 100 }} size="small" />
            <Skeleton.Input style={{ width: 300, borderRadius: 100 }} size="small" />
            <Skeleton.Input style={{ width: 300, borderRadius: 100, height: 40 }} />
          </Flex>
        </Col>
        <Col>
          <Flex vertical gap={8} align="flex-end">
            <Skeleton.Input style={{ width: 200, borderRadius: 100 }} size="small" />
            <Skeleton.Input style={{ width: 300, borderRadius: 100 }} size="small" />
            <Skeleton.Input style={{ width: 300, borderRadius: 100, height: 40 }} />
          </Flex>
        </Col>
      </Row>
    </>
  );
};
export default InvoiceHeaderSkeleton;
