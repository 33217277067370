import env from '@/config/env.ts';
import axios from 'axios';
import { authStateSingleton } from '../AuthContext/auth-service.util';
import { UnauthorizedErrorRedirect } from './unauthorized-error.util';

export const DigiEyeCore = axios.create({
  baseURL: env.VITE_CORE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

DigiEyeCore.interceptors.request.use((config) => {
  if (authStateSingleton.token === '') UnauthorizedErrorRedirect();
  config.headers['v1-auth'] = `Bearer ${authStateSingleton.token}`;
  return config;
});

DigiEyeCore.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) UnauthorizedErrorRedirect();
    else return Promise.reject(error);
  }
);
