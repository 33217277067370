import InspectionCollapsibleBody from '@inspection/components/InspectionDetails/InspectionCollapsibleBody';
import { InspectionDetailsList, KeyValuePairList } from '@inspection/types';
import { CollapseProps, Typography } from 'antd';
import { CSSProperties } from 'react';

export const getCollapsibleData = (data: InspectionDetailsList, styles: CSSProperties) => {
  const items: CollapseProps['items'] = [];
  const defaultActiveKeys: string[] = [];

  for (const [key, value] of Object.entries(data)) {
    defaultActiveKeys.push(key);
    items.push({
      key: key,
      label: <Typography.Link style={styles}>{value.title}</Typography.Link>,
      children: <InspectionCollapsibleBody inspectionData={value.data} />,
    });
  }

  return { items, defaultActiveKeys };
};

export const getNumericValueByTitle = (
  title: string,
  inspectionDetails: KeyValuePairList,
  currency?: boolean
): number => {
  const keyValue = inspectionDetails.find((obj) => obj.title === title);
  const description = keyValue ? keyValue.description : '0';
  if (currency) {
    const match = description.replace(/,/g, '').match(/\d+(\.\d+)?/);
    return match ? parseFloat(match[0]) : 0;
  }
  return parseFloat(description);
};

export const getStringValueByTitle = (title: string, inspectionDetails: KeyValuePairList): string => {
  const keyValue = inspectionDetails.find((obj) => obj.title === title);
  return keyValue ? keyValue?.description : '';
};
