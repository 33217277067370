import { Flex, Skeleton } from "antd";

type Props = {
  columns?: number;
};

const GalleryLoading = ({ columns = 4 }: Props) => {
  return (
    <Flex gap={"0.5rem"}>
      {new Array(columns).fill(0).map((_, index) => (
        <Skeleton.Image key={index} active />
      ))}
    </Flex>
  );
};

export default GalleryLoading;
