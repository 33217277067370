import { Modal, ModalProps } from 'antd';
import { CSSProperties } from 'react';

const ContentOverlay = ({
  children,
  width = '80vw',
  destroyOnClose = true,
  maskClosable = false,
  centered = true,
  footer = null,
  ...props
}: Omit<ModalProps, 'styles' | 'zIndex'>) => {
  const styles = useStyles();

  return (
    <Modal
      width={width}
      centered={centered}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      footer={footer}
      zIndex={2001}
      styles={styles.modalContent}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default ContentOverlay;

const useStyles = () => {
  return {
    modalContent: {
      body: { maxHeight: '75vh', overflow: 'auto' } as CSSProperties,
    },
  };
};
