import { InspectionData } from '@/features/inspection/types';
import { getStringValueByTitle } from '@/features/inspection/utils/inspectionDetails.util';
import { InspectionStatus } from '../constants';

export const getStatusColor = (value: string) => {
  if (value === InspectionStatus.BLOCKED) {
    return 'error';
  }
  if (value === InspectionStatus.CDP) {
    return 'warning';
  }
  if (value === InspectionStatus.COMPLETED) {
    return 'success';
  }
  return 'default';
};

export const getStatusTagColorAndText = (inspection: InspectionData) => {
  return {
    color: getStatusColor(
      inspection?.submitted
        ? InspectionStatus.COMPLETED
        : inspection?.blocked
          ? InspectionStatus.BLOCKED
          : getStringValueByTitle('Inspection Status', inspection?.inspectionDetails?.inspectionDetails?.data)
    ),
    tag: inspection?.submitted
      ? 'Completed'
      : inspection?.blocked
        ? 'Blocked'
        : getStringValueByTitle('Inspection Status', inspection?.inspectionDetails?.inspectionDetails?.data),
  };
};
