import { useAuth } from '@/config/AuthContext/useAuth';
import useCopyLink from '@/hooks/useCopyLink';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';

type Props = {
  portalLinks: {
    generalInspectionPortal?: URL | undefined;
    motorInspectionPortal?: URL | undefined;
    mobileAppPortal?: URL | undefined;
  };
};

const CopyPortalLinkButton = ({ portalLinks }: Props) => {
  const tenantId = useAuth()?.authState?.tenantId;

  const { copyLink } = useCopyLink();

  const dropdownOptions: MenuProps['items'] = [
    {
      label: 'Motor inspection web portal',
      key: '1',
      icon: <LinkOutlined />,
      disabled: portalLinks.motorInspectionPortal === undefined,
      onClick: () => {
        if (portalLinks.motorInspectionPortal)
          copyLink(portalLinks.motorInspectionPortal, 'Motor inspection web portal');
      },
    },
    {
      label: 'Mobile app portal',
      key: '2',
      icon: <LinkOutlined />,
      disabled: portalLinks.mobileAppPortal === undefined,
      onClick: () => {
        if (portalLinks.mobileAppPortal) copyLink(portalLinks.mobileAppPortal, 'Mobile app portal');
      },
    },
    {
      label: 'General inspection web portal',
      key: '3',
      icon: <LinkOutlined />,
      disabled: portalLinks.generalInspectionPortal === undefined,
      onClick: () => {
        if (portalLinks.generalInspectionPortal)
          copyLink(portalLinks.generalInspectionPortal, 'General inspection web portal');
      },
    },
  ];

  const getButton = () => {
    if (tenantId === 'hnb')
      return (
        <Dropdown.Button
          placement="bottomLeft"
          menu={{ items: dropdownOptions }}
          onClick={() => {
            if (portalLinks.mobileAppPortal) copyLink(portalLinks.mobileAppPortal, 'Mobile app portal');
            else if (portalLinks.motorInspectionPortal)
              copyLink(portalLinks.motorInspectionPortal, 'Motor inspection web portal');
          }}
        >
          <CopyOutlined />
          Copy Link
        </Dropdown.Button>
      );

    if (tenantId === 'ajax')
      return (
        <Button
          icon={<CopyOutlined />}
          onClick={() => {
            if (portalLinks.generalInspectionPortal) copyLink(portalLinks.mobileAppPortal!, 'Mobile app portal');
          }}
        >
          Copy Link
        </Button>
      );

    return (
      <Button
        icon={<CopyOutlined />}
        onClick={() => {
          if (portalLinks.generalInspectionPortal) copyLink(portalLinks.generalInspectionPortal, 'Mobile app portal');
        }}
      >
        Copy Link
      </Button>
    );
  };

  return <Space wrap>{getButton()}</Space>;
};

export default CopyPortalLinkButton;
