import { Flex } from 'antd';
import Text from 'antd/lib/typography/Text';
import dayjs from 'dayjs';

type InvoiceFooterProps = {
  dueDate?: string;
  terms?: string;
  note?: string;
};
const InvoiceFooter = ({ dueDate, terms, note }: InvoiceFooterProps) => {
  return (
    <Flex vertical style={{ marginTop: '32px' }} gap={8}>
      {terms && (
        <Flex vertical>
          <Text children={'Terms :'} strong />
          <Text style={{ whiteSpace: 'pre-line' }}>{terms}</Text>
        </Flex>
      )}
      {dueDate && (
        <Flex gap={8}>
          <Text children={'Due Date :'} strong />
          <Text children={dueDate ? dayjs(dueDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')} />
        </Flex>
      )}
      {note && (
        <Flex vertical>
          <Text children={'Customer Note :'} strong />
          <Text style={{ whiteSpace: 'pre-line' }}>{note}</Text>
        </Flex>
      )}
    </Flex>
  );
};
export default InvoiceFooter;
