import MapComponent from '@/components/MapComponent.tsx';
import { CopyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Tooltip, Typography } from 'antd';
import { CSSProperties } from 'react';
import { useToastApi } from "@/hooks/useToastApi.tsx";
import { useCopyLocation } from "@/features/inspection/utils/location.util.ts";
import { TLocation } from "@/types";

type Props = {
  location: TLocation | null;
  zoomLevel?: number;
};

const AccidentLocation = ({ location, zoomLevel }: Props) => {
  const styles: Styles = useStyles();
  const toastApi = useToastApi();
  const { copyLocationToClipboard } = useCopyLocation(toastApi);

  return (
    <div style={styles.mainContainer}>
      <Card
        title={
          <Flex style={styles.titleContainer} gap={8}>
            <Typography.Link style={styles.cardTitle}>Accident Location</Typography.Link>
            {location && (
              <Tooltip placement={'bottom'} title={'Copy shareable link'} mouseEnterDelay={1} >
                <Button shape="default" style={styles.copyButton} onClick={() => copyLocationToClipboard(location)}>
                  <CopyOutlined />
                </Button>
              </Tooltip>
            )}
          </Flex>
        }
        style={styles.cardContainer}
        styles={styles.cardContentStyles}
      >
        {location ? (
          <MapComponent location={location} zoomLevel={zoomLevel} />
        ) : (
          <Flex style={styles.emptyLocationContainer} justify="center" align="center" vertical gap={8}>
            <EnvironmentOutlined style={styles.locationIcon} />
            <Typography.Paragraph style={styles.locationTitle}>Location is pending</Typography.Paragraph>
          </Flex>
        )}
      </Card>
    </div>
  );
};

export default AccidentLocation;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    mainContainer: {
      marginTop: '16px',
    } as CSSProperties,
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    } as CSSProperties,
    cardTitle: {
      margin: 0,
      color: '#0035cc',
    } as CSSProperties,
    copyButton: {
      padding: '0',
      width: '30px',
      height: '30px',
    } as CSSProperties,
    cardContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      overflow: 'hidden',
      border: '1px solid #d9d9d9',
    } as CSSProperties,
    cardContentStyles: {
      body: {
        height: 300,
        width: '100%',
        padding: 8,
      } as CSSProperties,
      header: {
        padding: '12px 16px',
      } as CSSProperties,
    },
    emptyLocationContainer: {
      height: '300px',
      opacity: 0.5,
    } as CSSProperties,
    locationIcon: {
      fontSize: '3rem',
    } as CSSProperties,
    locationTitle: {
      textAlign: 'center',
    } as CSSProperties,
  };
};
