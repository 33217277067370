import { queryClient } from '@/config/http/react-query.config.ts';
import { useToastApi } from '@/hooks/useToastApi.tsx';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import Grid from '@components/Grid.tsx';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { SortableMediaCard } from '@inspection/components/SortableGallery/SortableMediaCard.tsx';
import { useInspectionQuery } from '@inspection/hooks/useInspectionQuery.ts';
import { SaveSortedMediaPayload, saveSortedMedia } from '@inspection/services/inspections.service.ts';
import { MediaData } from '@inspection/types.ts';
import { useMutation } from '@tanstack/react-query';
import { Button, Flex, Space, Spin, Typography } from 'antd';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  uniqueCode: string;
  onSuccess?: () => void;
};

function SortableGallery({ uniqueCode, onSuccess }: Props) {
  const toastApi = useToastApi();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [activeId, setActiveId] = useState<string | null>(null);
  const { data: inspection, isLoading } = useInspectionQuery({ uniqueCode });
  const { mutate: mutateMediaOrder, isPending: usMutatingMediaOrder } = useMutation({
    mutationFn: (payload: SaveSortedMediaPayload) => saveSortedMedia(payload),
    onSuccess: async (res) => {
      if (onSuccess) onSuccess();
      await queryClient.invalidateQueries({ queryKey: ['inspection-data', uniqueCode] });
      toastApi.open({ type: res.success ? 'success' : 'error', content: res.message });
    },
    onError: (error) => toastApi.error(error.message),
  });

  const mediaItems = useMemo(
    () => (inspection?.media || []).map((item) => ({ ...item, id: item.docId })),
    [inspection]
  );

  const [items, setItems] = useState<(MediaData & { id: string })[]>([]);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) setItems(mediaItems);
  }, [mediaItems]);

  const onDragStart = useCallback((event: DragStartEvent) => {
    setActiveId((event.active.id as string) || null);
  }, []);

  const onDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === (active.id as string));
        const newIndex = items.findIndex((item) => item.id === (over!.id as string));

        if (!updated) setUpdated(true);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  const onDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const onSave = () => {
    if (!updated) {
      toastApi.warning('Images are not sorted');
      return;
    }

    mutateMediaOrder({
      uniqueCode,
      media: items.map((item, index) => ({ docId: item.docId, order: index + 1 })),
    });
  };

  const changeOrder = (order: number, currentIndex: number) => {
    setItems((items) => {
      if (!updated) setUpdated(true);
      return arrayMove(items, currentIndex, order);
    });
  };

  if (isLoading) return <Spin spinning />;

  return (
    <Space direction={'vertical'}>
      <Flex align={'center'} justify={'space-between'}>
        <Typography>
          Columns <Button disabled>5</Button>
        </Typography>
        <Button disabled={usMutatingMediaOrder} loading={usMutatingMediaOrder} onClick={onSave}>
          Save
        </Button>
      </Flex>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Grid columns={5}>
            {items.map((item, index) => {
              const actions: ReactNode[] = [
                <Button
                  type={'text'}
                  icon={<UpCircleOutlined />}
                  onClick={() => changeOrder(0, index)}
                  style={{ width: '100%' }}
                >
                  Send to top
                </Button>,
                <Button
                  type={'text'}
                  icon={<DownCircleOutlined />}
                  onClick={() => changeOrder(items.length - 1, index)}
                  style={{ width: '100%' }}
                >
                  Send to bottom
                </Button>,
              ];
              if (index === 0) {
                actions.shift();
              }
              if (index === items.length - 1) {
                actions.pop();
              }
              return <SortableMediaCard key={item.id} id={item.id} src={item.thumbnail} actions={actions} />;
            })}
          </Grid>
        </SortableContext>
        <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
          {activeId ? <SortableMediaCard id={activeId} src={items.find((i) => i.id === activeId)!.thumbnail} /> : null}
        </DragOverlay>
      </DndContext>
    </Space>
  );
}

export default SortableGallery;
