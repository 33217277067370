import AuthProvider from '@/config/AuthContext/Auth.tsx';
import env from '@/config/env.ts';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import { ToastProvider } from './config/ToastContext/ToastContext.tsx';
import QueryConfigProvider from './config/http/QueryConfigProvider.tsx';
import { theme } from './config/theme.ts';
import './index.css';

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: env.VITE_TRACES_SAMPLE_RATE,
  tracePropagationTargets: [`${env.VITE_API_URL}`, `${env.VITE_CORE_URL}`, `${env.VITE_UNENCRYPTED_API_URL}`],
  profilesSampleRate: env.VITE_PROFILES_SAMPLE_RATE,
  replaysSessionSampleRate: env.VITE_REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE,
  environment: env.VITE_NODE_ENV,
  enabled: env.VITE_ENABLE_SENTRY,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider theme={theme}>
        <AuthProvider>
          <QueryConfigProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </QueryConfigProvider>
        </AuthProvider>
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);
