import { DragOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card, Image } from 'antd';
import { CSSProperties, ReactNode } from 'react';

const styles = {
  draggableIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    height: '2.5rem',
    width: '2.5rem',
    backgroundColor: 'rgba(255,255,255,0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '8px',
    fontSize: '1.5rem',
    color: 'gray',
  } as CSSProperties,
};

export function SortableMediaCard({ src, id, actions = [] }: { src: string; id: string; actions?: ReactNode[] }) {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const draggableStyle = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    opacity: isDragging ? '0.5' : '1',
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  return (
    <Card
      ref={setNodeRef}
      style={draggableStyle}
      styles={{ body: { padding: '0 0.3rem', display: 'flex', flexDirection: 'column', gap: 5 } }}
    >
      {...actions}
      <div style={{ position: 'relative' }} {...attributes} {...listeners}>
        <DragOutlined style={styles.draggableIcon} />
        <Image src={src} preview={false} />
      </div>
    </Card>
  );
}
