import { TAuthState } from './useAuth';

class AuthStatService {
  private state: TAuthState = { tenantId: '', token: '' };

  set authState(authState: TAuthState) {
    this.state = authState;
  }

  get token() {
    return this.state.token;
  }

  get tenantId() {
    return this.state.tenantId;
  }
}

export const authStateSingleton = new AuthStatService();
