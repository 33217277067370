import ContentSider from '@/components/ContentSider';
import { useAuth } from '@/config/AuthContext/useAuth';
import AccidentLocation from '@/features/inspection/components/AccidentLocation';
import BlockedOrInvalidStatusMessage from '@/features/inspection/components/BlockedOrInvalidStatusMessage';
import InspectionDetails from '@/features/inspection/components/InspectionDetails/InspectionDetails';
import ActionBar from '@inspection/components/ActionBar/ActionBar';
import Gallery from '@inspection/components/Gallery/Gallery.index';
import IncidentDetails from '@inspection/components/IncidentDetails/IncidentDetails.tsx';
import InspectionComments from '@inspection/components/InspectionComments';
import InspectionSummery from '@inspection/components/InspectionSummery';
import OtherInspections from '@inspection/components/OtherInspections';
import ProgressTimeLine from '@inspection/components/ProgressTimeLine';
import SortableGallery from '@inspection/components/SortableGallery';
import { POP_OVER_TYPE } from '@inspection/constants';
import { useInspectionQuery } from '@inspection/hooks/useInspectionQuery.ts';
import { INSPECTION_VIEW_MODES, InspectionViewModes, TInspectionCompletionForm } from '@inspection/types';
import { getNumericValueByTitle, getStringValueByTitle } from '@inspection/utils/inspectionDetails.util';
import { Card, Flex, Layout, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';
import Invoices from '../../invoices/components/Invoices';

const InspectionViewLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // TODO: Handle undefined unique code
  const { uniqueCode } = useParams<{ uniqueCode: string }>();

  const {
    authState: { tenantId, token },
  } = useAuth();

  const {
    data: inspection,
    isLoading,
    isRefetching: isInspectionDataRefetching,
    refetch: refetchInspectionData,
  } = useInspectionQuery({ uniqueCode });

  const vehicleNumber = inspection?.vehicleNumber;
  const referenceNumber = inspection?.referenceNumber;

  useDocumentTitle((vehicleNumber ? vehicleNumber + ' | ' : '') + 'DigiEye');
  const refetchAll = () => {
    refetchInspectionData();
  };

  const initMode: InspectionViewModes = useMemo(
    () =>
      INSPECTION_VIEW_MODES.includes((searchParams.get('mode') || '') as InspectionViewModes)
        ? (searchParams.get('mode') as InspectionViewModes)
        : 'preview',
    []
  );

  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [mode, setMode] = useState<InspectionViewModes>(initMode);

  useEffect(() => {
    setSiderCollapsed(mode !== 'preview');
  }, [mode]);

  const styles: Styles = useStyles();

  const commentsCardRef = useRef<HTMLDivElement>(null);

  const onCommentsButtonClick = () => {
    if (commentsCardRef.current) {
      commentsCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  useEffect(() => {
    searchParams.set('mode', mode);
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [mode, navigate, searchParams]);

  return isLoading || isInspectionDataRefetching ? (
    <Flex align={'center'} justify={'center'} style={{ padding: '1rem' }}>
      <Spin size={'large'} />
    </Flex>
  ) : (
    <div style={{ backgroundColor: '#f5f4f7', paddingTop: '16px', paddingBottom: '16px' }}>
      <Card style={{ margin: '0 10px 0 16px' }} styles={{ body: { padding: 0 } }}>
        {inspection && uniqueCode ? (
          <ActionBar
            mode={mode}
            setMode={setMode}
            refetch={refetchAll}
            portalLink={inspection.portalLink}
            linkToAssessor={inspection.linkToAssessor}
            assignToMe={{ accepted: inspection ? inspection.accepted : false, uniqueCode, tenantId, token }}
            uniqueCode={uniqueCode}
            inspectionCode={inspection.inspectionCode}
            sumAssured={getNumericValueByTitle(
              'Sum Assured',
              inspection.inspectionDetails.inspectionDetails.data,
              true
            )}
            acceptedBy={inspection.acceptedBy}
            submittedDraft={inspection.submittedDraft as TInspectionCompletionForm}
            isInspectionCompleted={inspection.submitted}
            isInspectionBlocked={inspection.blocked}
            isMediaAvailable={inspection?.media.length > 0}
            isInspectionInvalid={inspection?.invalid}
            showComments={() => onCommentsButtonClick()}
            commentsAvailable={inspection?.comments?.length > 0}
          />
        ) : null}
      </Card>
      <Layout style={styles.mainLayout}>
        <ContentSider collapsed={siderCollapsed} setCollapsed={setSiderCollapsed} width="350px">
          {inspection ? (
            <div style={{ paddingLeft: '16px' }}>
              <InspectionSummery
                inspectionStatus={getStringValueByTitle(
                  'Inspection Status',
                  inspection.inspectionDetails.inspectionDetails.data
                )}
                vehicleNumber={inspection.vehicleNumber}
                accidentDate={inspection.accidentDate.split(' ')[0]}
                policyInformation={inspection.policyDetails?.policyInformation}
                intimationDetails={inspection.policyDetails?.intimationDetails}
              />
              <Invoices
                uniqueCode={uniqueCode}
                referenceNumber={inspection.referenceNumber}
                vehicleNumber={vehicleNumber}
                containerStyles={{ marginBottom: '1rem' }}
              />
              <InspectionDetails inspectionDetailsList={inspection?.inspectionDetails} />
              <AccidentLocation location={inspection.location} />
            </div>
          ) : null}
        </ContentSider>
        <Content style={styles.mainContainer}>
          {(inspection?.blocked || inspection?.invalid) && (
            <BlockedOrInvalidStatusMessage
              type={inspection.invalid ? POP_OVER_TYPE.INVALID : POP_OVER_TYPE.BLOCKED}
              time={inspection.invalid ? inspection?.markedAsInvalidAt : inspection?.markedAsBlockedAt}
              reason={inspection.invalid ? inspection?.invalidReason : inspection?.blockedReason}
            />
          )}
          <Flex style={{ padding: '0', height: '120px', marginBottom: '16px' }}>
            <Card style={{ width: '100%' }}>
              {inspection ? (
                <div style={{ width: '100%' }}>
                  <ProgressTimeLine items={inspection.timeLine} />
                </div>
              ) : null}
            </Card>
          </Flex>
          <Flex gap={mode === 'comparison' ? 16 : 0}>
            <div style={styles.evidenceGalleryContainer}>
              {mode === 'preview' && referenceNumber && (
                <Card size="small" style={styles.incidentDetailsContainer}>
                  <IncidentDetails referenceNumber={referenceNumber as string} isForm={true} />
                </Card>
              )}
              {!uniqueCode ? null : mode === 'sort' ? (
                <Card size="small" style={styles.incidentDetailsContainer}>
                  <SortableGallery uniqueCode={uniqueCode} onSuccess={() => setMode('preview')} />
                </Card>
              ) : (
                <>
                  <Card size="small" style={styles.incidentDetailsContainer}>
                    <Gallery
                      data={inspection?.media || []}
                      columns={mode === 'comparison' ? 2 : siderCollapsed ? 4 : 3}
                      uniqueCode={uniqueCode}
                      refetch={refetchAll}
                    />
                  </Card>
                </>
              )}

              <Card size="small" ref={commentsCardRef} style={styles.commentsCard}>
                {uniqueCode && inspection ? (
                  <InspectionComments uniqueCode={uniqueCode} commentList={inspection?.comments || []} />
                ) : null}
              </Card>
            </div>
            <div style={styles.inspectionHistoryContainer(mode === 'comparison')}>
              <div style={styles.scrollableDiv}>
                {mode === 'comparison' && (
                  <OtherInspections vehicleNumber={vehicleNumber} uniqueCode={uniqueCode as string} />
                )}
              </div>
            </div>
          </Flex>
        </Content>
      </Layout>
    </div>
  );
};

export default InspectionViewLayout;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    mainLayout: {
      paddingTop: '16px',
      gap: 8,
      backgroundColor: '#f5f4f7',
    } as CSSProperties,
    mainContainer: {
      minHeight: 'calc(100dvh - 60px)',
      padding: '0 8px',
    } as CSSProperties,
    evidenceGalleryContainer: {
      width: '100%',
      overflow: 'auto',
      scrollbarWidth: 'none',
    } as CSSProperties,
    scrollableDiv: {
      flex: 1,
    } as CSSProperties,
    inspectionHistoryContainer: (show: boolean): CSSProperties => ({
      overflow: 'auto',
      transition: 'width 0.2s ease-in-out',
      visibility: show ? 'visible' : 'hidden',
      width: show ? '100%' : '0%',
    }),
    commentsCard: {
      marginTop: '16px',
    } as CSSProperties,
    incidentDetailsContainer: {
      marginBottom: '10px',
    } as CSSProperties,
  };
};
