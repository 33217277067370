import { TInvoiceResponse } from '@/features/invoices/util/types';
import { IncidentDetailsProps } from '@inspection/components/IncidentDetails/IncidentDetails.tsx';
import Title from 'antd/es/typography/Title';
import { CSSProperties, useMemo } from 'react';
import { InspectionData, InspectionEvidenceData } from '../inspection/types';
import InspectionReportSection from './InspectionReportSection';
import { generateReportSections } from './util';

type InspectionReportViewProps = {
  hideItem: (key: string) => void;
  showItem: (key: string) => void;
  inspection: InspectionData;
  invoices?: TInvoiceResponse[];
  evidences: InspectionEvidenceData[];
  incidentDetails: IncidentDetailsProps[];
  printMode?: boolean;
};

const InspectionReportView = ({
  inspection,
  invoices,
  evidences,
  incidentDetails,
  showItem,
  hideItem,
  printMode,
}: InspectionReportViewProps) => {
  const styles = useStyles();

  const inspectionSections = useMemo(() => {
    return generateReportSections(inspection, evidences, incidentDetails, invoices);
  }, [inspection, invoices, evidences, incidentDetails]);

  return (
    <>
      <Title level={3} style={styles.title}>
        Inspection Report
      </Title>
      {inspectionSections.map((section) => (
        <InspectionReportSection
          printMode={printMode}
          key={section.key}
          title={section.title}
          level={section.level}
          type={section.type}
          content={section.content}
          align={section.align}
          width={section.width}
          showItem={() => {
            hideItem(section.key);
          }}
          hideItem={() => {
            showItem(section.key);
          }}
        />
      ))}
    </>
  );
};
export default InspectionReportView;

const useStyles = () => {
  return {
    title: {
      textAlign: 'center',
      color: '#1677FF',
    } as CSSProperties,
  };
};
