import { DigiEyeApi } from '@/config/http/axios.api-config';
import { formatCurrency, getCurrency } from '@invoices/util';
import { TInvoiceResponse, TLineItem } from '@invoices/util/types';
import { Font, Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';

type InvoicePdfProps = {
  invoice: TInvoiceResponse | undefined;
  tenantId: string;
  footerHidden?: boolean;
};

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/fonts/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
      fontWeight: 'normal',
    },
    {
      src: '/fonts/KFOlCnqEu92Fr1MmWUlfBBc9.ttf',
      fontWeight: 'bold',
    },
  ],
});

const InvoicePdf = ({ invoice, tenantId, footerHidden = false }: InvoicePdfProps) => {
  const [logoUrl, setLogoUrl] = useState<string>();
  const currency = getCurrency(tenantId);

  useEffect(() => {
    if (!isEmpty(tenantId)) {
      DigiEyeApi.get('logo').then((data) => {
        setLogoUrl(data?.data?.data?.logo || undefined);
      });
    }
  }, [tenantId]);

  const groupedItems = useMemo(() => {
    return invoice?.lineItems?.reduce(
      (acc, item) => {
        const section = item.sectionCode || 'default';
        if (!acc[section]) acc[section] = [];
        acc[section].push(item);
        return acc;
      },
      {} as Record<string, TLineItem[]>
    );
  }, [invoice?.lineItems]);

  const totals = useMemo(() => {
    if (!groupedItems || typeof groupedItems !== 'object') {
      return { overallTotal: 0, overallDiscount: 0, overallNetTotal: 0 };
    }

    let overallTotal = 0,
      overallDiscount = 0,
      overallNetTotal = 0;
    Object.values(groupedItems).forEach((items) => {
      const sectionTotal = items?.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0);
      const sectionDiscount = items?.reduce(
        (sum, item) => sum + (item.unitPrice * item.quantity * (item.discount ?? 0)) / 100,
        0
      );
      overallTotal += sectionTotal;
      overallDiscount += sectionDiscount;
      overallNetTotal += sectionTotal - sectionDiscount;
    });
    return { overallTotal, overallDiscount, overallNetTotal };
  }, [groupedItems]);

  if (!invoice) return null;

  const renderSection = (sectionCode: string, items: TLineItem[]) => (
    <View key={sectionCode} wrap={false}>
      {sectionCode !== 'default' && <Text style={styles.sectionTitle}>{sectionCode}</Text>}
      {items.map((item) => (
        <View style={styles.tableRow} key={item.id} wrap={false}>
          <Text style={styles.tableColLeft}>{item.itemName}</Text>
          <Text style={styles.tableColDescription}>{item.itemDescription || '-'}</Text>
          <Text style={styles.tableCol}>{item.quantity}</Text>
          <Text style={styles.tableColRight}>{formatCurrency(Number(item.unitPrice.toFixed(2)), tenantId, true)}</Text>
          <Text style={styles.tableColRight}>{item.discount}</Text>
          <Text style={styles.tableColRight}>
            {formatCurrency(Number((item.quantity * item.unitPrice).toFixed(2)), tenantId, true)}
          </Text>
        </View>
      ))}
      {!footerHidden && renderSectionTotals(items)}
    </View>
  );
  const renderSectionTotals = (items: TLineItem[]) => {
    const sectionTotal = items?.reduce((sum, item) => sum + item.quantity * item.unitPrice, 0);
    const sectionDiscount = items?.reduce(
      (sum, item) => sum + (item.unitPrice * item.quantity * (item.discount ?? 0)) / 100,
      0
    );
    const sectionNetTotal = sectionTotal - sectionDiscount;

    return (
      <View style={{ borderBottomWidth: 1, borderBottomColor: '#EDEDED' }} wrap={false}>
        <View style={styles.sectionSummary}>
          {[
            {
              label: `Subtotal (${currency}):`,
              value: formatCurrency(Number(sectionTotal.toFixed(2)), tenantId, true),
            },
            {
              label: `Discount (${currency}):`,
              value: formatCurrency(Number(sectionDiscount.toFixed(2)), tenantId, true),
            },
            {
              label: `Net Total (${currency}):`,
              value: formatCurrency(Number(sectionNetTotal.toFixed(2)), tenantId, true),
            },
          ].map(
            (item, index) =>
              item.value && (
                <View key={index} style={styles.sectionSummaryRow} wrap={false}>
                  <Text>{item.label}</Text>
                  <Text>{item.value}</Text>
                </View>
              )
          )}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.page}>
      <View style={styles.header} fixed>
        <Image src={logoUrl || ''} style={styles.logo} />
      </View>

      <View style={styles.invoiceDetails} wrap={false}>
        <View style={{ alignSelf: 'flex-start' }}>
          {[
            {
              label: 'Invoice Date:',
              value: invoice?.invoiceDate ? dayjs(invoice?.invoiceDate).format('Do of MMMM YYYY') : '',
            },
            {
              label: 'To:',
              value:
                invoice?.toName || invoice?.toAddress
                  ? `${invoice?.toName ? invoice?.toName : ''} \n ${invoice?.toAddress ? invoice?.toAddress : ''}`
                  : null,
              valueStyle: { lineHeight: 1.5 },
            },
            { label: 'Attn:', value: invoice?.attn },
          ].map(
            (item, index) =>
              item.value && (
                <View key={index} style={styles.detailRowLeft}>
                  <Text style={styles.detailLabel}>{item.label}</Text>
                  <Text style={item.valueStyle}>{item.value}</Text>
                </View>
              )
          )}
        </View>
        <View wrap={false}>
          <View style={{ gap: 8, marginBottom: 16, alignItems: 'flex-end', textAlign: 'right' }}>
            {invoice?.fromName && <Text>{invoice?.fromName}</Text>}
            {invoice?.fromAddress && <Text style={styles.lineHeight}>{invoice?.fromAddress}</Text>}
            {invoice?.fromContactInfo && <Text style={styles.lineHeight}>{invoice?.fromContactInfo}</Text>}
          </View>
          <View style={{ marginTop: 20 }}>
            {[
              { label: 'Invoice No:', value: invoice.invoiceNumber ? invoice.invoiceNumber : invoice.invoiceId },
              { label: 'Company Reg No:', value: invoice?.companyRegNo },
              { label: 'GST Reg No:', value: invoice?.taxRegNo },
            ].map(
              (item, index) =>
                item.value && (
                  <View key={index} style={styles.invoiceDetailsContainer}>
                    <Text style={styles.detailLabel}>{item.label}</Text>
                    <Text style={styles.detailValue}>{item.value}</Text>
                  </View>
                )
            )}
          </View>
        </View>
      </View>
      <View style={styles.taxInvoiceContainer} wrap={false}>
        <Text style={styles.title}>
          {/* TODO:Replace with invoiceTypeName */}
          {invoice?.invoiceTypeCode === 'TAX_INVOICE' ? 'TAX INVOICE' : invoice?.invoiceTypeCode}
        </Text>
        {invoice?.additionalFields?.map(
          (item, index) =>
            item.label && (
              <View key={index} style={styles.taxDetailRow}>
                <Text style={styles.taxDetailLabel}>{item.label}</Text>
                <Text style={styles.colon}>:</Text>
                <Text style={styles.taxDetailValue}>{item.value}</Text>
              </View>
            )
        )}
      </View>

      <View style={styles.table}>
        <View style={styles.tableHeader} fixed>
          {['Item', 'Description', 'Quantity', `Unit Price (${currency})`, 'Discount (%)', `Amount (${currency})`].map(
            (col, idx) => (
              <Text key={idx} style={styles.tableColHeader}>
                {col}
              </Text>
            )
          )}
        </View>

        {groupedItems &&
          typeof groupedItems === 'object' &&
          Object.entries(groupedItems)
            .sort(([a], [b]) => (a === 'default' ? -1 : b === 'default' ? 1 : a.localeCompare(b)))
            .map(([sectionCode, items]) => renderSection(sectionCode, items))}
      </View>

      <View style={styles.overallTotalBox} wrap={false}>
        {[
          {
            label: `Total Amount (${currency}):`,
            value: formatCurrency(Number(totals.overallTotal.toFixed(2)), tenantId, true),
          },
          {
            label: `Total Discount (${currency}):`,
            value: formatCurrency(Number(totals.overallDiscount.toFixed(2)), tenantId, true),
          },
        ].map((item, index) => (
          <View key={index} style={styles.totalItem}>
            <Text style={styles.totalLabel}>{item.label}</Text>
            <Text style={styles.totalValue}>{item.value}</Text>
          </View>
        ))}
        <View style={styles.GrandTotalItem}>
          <Text style={styles.totalLabel}>{`Grand Total (${currency}):`}</Text>
          <Text style={styles.totalValue}>
            {formatCurrency(Number(totals.overallNetTotal.toFixed(2)), tenantId, true)}
          </Text>
        </View>
      </View>

      <View style={{ marginTop: 20 }} wrap={false}>
        {[
          { label: 'Payment terms:', value: invoice?.terms },
          { label: 'Due Date:', value: invoice?.dueDate ? dayjs(invoice?.dueDate).format('Do of MMMM YYYY') : '' },
          { label: 'Customer Note:', value: invoice?.customerNote },
        ].map(
          (item, index) =>
            item.value && (
              <View
                key={index}
                style={[styles.detailRow, ...(item.label === 'Payment terms:' ? [{ marginBottom: 8 }] : [])]}
              >
                <Text style={styles.detailLabel}>{item.label}</Text>
                <Text style={styles.detailValue}>{item.value}</Text>
              </View>
            )
        )}
      </View>
    </View>
  );
};

export default InvoicePdf;

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    fontSize: 8,
    color: '#4f5f6f',
    fontFamily: 'Roboto',
  },
  header: {
    marginBottom: '16px',
  },
  logo: {
    width: '80px',
  },
  invoiceDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  detailRowLeft: {
    alignSelf: 'flex-start',
    marginVertical: 10,
    flexDirection: 'row',
  },
  detailLabel: {
    fontWeight: 'bold',
    marginRight: 4,
  },
  detailValue: {
    textAlign: 'left',
  },
  detailRow: {
    alignSelf: 'flex-start',
    marginVertical: 2,
    flexDirection: 'row',
  },
  taxInvoiceContainer: {
    marginBottom: 16,
  },
  title: {
    textAlign: 'center',
    fontSize: 9,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  taxDetailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
    width: '50%',
  },
  taxDetailLabel: {
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'left',
  },
  taxDetailValue: {
    flex: 1,
    textAlign: 'right',
  },
  colon: {
    flex: 0.2,
    textAlign: 'center',
  },
  table: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#EDEDED',
    marginBottom: 20,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f0f0f0',
  },
  tableColHeader: {
    width: '20%',
    padding: 8,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  overallTotalBox: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#EDEDED',
    borderRadius: 4,
  },
  totalItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
  },
  totalLabel: {
    textAlign: 'left',
    fontWeight: 'bold',
  },
  totalValue: {
    textAlign: 'right',
  },
  GrandTotalItem: {
    borderTopWidth: 1,
    flexDirection: 'row',
    borderColor: '#EDEDED',
    justifyContent: 'space-between',
    fontSize: 10,
    color: '#000',
    padding: 8,
  },
  taxReportBox: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#000',
  },
  taxTitle: {
    alignSelf: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000',
  },
  taxReportHeaderContainer: {
    textAlign: 'center',
    borderTopWidth: 1,
    flexDirection: 'row',
    borderColor: '#000',
    justifyContent: 'center',
    fontSize: 10,
    color: '#000',
    padding: 2,
  },
  taxReportSubHeader: {
    fontSize: 8,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableItemContainer: {
    borderTopWidth: 1,
    flexDirection: 'row',
    borderColor: '#000',
    justifyContent: 'space-between',
    fontSize: 10,
    color: '#000',
    padding: 8,
  },
  tableRowContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '48%',
  },
  tableRowItemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 2,
  },
  tableRowItemLabel: {
    fontWeight: 'bold',
    textAlign: 'left',
    marginRight: 4,
    width: '50%',
  },
  tableRowItemValue: {
    textAlign: 'left',
    width: '50%',
  },
  tableRowUnderlineTxt: {
    textDecoration: 'underline',
    marginBottom: 4,
    fontWeight: 'bold',
  },
  signatureMainContainer: {
    fontSize: 10,
    borderTopWidth: 1,
    borderColor: '#000',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '4px 16px',
    height: 150,
    alignItems: 'flex-end',
  },
  signatureSection: {
    width: '45%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  signatureLine: {
    borderBottomWidth: 1,
    borderColor: '#000',
    width: '100%',
    marginBottom: 4,
  },
  signatureTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  signatureLabel: {
    textAlign: 'left',
    marginBottom: 4,
  },
  signatureText: {
    textAlign: 'center',
  },
  sectionTitle: {
    fontSize: 9,
    borderBottomWidth: 1,
    borderColor: '#EDEDED',
    backgroundColor: '#fafafa',
    padding: 8,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#EDEDED',
  },
  tableColLeft: {
    width: '20%',
    padding: 8,
    textAlign: 'left',
  },
  tableColDescription: {
    width: '20%',
    padding: 8,
    textAlign: 'center',
  },
  tableCol: {
    width: '20%',
    padding: 8,
    textAlign: 'center',
  },
  tableColRight: {
    width: '20%',
    padding: 8,
    textAlign: 'right',
  },
  sectionSummary: {
    padding: 12,
    gap: 4,
    alignSelf: 'flex-end',
    width: '175px',
  },
  sectionSummaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lineHeight: {
    lineHeight: 1.5,
  },
  invoiceDetailsContainer: {
    marginVertical: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});
