import ContentOverlay from '@/components/ContentOverlay';
import StatisticGrid from '@/components/StatisticGrid';
import { CommentOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Flex, Tag, Typography } from 'antd';
import { CSSProperties, useState } from 'react';
import { KeyValuePairList } from '../types';

type Props = {
  vehicleNumber: string;
  inspectionStatus: string;
  accidentDate: string;
  policyInformation: { title: string; data: KeyValuePairList };
  intimationDetails: { title: string; data: KeyValuePairList };
};

const InspectionSummery = (props: Props) => {
  const styles: Styles = useStyles();

  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [showIntimationDetails, setShowIntimationDetails] = useState(false);
  const [overlayTitle, setOverlayTitle] = useState('');

  return (
    <Card
      size="small"
      style={{
        height: '120px',
        marginBottom: '16px',
      }}
      styles={{ body: { height: '100%' } }}
    >
      <Flex vertical justify="space-between" style={{ height: '100%' }}>
        <Flex justify="space-between" align="start">
          <div>
            <Typography.Title level={3} style={styles.vehicleNumberPlate}>
              {props.vehicleNumber}
            </Typography.Title>
          </div>
          <div>
            <Tag style={styles.statusTag} color="blue">
              {props.inspectionStatus}
            </Tag>
            <Typography.Paragraph style={{ margin: 0, fontSize: 12, textAlign: 'end' }}>
              {props.accidentDate}
            </Typography.Paragraph>
          </div>
        </Flex>
        <Divider style={{ margin: 0 }} />
        <Flex justify="space-between" gap={8}>
          <Button
            style={{ width: '50%' }}
            onClick={() => {
              setOverlayTitle('Policy Details');
              setShowPolicyDetails(true);
            }}
          >
            <FileTextOutlined />
            Policy Details
          </Button>
          <Button
            style={{ width: '50%' }}
            onClick={() => {
              setOverlayTitle('Intimation Details');
              setShowIntimationDetails(true);
            }}
          >
            <CommentOutlined />
            Intimation Details
          </Button>
        </Flex>
      </Flex>
      <ContentOverlay
        title={
          props.policyInformation
            ? props.policyInformation.title
              ? props.policyInformation.title
              : overlayTitle
            : overlayTitle
        }
        open={showPolicyDetails}
        onCancel={() => setShowPolicyDetails(false)}
      >
        {props.policyInformation ? (
          props.policyInformation.data.length > 0 ? (
            <StatisticGrid data={props.policyInformation.data} columns={4} />
          ) : (
            <Typography.Text>No Data Found</Typography.Text>
          )
        ) : (
          <Typography.Text>No Data Found</Typography.Text>
        )}
      </ContentOverlay>
      <ContentOverlay
        title={
          props.intimationDetails
            ? props.intimationDetails.title
              ? props.intimationDetails.title
              : overlayTitle
            : overlayTitle
        }
        open={showIntimationDetails}
        onCancel={() => setShowIntimationDetails(false)}
      >
        {props.intimationDetails ? (
          props.intimationDetails.data.length > 0 ? (
            <StatisticGrid data={props.intimationDetails.data} columns={4} />
          ) : (
            <Typography.Text>No Data Found</Typography.Text>
          )
        ) : (
          <Typography.Text>No Data Found</Typography.Text>
        )}
      </ContentOverlay>
    </Card>
  );
};

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    statusTag: {
      width: 'fit-content',
      height: 'fit-content',
      fontWeight: 600,
      textWrap: 'wrap',
      lineHeight: '1rem',
      margin: 0,
      overflowWrap: 'break-word',
      maxWidth: '150px',
    } as CSSProperties,
    vehicleNumberPlate: {
      margin: 0,
      fontFamily: 'monospace',
      border: '1px lightgray solid',
      borderRadius: '4px',
      padding: '2px 8px',
    } as CSSProperties,
  };
};

export default InspectionSummery;
