import MarkAsBlockedButton from '@inspection/components/ActionBar/MarkAsBlocked/MarkAsBlockedButton';
import MarkAsResolvedButton from '@inspection/components/ActionBar/MarkAsBlocked/MarkAsResolvedButton';

type Props = {
  isInspectionInvalid: boolean;
  uniqueCode: string;
  token: string;
  tenantId: string;
  refetch: () => void;
  isInspectionCompleted: boolean;
  isInspectionBlocked: boolean;
  isAuthorizedUser: boolean;
};

const BlockStatus = ({
  isInspectionInvalid,
  isInspectionBlocked,
  isInspectionCompleted,
  uniqueCode,
  token,
  tenantId,
  refetch,
  isAuthorizedUser,
}: Props) => {
  return isInspectionBlocked ? (
    <MarkAsResolvedButton
      isAuthorizedUser={isAuthorizedUser}
      uniqueCode={uniqueCode}
      token={token}
      tenantId={tenantId}
      refetch={refetch}
    />
  ) : (
    <MarkAsBlockedButton
      isAuthorizedUser={isAuthorizedUser}
      isInspectionInvalid={isInspectionInvalid}
      isInspectionCompleted={isInspectionCompleted}
    />
  );
};
export default BlockStatus;
