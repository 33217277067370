import LastUpdated from '@/components/LastUpdated.tsx';
import { fetchInspectionSummary } from '@/features/inspectionList/services/inspectionList.service';
import { CameraOutlined, ReloadOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Flex, Space } from 'antd';

type Props = {
  uniqueCode: string;
};

const InspectionSummary = ({ uniqueCode }: Props) => {
  const {
    data: summary,
    isFetching: isSummaryFetching,
    error: fetchSummaryError,
    refetch: refetchSummary,
  } = useQuery({
    queryKey: ['inspection-summary', uniqueCode],
    queryFn: () => fetchInspectionSummary({ uniqueCode }),
    staleTime: 60 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60 * 60 * 1000,
  });

  return (
    <Flex
      justify="space-between"
      align="center"
      style={{ marginLeft: '7rem', marginRight: '43px', color: 'rgba(46,46,46,0.54)' }}
    >
      {fetchSummaryError ? (
        <span style={{ color: 'red' }}>{fetchSummaryError.message}</span>
      ) : (
        !isSummaryFetching && (
          <Space size={'large'}>
            <Space>
              <CameraOutlined />
              <span>Images : </span>
              <span>
                {summary?.uploadedImages || 0} / {summary?.totalImages || 0}
              </span>
            </Space>
            <Space>
              <VideoCameraOutlined />
              <span>Videos</span>
              <span>
                {summary?.uploadedVideos || 0} / {summary?.totalVideos || 0}
              </span>
            </Space>
          </Space>
        )
      )}
      <Space>
        {!isSummaryFetching && <LastUpdated timestamp={new Date(summary?.timestamp)} />}
        <Button type={'text'} loading={isSummaryFetching} icon={<ReloadOutlined />} onClick={() => refetchSummary()} />
      </Space>
    </Flex>
  );
};

export default InspectionSummary;
