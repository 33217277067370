import { Col, Row, Statistic } from 'antd';
import { CSSProperties } from 'react';
import { KeyValuePairList } from '../../types';

type Props = {
  inspectionData: KeyValuePairList;
};

const InspectionCollapsibleBody = ({ inspectionData }: Props) => {
  const styles: Styles = useStyles();

  return (
    <Row style={styles.row}>
      {inspectionData?.map((item, index) => (
        <Col span={12} key={index} style={styles.col}>
          <Statistic
            style={styles.statistic}
            title={item.title}
            value={item.description}
            groupSeparator={item.title === 'Mobile Number' ? '' : ','}
          />
        </Col>
      ))}
    </Row>
  );
};

export default InspectionCollapsibleBody;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    row: {
      width: '100%',
    } as CSSProperties,
    col: {
      marginBottom: 15,
      padding: '0 4px',
    } as CSSProperties,
    statistic: {
      textWrap: 'wrap',
      overflowWrap: 'anywhere',
    } as CSSProperties,
  };
};
