import { TTags } from '@/features/inspectionList/types';
import { getTagColor } from '@inspection/utils/gallery.util';
import { Tag } from 'antd';

type Props = {
  tags: TTags;
};

const TagList = ({ tags }: Props) => {
  if (!tags || tags.length === 0) return null;

  return (
    <div>
      {tags.map((singleTag, index) => (
        <Tag key={index} color={getTagColor(singleTag.code)}>
          {singleTag.name}
        </Tag>
      ))}
    </div>
  );
};

export default TagList;
