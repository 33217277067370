import { TDateRange } from '@/features/inspection/types';
import { reportGenerate } from '@/features/inspectionList/services/inspectionList.service';
import { useToastApi } from '@/hooks/useToastApi';
import { MoreOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, DatePicker, Flex, Form, Popover, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { CSSProperties, useState } from 'react';

const Report = () => {
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [visible, setVisible] = useState(false);
  const toastApi = useToastApi();
  const styles = useStyles();

  const mutation = useMutation({
    mutationFn: ({ startDate, endDate }: TDateRange) => reportGenerate({ startDate, endDate }),
    onSuccess: (data) => {
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? 'Report has been sent to your email' : data.message,
        duration: 2,
      });
      setVisible(false);
      setStartDate(undefined);
      setEndDate(undefined);
      form.resetFields();
    },
    onError: (data) => {
      toastApi.open({
        type: 'error',
        content: data?.message || 'An error occurred while generating the report',
        duration: 2,
      });
      setVisible(false);
    },
  });

  const generateReport = () => {
    form.validateFields().then(() => {
      mutation.mutate({
        startDate: startDate!,
        endDate: endDate!,
      });
    });
  };

  return (
    <Popover
      trigger={'click'}
      placement={'bottomLeft'}
      title={'Pick a date range'}
      open={visible}
      onOpenChange={(open) => {
        if (!open) {
          form.resetFields();
        }
        setVisible(open);
      }}
      content={
        <Form form={form} layout="vertical">
          <Flex vertical>
            <Form.Item name="dateRange" rules={[{ required: true, message: 'Please select a date range' }]}>
              <DatePicker.RangePicker
                value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : [null, null]}
                placeholder={['Start Date', 'End Date']}
                onChange={(value) => {
                  setStartDate(value ? value[0]?.toISOString() : undefined);
                  setEndDate(value ? value[1]?.toISOString() : undefined);
                  form.setFieldsValue({
                    dateRange: value,
                  });
                }}
              />
            </Form.Item>
            <Button type="primary" onClick={generateReport} style={styles.GenerateBtn} loading={mutation.isPending}>
              Generate Report
            </Button>
          </Flex>
        </Form>
      }
    >
      <Tooltip title="Generate Report" placement="bottom" mouseEnterDelay={2}>
        <Button size="middle" icon={<MoreOutlined />} />
      </Tooltip>
    </Popover>
  );
};

const useStyles = () => {
  return {
    GenerateBtn: {
      alignSelf: 'flex-end',
    } as CSSProperties,
  };
};

export default Report;
