import { CopyOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Tooltip, Typography } from "antd";
import { CSSProperties, useState } from "react";
import MapComponent from "./MapComponent";
import { useToastApi } from "@/hooks/useToastApi.tsx";
import { TLocation } from "@/types";
import { useCopyLocation } from "@/features/inspection/utils/location.util.ts";

type Props = {
  location: TLocation;
  title: string;
};

const LocationModal = ({ location, title }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const styles: Styles = useStyles();
  const toastApi = useToastApi();
  const { copyLocationToClipboard } = useCopyLocation(toastApi);

  return (
    <>
      <Button onClick={() =>  setIsModalOpen(true)}><EnvironmentOutlined/></Button>
      <Modal title={<Flex style={styles.titleContainer} gap={8}>
        <Typography.Title level={5} style={styles.cardTitle}>{title}</Typography.Title>
        <Tooltip placement={'bottom'} title={'Copy shareable link'} mouseEnterDelay={1} >
          <Button shape="default" style={styles.copyButton} onClick={() => copyLocationToClipboard(location)}>
            <CopyOutlined />
          </Button>
        </Tooltip>
      </Flex>} open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} centered width={'50vw'}>
        <div style={{ height: '50vh', padding: '0' }}>
          <MapComponent location={location} />
        </div>
      </Modal>
    </>
  );
};

export default LocationModal;

type Styles = ReturnType<typeof useStyles>;

const useStyles = () => {
  return {
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    } as CSSProperties,
    cardTitle: {
      margin: 0,
    } as CSSProperties,
    copyButton: {
      padding: '0',
      width: '30px',
      height: '30px',
      color: '#000'
    } as CSSProperties,
  };
};

