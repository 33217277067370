import { EditFilled } from '@ant-design/icons';
import EditableTextView from '@components/EditableTextView.tsx';
import { Col, Row } from 'antd';
import startCase from 'lodash/startCase';
import React, { CSSProperties } from 'react';

export interface KeyValuePair {
  name: string;
  value: string | number;
}

type Props = {
  section: string;
  data: KeyValuePair[];
  isForm: boolean;
  onSave: (data: KeyValuePair[]) => void;
};

const SubSectionDetails: React.FC<Props> = ({ section, data, isForm, onSave }) => {
  const styles = useStyles();

  const onSaveValue = (name: string) => (value: string | number) => {
    const previousItem = data.find((item) => item.name === name);
    if (previousItem && previousItem.value === value) {
      return;
    }
    const updatedData = data.map((item) => (item.name === name ? { ...item, value } : item));
    onSave(updatedData);
  };

  const textAreaSections = ['describeCircumstancesOfTheAccident', 'invoiceRemarks', 'recommendationAndConclusion'];

  return (
    <>
      {section && <div style={styles.title}>{startCase(section)}</div>}
      {textAreaSections.includes(section) ? (
        <EditableTextView
          type={'text-area'}
          icon={<EditFilled style={styles.editIcon} />}
          onSave={onSaveValue(data[0].name)}
          key={data[0].value}
          value={data[0].value ? data[0].value : isForm ? data[0].value : '-'}
          editable={isForm}
        />
      ) : (
        data.map((item, index) => {
          // Split the string by slash
          const parts = item.name.split('/');
          const formattedItemName = parts.map((part) => startCase(part)).join(' / ');

          return (
            <Row key={index} style={styles.row}>
              <Col span={12} style={styles.colKey}>
                <Row>
                  <Col style={styles.colKeyText}>{formattedItemName}</Col>
                  <Col style={styles.colKeyLine}>
                    <div style={styles.dashedLine} />
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={styles.colValue}>
                <EditableTextView
                  icon={<EditFilled style={styles.editIcon} />}
                  onSave={onSaveValue(item.name)}
                  key={item.value}
                  value={item.value ? item.value : isForm ? item.value : '-'}
                  editable={isForm}
                />
              </Col>
            </Row>
          );
        })
      )}
    </>
  );
};

const useStyles = () => {
  return {
    title: {
      backgroundColor: '#E8F1FF',
      paddingLeft: '0.5rem',
      fontSize: 16,
      fontWeight: 500,
      color: '#1677FF',
      marginTop: '10px',
      marginBottom: '8px',
      padding: '8px',
    } as CSSProperties,
    row: {
      marginBottom: '5px',
      alignItems: 'center',
      display: 'flex',
      marginLeft: '4px',
    } as CSSProperties,
    colKey: {
      textAlign: 'left',
      fontWeight: 'bold',
      position: 'relative',
    } as CSSProperties,
    colKeyText: {
      flex: '0 1 auto',
      textAlign: 'left',
      fontWeight: 500,
    } as CSSProperties,
    colKeyLine: {
      flex: '1 1 auto',
      margin: '0 10px',
      display: 'flex',
      alignItems: 'center',
    } as CSSProperties,
    dashedLine: {
      width: '100%',
      borderBottom: '1px dashed rgba(0, 0, 0, 0.3)',
      opacity: 0.8,
    } as CSSProperties,
    colValue: {
      textAlign: 'left',
      paddingLeft: '10px',
    } as CSSProperties,
    editIcon: {
      color: 'grey',
      margin: 0,
      padding: 0,
    } as CSSProperties,
  };
};

export default SubSectionDetails;
