import { useAuth } from '@/config/AuthContext/useAuth';
import { useToastApi } from '@/hooks/useToastApi';
import { POP_OVER_TYPE } from '@inspection/constants';
import { markAsBlocked, markAsInvalid } from '@inspection/services/inspections.service';
import { TMarkAsBlocked, TMarkAsInvalid, TPopOver } from '@inspection/types';
import { useMutation } from '@tanstack/react-query';
import { Button, Flex, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';

type Props = {
  uniqueCode: string;
  setShowPopover: (value: boolean) => void;
  refetch: () => void;
  type: TPopOver;
};

const MarkAsInvalidOrBlockedPopOverContent = ({ uniqueCode, setShowPopover, refetch, type }: Props) => {
  const [form] = Form.useForm();
  const toastApi = useToastApi();
  const {
    authState: { tenantId, token },
  } = useAuth();

  const mutation = useMutation({
    mutationFn: ({ tenantId, token, uniqueCode, reason }: TMarkAsInvalid | TMarkAsBlocked) =>
      type === POP_OVER_TYPE.INVALID
        ? markAsInvalid({ tenantId, token, uniqueCode, reason })
        : markAsBlocked({ tenantId, token, uniqueCode, reason }),
    onSuccess: (data) => {
      form.resetFields();
      setShowPopover(false);
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success
          ? `Inspection marked as ${type === POP_OVER_TYPE.INVALID ? POP_OVER_TYPE.INVALID : POP_OVER_TYPE.BLOCKED}!`
          : data.message,
        duration: 2,
      });
      refetch();
    },
    onError: (data) => {
      form.resetFields();
      setShowPopover(false);
      toastApi.open({
        type: 'error',
        content: data?.message || 'Something went wrong!',
        duration: 2,
      });
    },
  });

  const onCancel = () => {
    form.resetFields();
    setShowPopover(false);
  };

  const onFinish = (values: any) => {
    mutation.mutate({ tenantId, token, uniqueCode, reason: values.reason });
  };

  return (
    <Flex>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.submitStatus !== currentValues.submitStatus}
        >
          {({ setFieldValue }) => (
            <Form.Item
              label={`Reason for marking this inpection as ${type === POP_OVER_TYPE.INVALID ? POP_OVER_TYPE.INVALID : POP_OVER_TYPE.BLOCKED}`}
              name={'reason'}
              rules={[
                {
                  required: true,
                  message: `Please enter the reason to mark this inspection as ${type === POP_OVER_TYPE.INVALID ? POP_OVER_TYPE.INVALID : POP_OVER_TYPE.BLOCKED}`,
                },
              ]}
              required
              style={{ marginTop: 12 }}
            >
              <TextArea
                maxLength={300}
                rows={2}
                placeholder="Enter the reason"
                size="middle"
                onChange={(e) => setFieldValue('reason', e.target.value)}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Flex justify="end" gap={8}>
          <Form.Item style={{ marginBottom: '4px' }}>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
          </Form.Item>

          <Form.Item style={{ marginBottom: '4px' }}>
            <Button type="primary" htmlType="submit" loading={mutation.isPending}>
              Submit
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Flex>
  );
};
export default MarkAsInvalidOrBlockedPopOverContent;
