import Logo from '@components/Logo.tsx';
import { TCustomHeaderForm } from '@invoices/util/types';
import { Col, Flex, Row } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';

const InvoiceHeader = ({
  invoiceId,
  invoiceDate,
  invoiceNumber,
  toName,
  toAddress,
  attn,
  fromName,
  fromAddress,
  fromContactInfo,
  companyRegNo,
  taxRegNo,
  additionalFields,
  invoiceTypeName,
}: TCustomHeaderForm) => {
  const styles = useStyles;

  const renderRow = (label: string, content?: string) =>
    content && (
      <Row gutter={4} justify="space-around" style={styles.row}>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Text strong>{label}</Text>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Text strong>{content}</Text>
        </Col>
      </Row>
    );
  return (
    <div style={styles.container}>
      <Flex justify="space-between" align="start" style={styles.topSection}>
        <div>
          <Logo />
        </div>
        <Flex vertical align="end">
          <Title level={4} style={styles.title}>
            {invoiceTypeName}
          </Title>
          <Text>{`#${invoiceNumber || invoiceId}`}</Text>
        </Flex>
      </Flex>
      <Flex justify="space-between" style={styles.section}>
        <Flex vertical gap={16}>
          <Flex vertical>
            <Text strong>Invoice Date</Text>
            <Text>{invoiceDate ? dayjs(invoiceDate).format('DD MMM, YYYY') : dayjs().format('DD MMM, YYYY')}</Text>
          </Flex>
          {toAddress ? (
            <Flex vertical>
              <Text strong>To</Text>
              <Text>{toName}</Text>
              <Text style={styles.txtStyle}>{toAddress}</Text>
            </Flex>
          ) : (
            <Flex>{''}</Flex>
          )}
        </Flex>

        <Flex vertical gap={16} align="end" style={{ textAlign: 'right' }}>
          {fromAddress ? (
            <Flex vertical>
              <Text strong>From</Text>
              <Text>{fromName}</Text>
              <Text style={styles.txtStyle}>{fromAddress}</Text>
            </Flex>
          ) : (
            <Flex>{''}</Flex>
          )}
          {fromContactInfo ? (
            <Flex vertical>
              <Text strong>Contact</Text>
              <Text style={styles.txtStyle}>{fromContactInfo}</Text>
            </Flex>
          ) : (
            <Flex>{''}</Flex>
          )}
        </Flex>
      </Flex>
      <Flex justify="space-between" style={styles.section}>
        {attn ? (
          <Flex vertical align="start" gap={4} style={styles.attnSection}>
            <Text strong>Attn</Text>
            <Text>{attn}</Text>
          </Flex>
        ) : (
          <Flex>{''}</Flex>
        )}
        <Flex vertical style={{ width: '100%' }}>
          {renderRow('Registration No :', companyRegNo)}
          {renderRow('GST Registration No :', taxRegNo)}
        </Flex>
      </Flex>

      {additionalFields && additionalFields?.length > 0 && (
        <>
          <Title level={4} style={styles.subTitle}>
            {invoiceTypeName === 'TAX_INVOICE'
              ? 'Tax Invoice'
              : invoiceTypeName === 'SPECIAL_NET_ITEMS'
                ? 'Special Net Items'
                : invoiceTypeName}
          </Title>
          <Flex vertical style={{ width: '50%' }}>
            {additionalFields.map(
              (detail, index) =>
                detail?.label && (
                  <Row key={index} justify="space-between">
                    <Text>{`${detail.label}:`}</Text>
                    <Text>{detail.value}</Text>
                  </Row>
                )
            )}
          </Flex>
        </>
      )}
    </div>
  );
};

const useStyles = {
  container: {
    width: '100%',
    margin: '0 auto',
  },
  topSection: { marginBottom: '16px' },
  section: {
    marginBottom: 16,
  },
  txtStyle: {
    lineHeight: 1.2,
    whiteSpace: 'pre-wrap',
  },
  attnSection: { marginTop: '8px', width: '100%' },
  title: { margin: 0 },
  subTitle: { textAlign: 'center' } as CSSProperties,
  row: { width: '100%' },
};

export default InvoiceHeader;
