import InvoiceFooterSkeleton from './InvoiceFooterSkeleton';
import InvoiceHeaderSkeleton from './InvoiceHeaderSkeleton';
import TableSkeleton from './TableSkeleton';

const InvoiceViewSkeleton = () => {
  return (
    <div>
      {/* Header Skeleton */}
      <InvoiceHeaderSkeleton />

      {/* Invoice Table Skeleton */}
      <TableSkeleton />

      {/* Invoice Footer Skeleton */}
      <InvoiceFooterSkeleton />
    </div>
  );
};
export default InvoiceViewSkeleton;
