import {Typography} from "antd";
import {CSSProperties, useEffect, useState} from "react";

type Props = {
    timestamp: Date,
    style?: CSSProperties
}

const lastUpdatedInMinutes = (timestamp: Date) => {
    const diff = new Date().getTime() - timestamp.getTime();
    return Math.floor(diff / 60000);
}

const LastUpdated = ({timestamp, style}: Props) => {
    const [minutes, setMinutes] = useState(lastUpdatedInMinutes(timestamp))
    useEffect(() => {
        const timeout = setTimeout(() => {
            setMinutes(lastUpdatedInMinutes(timestamp));
        }, 60000)
        return () => clearTimeout(timeout)
    }, [timestamp]);

    return (
        <Typography.Text style={style}>
            Last updated {minutes < 1 ? 'a few seconds ago' : `${minutes} min ago`}
        </Typography.Text>
    );
};

export default LastUpdated;
