import ContentOverlay from '@/components/ContentOverlay';
import CurrencySelectInput from '@/components/CurrencySelectInput';
import { useAuth } from '@/config/AuthContext/useAuth';
import { getCurrency } from '@/features/invoices/util';
import { useToastApi } from '@/hooks/useToastApi';
import { APPLICABLE_TYPES, SUBMIT_STATUSES, SWITCH_LABELS } from '@inspection/constants';
import { completeInspection } from '@inspection/services/inspections.service';
import { TCompleteInspection, TInspectionCompletionForm } from '@inspection/types';
import { useMutation } from '@tanstack/react-query';
import { Button, Divider, Flex, Form, Select, Space, Statistic, Switch, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import startCase from 'lodash/startCase';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';

type Props = {
  uniqueCode: string;
  children: ReactNode;
  inspectionCode: string;
  sumAssured: number;
  submittedDraft: TInspectionCompletionForm;
  refetch: () => void;
  disable?: boolean;
};

const CompleteInspection = ({
  inspectionCode,
  uniqueCode,
  sumAssured,
  children,
  submittedDraft,
  refetch,
  disable = false,
}: Props) => {
  const styles = useStyles();
  const [form] = Form.useForm();
  const toastApi = useToastApi();
  const {
    authState: { tenantId, token },
  } = useAuth();
  const { Text } = Typography;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const CURRENCY = getCurrency(tenantId);
  const options = getSubmitStatuses(inspectionCode);
  const [showCompleteInspectionOverlay, setShowCompleteInspectionOverlay] = useState(false);

  const onCancel = () => {
    form.resetFields();
    setShowCompleteInspectionOverlay(false);
  };

  useEffect(() => {
    if (submittedDraft?.isCustomerDecisionPending) {
      form.setFieldsValue({
        submitStatus: SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING,
        offerStatus: submittedDraft.offerStatus,
        consistency: submittedDraft.consistency,
        collectSalvage: submittedDraft.collectSalvage,
        policeReport: submittedDraft.policeReport,
        valuation: parseFloat(submittedDraft.valuation),
        offerAmount: parseFloat(submittedDraft.offerAmount),
        PAV: parseFloat(submittedDraft.PAV),
        salvages: submittedDraft.salvages,
        operatorComment: submittedDraft.operatorComment,
      });
    }
  }, [submittedDraft, form, showCompleteInspectionOverlay]);

  const mutation = useMutation({
    mutationFn: ({ tenantId, token, uniqueCode, form: submittedValues }: TCompleteInspection) =>
      completeInspection({ tenantId, token, uniqueCode, form: submittedValues }),
    onSuccess: (data) => {
      form.resetFields();
      setShowCompleteInspectionOverlay(false);
      setIsSubmitted(false);
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? 'Inspection completed successfully!' : data.message,
        duration: 2,
      });
      data?.success && refetch();
    },
    onError: (data) => {
      form.resetFields();
      setShowCompleteInspectionOverlay(false);
      toastApi.open({
        type: 'error',
        content: data?.message || 'Something went wrong!',
        duration: 2,
      });
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    const submittedValues = {
      uniqueCode: uniqueCode,
      submitStatus: values.submitStatus,
      valuation: values.valuation.toString(),
      offerStatus: values.offerStatus ? 'true' : 'false',
      consistency: values.consistency ? 'true' : 'false',
      policeReport: values.policeReport ? 'true' : 'false',
      collectSalvage: values.collectSalvage ? 'true' : 'false',
      operatorComment: values.operatorComment,
      salvages: values.salvages ? values.salvages : null,
      offerAmount: values.offerAmount ? values.offerAmount.toString() : null,
      PAV: values.PAV ? values.PAV.toString() : null,
    };

    mutation.mutate({ tenantId, token, uniqueCode, form: submittedValues });
  };

  const onSubmitStatusChange = (value: string) => {
    if (submittedDraft?.isCustomerDecisionPending && form.getFieldValue('offerAmount'))
      form.setFieldValue('offerStatus', true);
    if (value === SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING) form.setFieldValue('offerStatus', false);
  };

  return (
    <>
      <ContentOverlay
        title={isSubmitted ? 'Confirm Submission' : 'Complete Inspection'}
        open={showCompleteInspectionOverlay}
        onCancel={() => setShowCompleteInspectionOverlay(false)}
        width="700px"
        afterClose={onCancel}
      >
        <Form form={form} layout="vertical" onFinish={onFinish} style={{ marginRight: '16px' }}>
          <div style={{ display: isSubmitted ? 'none' : '' }}>
            <Form.Item label={'Submit Status'} name={'submitStatus'} rules={[{ required: true }]}>
              <Select placeholder={'Select submit status'} onChange={onSubmitStatusChange}>
                {options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Divider style={styles.horizontalDividerTop} />

            <Flex justify="space-between" gap={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.submitStatus !== currentValues.submitStatus}
              >
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item
                    label={
                      getFieldValue('submitStatus') === SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING
                        ? 'Offer Status (CDP)'
                        : 'Offer Status'
                    }
                    name={'offerStatus'}
                    style={{ margin: '2px' }}
                    required={getFieldValue('submitStatus') !== SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING}
                  >
                    <Switch
                      checkedChildren={SWITCH_LABELS.YES}
                      unCheckedChildren={SWITCH_LABELS.NO}
                      disabled={getFieldValue('submitStatus') === SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING}
                      onChange={(value) => {
                        if (!value) setFieldValue('offerAmount', undefined);
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>

              <Divider type="vertical" style={styles.verticalDivider} />

              <Form.Item label={'Consistency'} name={'consistency'} style={{ margin: '2px' }} required>
                <Switch checkedChildren={SWITCH_LABELS.YES} unCheckedChildren={SWITCH_LABELS.NO} />
              </Form.Item>

              <Divider type="vertical" style={styles.verticalDivider} />

              <Form.Item label={'Collect Salvage'} name={'collectSalvage'} style={{ margin: '2px' }} required>
                <Switch
                  checkedChildren={SWITCH_LABELS.YES}
                  unCheckedChildren={SWITCH_LABELS.NO}
                  onChange={() => {
                    form.setFieldValue('salvages', undefined);
                  }}
                />
              </Form.Item>

              <Divider type="vertical" style={styles.verticalDivider} />

              <Form.Item
                label={APPLICABLE_TYPES.PoliceReport.includes(inspectionCode) ? 'Police Report' : 'Police Report (N/A)'}
                name={'policeReport'}
                style={{ margin: '2px' }}
                required={APPLICABLE_TYPES.PoliceReport.includes(inspectionCode)}
              >
                <Switch
                  checkedChildren={SWITCH_LABELS.YES}
                  unCheckedChildren={SWITCH_LABELS.NO}
                  disabled={!APPLICABLE_TYPES.PoliceReport.includes(inspectionCode)}
                  defaultValue={false}
                />
              </Form.Item>
            </Flex>

            <Divider style={styles.horizontalDividerBottom} />

            <Flex justify="space-between" gap={8}>
              <Form.Item
                label={'ACR Value'}
                name={'valuation'}
                rules={
                  sumAssured === 0
                    ? [{ required: true, min: 0, type: 'number' }]
                    : [
                        {
                          required: true,
                        },
                        {
                          min: 0,
                          type: 'number',
                          max: sumAssured,
                          message: 'ACR cannot exceed sum assured',
                        },
                      ]
                }
              >
                <CurrencySelectInput style={styles.fullWidth} defaultCurrency={CURRENCY} />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.offerStatus !== currentValues.offerStatus ||
                  prevValues.valuation !== currentValues.valuation
                }
              >
                {({ getFieldValue }) => (
                  <Form.Item
                    label={'Offer Amount'}
                    name={'offerAmount'}
                    rules={[
                      {
                        required:
                          getFieldValue('offerStatus') ||
                          getFieldValue('submitStatus') === SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING,
                      },
                      {
                        min: 0,
                        type: 'number',
                        max: getFieldValue('valuation'),
                        message: 'Offer amount cannot exceed ACR',
                      },
                    ]}
                  >
                    <CurrencySelectInput
                      defaultCurrency={CURRENCY}
                      min={0}
                      style={styles.fullWidth}
                      disabled={
                        !getFieldValue('offerStatus') &&
                        getFieldValue('submitStatus') !== SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING
                      }
                      placeholder={
                        !getFieldValue('offerStatus') &&
                        getFieldValue('submitStatus') !== SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING
                          ? 'N/A'
                          : ''
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item
                label={'PAV Value'}
                name={'PAV'}
                rules={[{ type: 'number', required: APPLICABLE_TYPES.PAV.includes(inspectionCode), min: 0 }]}
              >
                <CurrencySelectInput
                  defaultCurrency={CURRENCY}
                  style={styles.fullWidth}
                  disabled={!APPLICABLE_TYPES.PAV.includes(inspectionCode)}
                  placeholder={!APPLICABLE_TYPES.PAV.includes(inspectionCode) ? 'N/A' : ''}
                />
              </Form.Item>
            </Flex>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.collectSalvage !== currentValues.collectSalvage}
            >
              {({ getFieldValue }) => (
                <Form.Item label={'Salvages'} name={'salvages'} rules={[{ required: getFieldValue('collectSalvage') }]}>
                  <TextArea
                    rows={2}
                    disabled={!getFieldValue('collectSalvage')}
                    placeholder={!getFieldValue('collectSalvage') ? 'N/A' : ''}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item label={'Comments'} name={'operatorComment'} rules={[{ required: true }]}>
              <TextArea rows={2} />
            </Form.Item>

            <Flex justify="end" gap={8}>
              <Form.Item style={{ marginBottom: '4px' }}>
                <Button type="default" onClick={onCancel}>
                  Cancel
                </Button>
              </Form.Item>

              <Form.Item style={{ marginBottom: '4px' }}>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => {
                        setIsSubmitted(true);
                      })
                      .catch(() => {
                        setIsSubmitted(false);
                      });
                  }}
                  loading={mutation.isPending}
                >
                  Submit
                </Button>
              </Form.Item>
            </Flex>
          </div>
          <div style={{ display: isSubmitted ? '' : 'none' }}>
            <Text type="danger">Please ensure the entered amounts are accurate!</Text>
            <Flex justify={'space-evenly'} style={styles.statisticsContainer}>
              <Statistic
                valueStyle={styles.statistic(form.getFieldValue('valuation'))}
                title={<Text>ACR Value</Text>}
                prefix={`${CURRENCY}`}
                value={form.getFieldValue('valuation') || 'N/A'}
              />
              <>
                <Divider type="vertical" style={styles.verticalDivider} />
                <Statistic
                  valueStyle={styles.statistic(form.getFieldValue('offerAmount'))}
                  title={<Text>Offer Value</Text>}
                  prefix={form.getFieldValue('offerAmount') && `${CURRENCY}`}
                  value={form.getFieldValue('offerAmount') || 'N/A'}
                />
              </>
              <>
                <Divider type="vertical" style={styles.verticalDivider} />
                <Statistic
                  valueStyle={styles.statistic(form.getFieldValue('PAV'))}
                  title={<Text>PAV Value</Text>}
                  prefix={form.getFieldValue('PAV') && `${CURRENCY}`}
                  value={form.getFieldValue('PAV') || 'N/A'}
                />
              </>
            </Flex>
            <Flex justify="end" gap={8}>
              <Form.Item style={{ marginBottom: '4px' }}>
                <Button type="default" onClick={() => setIsSubmitted(false)}>
                  Back
                </Button>
              </Form.Item>

              <Form.Item style={{ marginBottom: '4px' }}>
                <Button type="primary" htmlType="submit" loading={mutation.isPending}>
                  Confirm
                </Button>
              </Form.Item>
            </Flex>
          </div>
        </Form>
      </ContentOverlay>

      <Space
        {...(!disable && { onClick: () => setShowCompleteInspectionOverlay(!showCompleteInspectionOverlay) })}
        style={styles.childSpace(disable)}
      >
        {children}
      </Space>
    </>
  );
};

export default CompleteInspection;

const useStyles = () => {
  return {
    verticalDivider: {
      backgroundColor: '#d9d9d9',
      height: '60px',
      marginTop: 'auto',
      marginBottom: 'auto',
    } as CSSProperties,
    horizontalDividerTop: {
      backgroundColor: '#d9d9d9',
      margin: '0 0 8px 0',
    } as CSSProperties,
    horizontalDividerBottom: {
      backgroundColor: '#d9d9d9',
      margin: '8px 0 24px 0',
    } as CSSProperties,
    fullWidth: {
      width: '100%',
    } as CSSProperties,
    statisticsContainer: {
      margin: '32px 0',
    } as CSSProperties,
    statistic: (value?: boolean): CSSProperties => ({
      color: value ? 'black' : 'grey',
      fontSize: 24,
    }),
    childSpace: (disabled: boolean): CSSProperties => ({
      color: disabled ? 'rgba(0, 0, 0, 0.25)' : 'black',
      cursor: disabled ? 'not-allowed' : 'pointer',
    }),
  };
};

const getSubmitStatuses = (inspectionCode: string) => {
  let options = Object.entries(SUBMIT_STATUSES).map(([key, value]) => ({
    value,
    label: startCase(key.toLowerCase()),
  }));

  if (!APPLICABLE_TYPES.CDP.includes(inspectionCode))
    options = options.filter((option) => option.value !== SUBMIT_STATUSES.CUSTOMER_DECISION_PENDING);
  return options;
};
