import { Col, Row, Skeleton } from 'antd';

const InvoiceFooterSkeleton = () => {
  return (
    <Row style={{ marginTop: '10px' }}>
      <Col span={24}>
        <Skeleton paragraph={{ rows: 1 }} round />
      </Col>
    </Row>
  );
};
export default InvoiceFooterSkeleton;
