import env from '@/config/env.ts';
import axios from 'axios';
import { authStateSingleton } from '../AuthContext/auth-service.util';
import { UnauthorizedErrorRedirect } from './unauthorized-error.util';

export const DigiEyeApi = axios.create({
  baseURL: env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

DigiEyeApi.interceptors.request.use((config) => {
  if (authStateSingleton.token === '' || authStateSingleton.tenantId === '') UnauthorizedErrorRedirect();
  config.headers['Authorization'] = `Bearer ${authStateSingleton.token}`;
  config.baseURL = `${config.baseURL}${authStateSingleton.tenantId}`;
  return config;
});

DigiEyeApi.interceptors.response.use(
  (response) => {
    if (response.status === 211) UnauthorizedErrorRedirect();
    return response;
  },
  (error) => {
    if (error.response?.status === 401) UnauthorizedErrorRedirect();
    else Promise.reject(error);
  }
);
